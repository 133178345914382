import React, { Dispatch, ReactNode, SetStateAction } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  TextInputProps,
  Text,
  ViewStyle,
  StyleProp,
  Platform,
} from "react-native";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
// import {
//   CodeField,
//   Cursor,
//   useBlurOnFulfill,
//   useClearByFocusCell,
// } from 'react-native-confirmation-code-field';
// import IMask from 'imask';

export type CommonInputProps = {
  error?: boolean;
  mask?: string | RegExp;
  RightItem?: ReactNode;
} & TextInputProps;

export const CommonInput = ({
  error,
  mask,
  onChangeText,
  multiline,
  style,
  textInputStyle,
  RightItem,
  ...props
}: CommonInputProps & { textInputStyle?: StyleProp<ViewStyle> }) => {
  // const userMask = mask
  //   ? IMask.createMask({
  //       mask: mask,
  //     })
  //   : null;

  return (
    <View
      style={[
        inputStyles.input,
        multiline && inputStyles.multiLine,
        error && inputStyles.wrongCode,
        style,
      ]}
    >
      <TextInput
        placeholderTextColor={colors.gray20}
        style={[
          inputStyles.textInput,
          Platform.OS === "ios" && { lineHeight: 0 },
          textInputStyle,
          { outline: "none" },
        ]}
        onChangeText={(text) =>
          onChangeText &&
          onChangeText(
            // userMask ? userMask.resolve(text) :
            text
          )
        }
        multiline={multiline}
        {...props}
      />
      {RightItem}
    </View>
  );
};

// export type CodeInputProps = {
//   error?: boolean;
//   code: string;
//   setCode: Dispatch<SetStateAction<string>>;
//   cellCount?: number;
//   style?: ViewStyle;
//   autoFocus?: boolean;
// };
//
// export const CodeInput = ({
//   error,
//   code,
//   setCode,
//   cellCount = 4,
//   autoFocus = false,
//   style,
// }: CodeInputProps) => {
//   const ref = useBlurOnFulfill({value: code, cellCount: 4});
//
//   const [cellProps, getCellOnLayoutHandler] = useClearByFocusCell({
//     value: code,
//     setValue: setCode,
//   });
//
//   return (
//     <View style={[inputStyles.input, error && inputStyles.wrongCode, style]}>
//       <CodeField
//         ref={ref}
//         rootStyle={[inputStyles.textInput, {justifyContent: 'center'}]}
//         value={code}
//         autoFocus={autoFocus}
//         onChangeText={setCode}
//         cellCount={cellCount}
//         keyboardType="number-pad"
//         textContentType="oneTimeCode"
//         {...cellProps}
//         returnKeyType={'done'}
//         blurOnSubmit
//         renderCell={({index, symbol, isFocused}) => (
//           <View
//             key={index}
//             onLayout={getCellOnLayoutHandler(index)}
//             style={inputStyles.cellRoot}>
//             <Text style={[fonts.mediumText_regular, {color: colors.black}]}>
//               {symbol ? symbol : '_'}
//             </Text>
//             <View style={{position: 'absolute'}}>
//               {isFocused && (
//                 <Text style={[fonts.mediumText_regular, {color: colors.black}]}>
//                   <Cursor
//                     // Blinking animation speed (optional, number)
//                     delay={500}
//                     // Symbol that would be returned to simulate cursor blinking (optional, string)
//                     cursorSymbol="|"
//                   />
//                 </Text>
//               )}
//             </View>
//           </View>
//         )}
//       />
//     </View>
//   );
// };

export const RadioButton = ({
  isActive,
  style,
}: {
  isActive: boolean;
  style?: StyleProp<ViewStyle>;
}) => (
  <View
    style={[
      inputStyles.circle,
      isActive && { backgroundColor: colors.black },
      style,
    ]}
  />
);

export const inputStyles = StyleSheet.create({
  input: {
    backgroundColor: colors.LightGrayishYellow,
    borderColor: "#BEBCC0",
    borderRadius: 10,
    borderWidth: 0.5,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    elevation: 2,
    shadowRadius: 2,
    shadowOpacity: 0.1,
    shadowColor: "rgb(37,31,44)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  textInput: {
    color: colors.black,
    padding: 12,
    flex: 1,
    ...fonts.mediumText_regular,
    alignSelf: "stretch",
  },
  wrongCode: {
    borderColor: colors.WarningRed,
  },
  cellRoot: {
    position: "relative",
    marginLeft: 5,
  },
  multiLine: {
    minHeight: 92,
  },
  circle: {
    margin: 8,
    width: 16,
    height: 16,
    borderRadius: 8,
    borderWidth: 1,
  },
});
