import React, { useContext, useEffect, useRef, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useLocation, useNavigate } from "react-router-dom";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from "./Navigation";
import { RootState } from "../store/root-reducer";
import { phoneAuthPostSmsAction } from "../store/phoneAuth/action";
import { CommonScreenCard } from "../components/CommonScreenCard";
import { Header } from "../components/Header";
import { fonts } from "@gadder/common/src/styles/fonts";
import { Phone } from "../components/CommonPhoneInput";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { colors } from "@gadder/common/src/styles/colors";

export const PhonePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { phone, setPhone, setEmail, setPassword } = useContext(AuthContext);

  const sendSmsState = useSelector((state: RootState) => state.phoneAuth);
  const [sendToken, sendRequest] = useUniqueDispatch(
    phoneAuthPostSmsAction.request
  );

  const isSending = isLoading(sendSmsState, sendToken);

  useEffect(() => {
    if (
      sendToken &&
      isData(sendSmsState, sendToken) &&
      sendSmsState.data.token
    ) {
      navigate("../code", { state: location.state });
    }
  }, [sendSmsState, sendToken]);

  const onVerify = () => {
    sendRequest([
      {
        phone: phone.split(" ").join("").split("+")[1],
        captcha_token: captchaToken,
      },
    ]);
  };

  const recaptchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState<null | string>("");

  const buttonDisable = !captchaToken || phone?.length < 8;

  return (
    <CommonScreenCard>
      <Header
        onPressLeft={() => {
          navigate(-1);
        }}
      />
      <Text
        style={[
          fonts.title,
          { marginBottom: 50, textAlign: "center", maxWidth: 340 },
        ]}
      >
        Please enter your phone number.
      </Text>
      <Phone value={phone} onChange={setPhone} style={styles.phoneInput} />
      <View style={{ flexDirection: "column", marginBottom: 20 }}>
        <CommonButton
          disabled={buttonDisable}
          textStyle={fonts.mediumText_bold}
          title="Next"
          isLoading={isSending}
          type={buttonDisable ? "black" : "blackFilled"}
          onPress={() => onVerify()}
        />
      </View>
      <ReCAPTCHA
        style={{ marginBottom: 32 }}
        sitekey="6LfNcWwkAAAAABCHvGkc4haEeYYhtDs3jVdx8Njg"
        //@ts-ignore
        ref={recaptchaRef}
        onChange={(token) => {
          setCaptchaToken(token);
        }}
      />
    </CommonScreenCard>
  );
};

const styles = StyleSheet.create({
  phoneInput: {
    borderColor: colors.gray81,
    borderWidth: 1,
    maxWidth: 300,
    height: 46,
    borderRadius: 10,
    width: "100%",
    marginBottom: 170,
  },
});
