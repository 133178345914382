import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Keyboard, Text, View } from "react-native";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useUniqueDispatch, {
  isError,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { AuthContext, CommonContext } from "./Navigation";
import ReCAPTCHA from "react-google-recaptcha";
import { RootState } from "../store/root-reducer";
import { userRegisterAction } from "../store/user/action";
import { phoneAuthPostSmsAction } from "../store/phoneAuth/action";
import { fonts } from "@gadder/common/src/styles/fonts";
import { Header } from "../components/Header";
import { CommonScreenCard } from "../components/CommonScreenCard";
import { InputField } from "../components/InputField";
import { CommonButton } from "@gadder/common/src/components/buttons";

export const CodePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState("");

  const { setRedirectAfterLoginTo } = useContext(CommonContext);

  useEffect(() => {
    setRedirectAfterLoginTo(location.state?.redirectAfterLoginTo || null);
  }, [location.state]);

  const { phone, setPhone } = useContext(AuthContext);

  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((timeLeft) => (timeLeft > 0 ? timeLeft - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const disableButton = code.length < 4;

  const sendSmsState = useSelector((state: RootState) => state.phoneAuth);
  const userRequest = useSelector((state: RootState) => state.user);

  const [authToken, authRequest] = useUniqueDispatch(
    userRegisterAction.request
  );

  const isSending = useMemo(
    () => isLoading(userRequest, authToken),
    [userRequest, authToken]
  );
  const isCodeError = useMemo(
    () => isError(userRequest, authToken),
    [userRequest, authToken]
  );

  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    if (isError(userRequest, authToken)) {
      if (isCodeError?.response?.status === 400) {
        setCodeError("Wrong code");
      } else {
        setCodeError(isCodeError?.message || "");
      }
    }
  }, [isCodeError]);

  const [showCaptcha, setShowCaptcha] = useState(false);

  const [sendToken, sendRequest] = useUniqueDispatch(
    phoneAuthPostSmsAction.request
  );

  const recaptchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState<null | string>("");

  const onSendAgain = () => {
    sendRequest([
      {
        phone: phone.split(" ").join("").split("+")[1],
        captcha_token: captchaToken,
      },
    ]);
  };

  const sendSmsHandler = () => {
    Keyboard.dismiss();

    const tempEmail = new Date().getTime() + "@temp.replace";

    authRequest([
      {
        providerArgs: {
          phone: sendSmsState.data?.phone,
          code: code,
          token: sendSmsState.data?.token,
        },

        profileToRegister: {
          email: tempEmail,
          username: tempEmail,
        },
      },
      {},
      "phone",
    ]);
  };

  useEffect(() => {
    if (captchaToken !== "") {
      setShowCaptcha(false);
      setTimeLeft(60);
      onSendAgain();
    }
  }, [captchaToken]);

  useEffect(() => {
    setCodeError("");
  }, [code]);

  useEffect(() => {
    if (code.length === 4) {
      Keyboard.dismiss();
    }
  }, [code]);

  return (
    <CommonScreenCard>
      <Header onPressLeft={() => navigate(-1)} />
      <Text
        style={[
          fonts.title,
          { marginBottom: 8, textAlign: "center", maxWidth: 340 },
        ]}
      >
        We sent you a code via sms.
      </Text>
      <Text style={[fonts.mediumText_regular, { marginBottom: 51 }]}>
        Please, enter the code below.
      </Text>
      <InputField
        placeholder="_ _ _ _"
        value={code}
        onChangeText={(text) => text.length < 5 && setCode(text)}
        error={!!codeError}
        errorText={codeError}
      />
      {showCaptcha ? (
        <ReCAPTCHA
          style={{ marginBottom: 76, marginTop: 16 }}
          sitekey="6LfNcWwkAAAAABCHvGkc4haEeYYhtDs3jVdx8Njg"
          //@ts-ignore
          ref={recaptchaRef}
          onChange={(token) => {
            setCaptchaToken(token);
          }}
        />
      ) : timeLeft > 0 ? (
        <Text
          style={[
            fonts.mediumText_regular,
            {
              marginBottom: 132,
              marginTop: 16,
              textDecorationLine: "underline",
            },
          ]}
        >
          Send new code in {timeLeft.toString()} sec.
        </Text>
      ) : (
        <Text
          onPress={() => setShowCaptcha(true)}
          style={[
            fonts.mediumText_regular,
            {
              marginBottom: 132,
              marginTop: 16,
              textDecorationLine: "underline",
            },
          ]}
        >
          Resend code
        </Text>
      )}
      <CommonButton
        textStyle={fonts.mediumText_bold}
        title="Next"
        isLoading={isSending}
        onPress={sendSmsHandler}
        type={disableButton ? "black" : "blackFilled"}
        disabled={disableButton}
      />
    </CommonScreenCard>
  );
};
