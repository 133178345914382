import { phoneAuthPostSmsAction } from "./action";
import api from "../storeApi";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";

export const phoneAuthReducer = combineReducers<{
  data: typeof api.phoneAuth.type;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null).handleAction(
    [phoneAuthPostSmsAction.success],
    (state, action) => action.payload
  ),
  isLoading: createLoadingReducer(phoneAuthPostSmsAction),
  lastOpId: createOperationReducer(phoneAuthPostSmsAction),
  error: createErrorReducer(phoneAuthPostSmsAction),
});
