import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { StyleProp, View, ViewStyle, StyleSheet } from "react-native";
import { eachHourOfInterval, eachMinuteOfInterval, format } from "date-fns";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";

export const TimeSelect = ({
  date,
  setDate,
  isActive = true,
  disabled = false,
  containerStyle,
  menuStyle,
}: {
  disabled?: boolean;
  isActive?: boolean;
  date: Date;
  setDate?: (date: Date) => void;
  containerStyle?: StyleProp<ViewStyle>;
  menuStyle?: StyleProp<ViewStyle>;
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const isToday =
      new Date().setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0);

    const startOfTheDay = new Date(date);
    startOfTheDay.setHours(
      isToday ? Math.max(new Date().getHours() + 1, 0) : 0,
      0,
      0,
      0
    );

    const endOfTheDay = new Date(date);
    endOfTheDay.setHours(23, 30, 0, 0);

    const options = eachMinuteOfInterval(
      {
        start: startOfTheDay,
        end: endOfTheDay,
      },
      { step: 30 }
    ).map((_timeStep) => ({
      value: _timeStep,
      label: format(_timeStep, "HH:mm"),
    }));

    setOptions(options);

    const selectedOption =
      options.find(
        (_option) =>
          _option.value.getHours() === date.getHours() &&
          _option.value.getMinutes() === date.getMinutes()
      ) || options[0];

    setSelectedOption(selectedOption);
  }, [date]);

  const [selectedOption, setSelectedOption] = useState();

  return (
    <Select
      onChange={(_value) => {
        console.log("_value", _value.value);

        setDate(_value.value);
      }}
      isSearchable={false}
      isDisabled={disabled || !isActive}
      value={selectedOption}
      options={options}
      styles={{
        indicatorsContainer: () => ({ display: "none" }),
        valueContainer: () => ({
          padding: 0,
          flexDirection: "row",
          width: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }),
        singleValue: () => ({ margin: 0 }),
        input: () => ({ padding: 0, margin: 0, width: 0 }),
        control: () => ({ minHeight: 0, height: "100%" }),
        container: () => ({
          position: "relative",
          backgroundColor: isActive ? colors.white : colors.gray90,
          borderColor: colors.gray81,
          color: isActive ? colors.black : colors.gray50,
          borderWidth: 1,
          borderRadius: 10,
          borderStyle: "solid",
          boxSizing: "border-box",
          ...fonts.smallMediumText_regular,
          lineHeight: "unset",
          cursor: "pointer",
          ...containerStyle,
        }),
        menu: () => ({
          width: 80,
          position: "absolute",
          left: -30,
          top: "calc(100% + 16px)",
          borderRadius: 10,
          backgroundColor: colors.white,
          ...menuStyle,
        }),
      }}
    />
  );
};
