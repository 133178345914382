import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { placeGetAction, placeGetBookingDataAction } from "./action";
export const getRequest = function* (
  action: ReturnType<typeof placeGetAction.request>
) {
  try {
    let response = yield call(() => api.place.methods.get(...action.payload));
    yield put(placeGetAction.success(response.data, action.meta));
  } catch (error) {
    yield put(placeGetAction.failure(error, action.meta));
  }
};

export const getBookingDataRequest = function* (
  action: ReturnType<typeof placeGetBookingDataAction.request>
) {
  try {
    const response = yield call(() =>
      api.mapSearchPlaces.methods.getBookingData(...action.payload)
    );

    yield put(placeGetBookingDataAction.success(response.data, action.meta));
  } catch (error) {
    yield put(placeGetBookingDataAction.failure(error, action.meta));
  }
};

export const placeSaga = function* () {
  yield all([
    takeEvery(placeGetAction.request, getRequest),
    takeEvery(placeGetBookingDataAction.request, getBookingDataRequest),
  ]);
};
