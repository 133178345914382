import { TOKEN_LOCAL_KEY } from "./constants";

const WHATSAPP_POPUP_STATE = "WHATSAPP_POPUP_STATE";

export async function getToken() {
  console.log("GET TOKEN", await localStorage.getItem(TOKEN_LOCAL_KEY));
  return (await localStorage.getItem(TOKEN_LOCAL_KEY)) ?? "";
}

export async function setToken(token: string | null) {
  token
    ? localStorage.setItem(TOKEN_LOCAL_KEY, token)
    : localStorage.removeItem(TOKEN_LOCAL_KEY);
  return token;
}

export async function getWhatsAppPopupState() {
  const state = await localStorage.getItem(WHATSAPP_POPUP_STATE);

  return state ? JSON.parse(state) : null;
}

export async function setWhatsAppPopupState(state) {
  state
    ? localStorage.setItem(WHATSAPP_POPUP_STATE, JSON.stringify(state))
    : localStorage.removeItem(WHATSAPP_POPUP_STATE);
  return state;
}
