import {
  ActivityIndicator,
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { ReactNode, useState } from "react";
import { colors } from "@gadder/common/src/styles/colors";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import { fonts } from "@gadder/common/src/styles/fonts";
import { useIsMobile } from "../../pages/ChatPage";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../../store/root-reducer";
import { ReactComponent as GadderLogo } from "@gadder/common/src/assets/svg/gadder_with_logo_black.svg";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { ReactComponent as InstagramIcon } from "@gadder/common/src/assets/svg/instagram_white.svg";
import { ReactComponent as TiktokIcon } from "@gadder/common/src/assets/svg/tiktok.svg";
import { ReactComponent as ChatBlackIcon } from "@gadder/common/src/assets/svg/chat_black.svg";
import { ReactComponent as ChatLightIcon } from "@gadder/common/src/assets/svg/chat_light.svg";
import { ReactComponent as PlacelistBlackIcon } from "@gadder/common/src/assets/svg/placelist_big_black.svg";
import { ReactComponent as PlacelistWhiteIcon } from "@gadder/common/src/assets/svg/placelist_big_light.svg";
import { ReactComponent as MapBlackIcon } from "@gadder/common/src/assets/svg/map_tab_black.svg";
import { ReactComponent as MapWhiteIcon } from "@gadder/common/src/assets/svg/map_tab_light.svg";
import { ReactComponent as ArrowGray } from "@gadder/common/src/assets/svg/arrow_gray.svg";

import { TabButton } from "../TabButton";
import { BugButton } from "../BugButton";

export enum SideMenuTabs {
  CHATS = "chats",
  PLACELIST = "placelists",
  MAP = "map",
}

export const SideMenu = ({
  children,
  selectedTab,
  onFilterPress,
}: {
  children?: ReactNode;
  selectedTab?: SideMenuTabs;
  onFilterPress?: () => void;
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((store: RootState) => store.user);

  const avatarPressHandler = () => {
    if (user.data) {
      navigate("/profile", { state: { source: selectedTab } });
    } else {
      navigate("/login");
    }
  };
  const [isFooterOpened, setIsFooterOpened] = useState(false);

  return (
    <View
      style={[
        styles.mainContainer,
        {
          width: isMobile ? "100%" : "33%",
          minWidth: isMobile ? "unset" : 570,
        },
      ]}
    >
      <TouchableOpacity
        onPress={avatarPressHandler}
        style={styles.headerContainer}
      >
        <UserAvatar size={48} user={user.data} />

        {user.data ? (
          <Text
            numberOfLines={1}
            style={[
              isMobile
                ? fonts.mediumLargeText_bold
                : fonts.mediumLargeText_bold,
              { color: colors.black, marginLeft: 18 },
            ]}
          >
            {user.data.first_name + " " + user.data.second_name}
          </Text>
        ) : (
          <CommonButton
            style={{ marginLeft: 12 }}
            onPress={() => navigate("/login")}
            textStyle={fonts.smallMediumText_medium}
            containerStyle={{ paddingVertical: 4, paddingHorizontal: 12 }}
            type={"black"}
            size={"medium"}
            title={"Log in"}
          />
        )}
      </TouchableOpacity>

      <BugButton
        style={{
          position: "absolute",
          top: isMobile ? 30 : 4,
          right: isMobile ? 24 : 10,
        }}
        withNote={!isMobile}
        size={75}
        label={isMobile ? "Bug" : "Report an\nissue"}
      />

      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 32,
          paddingBottom: 12,
          gap: 16,
        }}
      >
        {/*<TabButton*/}
        {/*  label={"Placelists"}*/}
        {/*  isSelected={selectedTab === SideMenuTabs.PLACELIST}*/}
        {/*  Icon={PlacelistBlackIcon}*/}
        {/*  ActiveIcon={PlacelistWhiteIcon}*/}
        {/*  onPress={() => navigate("/placelists")}*/}
        {/*/>*/}

        <TabButton
          label={"Chats"}
          isSelected={selectedTab === SideMenuTabs.CHATS}
          Icon={ChatBlackIcon}
          ActiveIcon={ChatLightIcon}
          onPress={() =>
            user.data
              ? navigate("/bot")
              : navigate("/login-modal", {
                  state: { previousLocation: location },
                })
          }
        />

        <TabButton
          label={"Map"}
          isSelected={selectedTab === SideMenuTabs.MAP}
          Icon={MapBlackIcon}
          ActiveIcon={MapWhiteIcon}
          onPress={() => navigate("/map")}
        />
      </View>

      <View style={styles.horizontalLine} />

      <View style={{ zIndex: 10, flex: 1 }}>{children}</View>

      <View style={styles.horizontalLine} />

      <View style={isMobile ? styles.footerMobile : styles.footer}>
        <View
          style={[
            styles.footerContainer,
            isMobile && { flexDirection: "column", marginBottom: 8 },
          ]}
        >
          {!isMobile && (
            <Pressable onPress={() => navigate("/", { replace: true })}>
              <GadderLogo width={110} />{" "}
            </Pressable>
          )}

          <View
            style={[
              styles.footerButtonsContainer,
              isMobile && {
                justifyContent: isMobile ? "space-between" : "flex-end",
              },
            ]}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <CommonButton
                style={{ marginRight: 12 }}
                onPress={() =>
                  Linking.openURL("https://www.tiktok.com/@gadderapp")
                }
                type={"blackFilled"}
                textStyle={fonts.smallMediumText_semiBold}
                containerStyle={[
                  isMobile ? styles.footerButtonMobile : styles.footerButton,
                  // { paddingHorizontal: 18 },
                ]}
                title={"Follow us"}
                LeftIcon={
                  <TiktokIcon
                    style={{ paddingRight: 4, height: 22, width: 22 }}
                  />
                }
              />
              <CommonButton
                onPress={() =>
                  Linking.openURL("https://www.instagram.com/gadderhq/")
                }
                type={"instaGradient"}
                textStyle={fonts.smallMediumText_semiBold}
                containerStyle={[
                  isMobile ? styles.footerButtonMobile : styles.footerButton,
                ]}
                title={"Follow us"}
                LeftIcon={<InstagramIcon style={{ paddingRight: 4 }} />}
              />
            </View>
            <Pressable
              style={{ marginLeft: 18 }}
              onPress={() => setIsFooterOpened((prevState) => !prevState)}
            >
              <View style={!isFooterOpened && { transform: "rotateZ(180deg)" }}>
                <ArrowGray />
              </View>
            </Pressable>
          </View>
        </View>

        {isFooterOpened && (
          <View
            style={[
              styles.footerInfoContainer,
              isMobile && { flexDirection: "column", marginBottom: 4 },
            ]}
          >
            <Text
              style={[
                styles.footerInfoItem,
                styles.footerInfoItemNormal,
                isMobile && { marginBottom: 6 },
                fonts.smallMediumText_medium,
              ]}
            >
              Contact: hello@gadderapp.com
            </Text>

            <View style={[styles.footerInfoItems, { gap: 36 }]}>
              <Text
                onPress={() =>
                  Linking.openURL(
                    "https://www.termsfeed.com/live/fb6d3a5b-41e8-4e4a-9cd6-fee3c8e58f54"
                  )
                }
                style={[styles.footerInfoItem, fonts.smallMediumText_medium]}
              >
                Privacy Policy
              </Text>
              <Text
                onPress={() =>
                  Linking.openURL("https://www.gadderapp.com/faqs")
                }
                style={[styles.footerInfoItem, fonts.smallMediumText_medium]}
              >
                FAQs
              </Text>
              <Text
                onPress={() =>
                  Linking.openURL("https://www.gadderapp.com/d-and-i")
                }
                style={[styles.footerInfoItem, fonts.smallMediumText_medium]}
              >
                D&I
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    height: "100%",
    backgroundColor: "white",
    borderRightWidth: 1,
    borderRightColor: colors.gray81,
    paddingTop: 24,
  },
  headerContainer: {
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: 24,
    paddingBottom: 12,
    alignItems: "center",
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: colors.gray81,
  },
  footer: {
    paddingHorizontal: 32,
    paddingTop: 8,
    paddingBottom: 8,
  },
  footerMobile: {
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 12,
  },
  footerButton: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignItems: "center",
  },
  footerButtonMobile: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  footerContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  footerButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },

  footerInfoContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },

  footerInfoItems: {
    flexDirection: "row",
  },

  footerInfoItem: {
    color: colors.black,
    textDecorationLine: "underline",
  },
  footerInfoItemNormal: {
    textDecorationLine: "none",
  },
});
