export const colors = {
  gray: "#B3B3B3",
  gray90: "#E5E5E5",
  gray81: "#BEBCC0",
  darkGray: "#666666",
  gray50: "#A8A5AB",
  gray20: "#7C7980",
  black: "#251F2C",
  green: "#849617",
  greenBright: "#00A001",
  lightGray: "#DBD6E0",
  ImportantRed: "#CD5839",
  WarningRed: "#BD4400",
  LightGrayishYellow: "#f9f8f0",
  YellowishShadow: "#e9e9ea",
  noteGray: "#67636C",
  white: "white",
  blue: "#2C92D5",
  borderLightGray: "#F0EFE6",
  mediumLightGray: "#F5F5F5",
  violet: "#9682DB",
  blue50: "#928F96",
  blueLight: "#C7E9EF",
  gray_f9: "#f9f9f9",
  newBlue: "#2697FF",
  blue1: "#3F9AE9",
};
