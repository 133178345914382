import { useCallback, useEffect, useRef } from "react";
import _ from "lodash";

export function useDebounce(cb: (args: any) => void, delay: number) {
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep

  useEffect(() => {
    cbRef.current = cb;
  }, []);

  return useCallback(
    _.debounce((...args) => cbRef.current(...args), delay, {
      leading: false,
      trailing: true,
    }),
    [delay]
  );
}
