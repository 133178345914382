import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";
import api from "../storeApi";
export const phoneAuthPostSmsAction = createAsyncActionWithMeta(
  "phoneAuth_phoneAuthPostSmsAction_REQUEST",
  "phoneAuth_phoneAuthPostSmsAction_SUCCESS",
  "phoneAuth_phoneAuthPostSmsAction_FAILURE"
)<
  Parameters<typeof api.phoneAuth.methods.postSms>,
  typeof api.phoneAuth.type,
  Error
>();
