import React, { ReactNode, useEffect, useState } from "react";
import { Pressable, View } from "react-native";

export const DrawerWrapper = ({
  isOpen,
  onClose,
  children,
}: {
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isActualOpen, setIsActualOpen] = useState(isOpen);

  const animationDelayMs = 300;

  useEffect(() => {
    setTimeout(() => setIsActualOpen(isOpen), animationDelayMs);
  }, [isOpen]);

  const [animationStart, setAnimationStart] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimationStart(isOpen), 10);
  }, [isOpen]);

  return (
    <>
      {(isOpen || isActualOpen) && (
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <View
            style={[
              {
                height: "100%",
                width: "80%",
                transform: [{ translateX: "-100%" }],
                transition: "transform 0.3s",
              },
              animationStart && { transform: [{ translateX: "0" }] },
            ]}
          >
            {children}
          </View>
          <Pressable
            style={[
              {
                zIndex: -1,
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0)",
                transition: "background-color 0.2s",
              },
              animationStart && { backgroundColor: "rgba(0, 0, 0, 0.5)" },
            ]}
            onPress={onClose}
          />
        </View>
      )}
    </>
  );
};
