import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  chatbotPostMessageAction,
  chatbotGetChatAction,
  chatbotPostEventAction,
  chatbotSubscribeAction,
  chatbotUnsubscribeAction,
  chatbotPostMessageEventAction,
  chatbotHideErrorAction,
} from "./action";
import { io, Socket } from "socket.io-client";
import { BASE_PATH } from "../../api/constants";
import store from "../index";

export const postMessageRequest = function* (
  action: ReturnType<typeof chatbotPostMessageAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbot.methods.postMessage(...action.payload)
    );
    yield put(chatbotPostMessageAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotPostMessageAction.failure(error, action.meta));
  }
};

export const getChatRequest = function* (
  action: ReturnType<typeof chatbotGetChatAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbot.methods.getChat(...action.payload)
    );
    yield put(chatbotGetChatAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotGetChatAction.failure(error, action.meta));
  }
};

export const postEventRequest = function* (
  action: ReturnType<typeof chatbotPostEventAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbot.methods.postEvent(...action.payload)
    );
    yield put(chatbotPostEventAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotPostEventAction.failure(error, action.meta));
  }
};

export const chatbotSaga = function* () {
  yield all([
    takeEvery(chatbotPostMessageAction.request, postMessageRequest),
    takeEvery(chatbotGetChatAction.request, getChatRequest),
    takeEvery(chatbotPostEventAction.request, postEventRequest),

    takeEvery(chatbotSubscribeAction.request, subscribe),
    takeEvery(chatbotUnsubscribeAction.request, unsubscribe),
    takeEvery(chatbotPostMessageEventAction.request, postMessageEvent),
  ]);
};

const socketStorage: {
  socket;
  timeout: NodeJS.Timeout | null;
} = {
  socket: Socket,
  timeout: null,
};

const postMessageEvent = function* (action) {
  yield call(() => {
    socketStorage.socket.emit("chat-bot-post-message", action.payload[0]);
  });
  yield call(() => {
    socketStorage.timeout = setTimeout(() => {
      store.dispatch(
        chatbotPostMessageEventAction.success([
          {
            chat_id: action.payload[0].chat_id,
            is_finished: true,
          },
        ])
      );
    }, 1000 * 60 * 2);
  });
};

const subscribe = function* () {
  console.log("BASE_PATH", BASE_PATH);

  const token = yield call(() => store.getState().token.data);

  const socket = yield call(() =>
    io(BASE_PATH || "https://test.gadderapp.com", {
      auth: { token: token },
    })
  );

  socketStorage.socket = socket;

  yield call(() =>
    socket.on("connect_error", (err) =>
      store.dispatch(chatbotPostMessageEventAction.failure([err]))
    )
  );
  yield call(() =>
    socket.on("connect_failed", (err) =>
      store.dispatch(chatbotPostMessageEventAction.failure([err]))
    )
  );
  yield call(() =>
    socket.on("disconnect", (err) =>
      store.dispatch(chatbotPostMessageEventAction.failure([err]))
    )
  );

  yield call(() =>
    socket.on("connect", () => store.dispatch(chatbotHideErrorAction([])))
  );

  yield call(() =>
    socket.on("chat-bot-response", (arg) => {
      store.dispatch(chatbotPostMessageEventAction.success([arg]));
      if (socketStorage.timeout && arg.is_finished) {
        clearTimeout(socketStorage.timeout);
      }
    })
  );
};

const unsubscribe = function* () {
  if (socketStorage.socket) {
    yield call(() => socketStorage.socket.disconnect());
  }
};
