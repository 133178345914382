import React from "react";
import { StyleSheet, TextInput, View } from "react-native";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";

export function Phone({
  value,
  style,
  inputStyle,
  onChange,
  ...props
}: {
  value: any;
  style?: object;
  inputStyle?: object;
  onChange: (phone: any) => void;
}) {
  return (
    <PhoneInput
      placeholder="Enter phone number"
      value={value}
      international
      defaultCountry={"GB"}
      style={{ ...styles.phoneInput, ...style }}
      numberInputProps={{
        style: [
          {
            color: colors.black,
            outlineStyle: "none",
            ...fonts.input,
            ...inputStyle,
          },
        ],
        keyboardType: "phone-pad",
      }}
      inputComponent={TextInput}
      onChange={onChange}
      containerComponent={View}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  phoneInput: {
    flexDirection: "row",
    backgroundColor: colors.LightGrayishYellow,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 15,
    maxWidth: 332,
    height: 60,
  },
});
