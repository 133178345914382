import React, { useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
  Text,
} from "react-native";
import { ClusterPin, MapPin } from "../MapPin";
import MapView, { Region } from "../Map";
import {
  defaultPlaceFilter,
  PlaceSearchFiltersType,
} from "@gadder/common/src/components/placeSearch/types/filters";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../../store/root-reducer";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";
import _ from "lodash";
import { mapPlacesGetAction } from "../../store/mapPlaces/action";
import { usePins } from "../../pages/MapPageOld";
import { useIsMobile } from "../../pages/ChatPage";
import { PlaceCardMedium } from "@gadder/common/src/components/PlaceCardMedium";
import LinearGradient from "react-native-web-linear-gradient";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";

const defaultRegion: Region = {
  latitude: 51.512,
  longitude: -0.12,
  zoom: 12,
};

export const MapWithPlaces = ({
  filters,
  selectedPlace,
  onPlaceSelect,
  searchIds = [],
  style,
  isPlacesLoading = false,
}: {
  isPlacesLoading?: boolean;
  style?: StyleProp<ViewStyle>;
  filters: PlaceSearchFiltersType;
  searchIds?: string[];
  selectedPlace: FullPlaceType | null;
  onPlaceSelect?: (place_id: number | null) => void;
}) => {
  const [currentRegion, setCurrentRegion] = useState<Region>(defaultRegion);

  const mapPlacesState = useSelector((state: RootState) => state.mapPlaces);

  const mapPlaces: FullPlaceType[] = useMemo(
    () => _.shuffle(mapPlacesState.data) || [],
    [mapPlacesState.data]
  );

  const [loadPinsToken, loadPins] = useUniqueDispatch(
    mapPlacesGetAction.request
  );

  const isPinsLoading = isLoading(mapPlacesState, loadPinsToken);

  const placesFromSearch = useMemo(
    () =>
      !mapPlaces
        ? []
        : !searchIds
        ? mapPlaces
        : mapPlaces.filter((_place) => searchIds.includes(_place.place_id)),
    [mapPlaces, searchIds]
  );

  const pins = usePins(placesFromSearch, {
    ...currentRegion,
    latitudeDelta: Math.exp(
      Math.log(360) - (currentRegion.zoom - 1) * Math.LN2
    ),
    longitudeDelta: Math.exp(
      Math.log(360) - (currentRegion.zoom - 1) * Math.LN2
    ),
  });

  useEffect(() => {
    loadPins([]);
  }, []);

  // const [selectedPin, setSelectedPin] = useState<{
  //   place?: FullPlaceType;
  //   cluster?: ClusterType;
  // } | null>(null);

  useEffect(() => {
    if (selectedPlace) {
      setCurrentRegion({
        latitude: selectedPlace.latitude,
        longitude: selectedPlace.longitude,
        zoom: Math.max(currentRegion.zoom, 16),
      });
    }
  }, [selectedPlace]);

  const isMobile = useIsMobile();

  const placeRoute = (place_id: string, booking_dates: string[] = []) => {
    window.open(
      "/place/" + place_id + "?bookingTime=" + JSON.stringify(booking_dates),
      "_blank"
    );
  };

  const [isMapScrollable, setIsMapScrollable] = useState(true);

  return (
    <View style={[{ flex: 1, position: "relative" }, style]}>
      <MapView
        onClick={() => onPlaceSelect(null)}
        onRegionChangeComplete={(region) => {
          region && setCurrentRegion(region);
        }}
        isMapScrollable={isMapScrollable}
        position={currentRegion}
        initialRegion={defaultRegion}
      >
        {selectedPlace && (
          <View
            style={{ zIndex: 11, position: "relative" }}
            key={selectedPlace.place_id}
            lat={selectedPlace.latitude}
            lng={selectedPlace.longitude}
          >
            <MapPin place={selectedPlace} active />

            {!isMobile && (
              <View
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  position: "absolute",
                  transform: "translateY(-100%) translateX(-100%)",
                }}
              >
                <PlaceCardMedium
                  bookingDataParams={
                    !filters.bookingMode ? filters.bookingFilters : undefined
                  }
                  onHover={() => setIsMapScrollable(false)}
                  onHoverOut={() => setIsMapScrollable(true)}
                  style={{ width: 240 }}
                  onArrowPress={() =>
                    placeRoute(
                      selectedPlace.place_id,
                      selectedPlace.booking_dates
                    )
                  }
                  place={selectedPlace}
                />
              </View>
            )}
          </View>
        )}

        {pins?.map(
          (it) =>
            it.place_id !== selectedPlace?.place_id && (
              <View
                onClick={(e) => {
                  e.stopPropagation();

                  const currentPin = pins.find(
                    (_place) => _place.place_id === it.place_id
                  );

                  if (currentPin?.pinType !== "cluster") {
                    if (
                      selectedPlace &&
                      selectedPlace?.place_id === e.nativeEvent.id
                    ) {
                      onPlaceSelect(null);
                    } else {
                      onPlaceSelect(currentPin.place_id);
                    }
                  } else {
                    setCurrentRegion((prev) => ({
                      ...prev,
                      latitude: currentPin.latitude,
                      longitude: currentPin.longitude,
                      zoom: Math.min(currentRegion.zoom + 1, 17),
                    }));
                  }

                  // if (currentPin?.pinType === "cluster") {
                  //   if (
                  //     selectedPin?.cluster &&
                  //     selectedPin.cluster.place_id === it.place_id
                  //   ) {
                  //     setSelectedPin(null);
                  //   } else {
                  //     setSelectedPin({ cluster: currentPin });
                  //   }
                  // } else {
                  //   if (
                  //     selectedPin?.place &&
                  //     selectedPin?.place.place_id === e.nativeEvent.id
                  //   ) {
                  //     setSelectedPin(null);
                  //   } else {
                  //     setSelectedPin({ place: currentPin });
                  //   }
                  // }
                }}
                style={{ cursor: "pointer" }}
                lat={it.latitude}
                key={it.place_id}
                lng={it.longitude}
              >
                {it.pinType === "cluster" ? (
                  <ClusterPin
                    // active={
                    //   selectedPin?.cluster &&
                    //   selectedPin.cluster.place_id === it.place_id
                    // }
                    places={it.places.filter(
                      (_place) => _place.place_id !== selectedPlace?.place_id
                    )}
                    count={
                      it.places.find(
                        (_place) => _place.place_id === selectedPlace?.place_id
                      )
                        ? it.numberOfPlaces - 1
                        : it.numberOfPlaces
                    }
                  />
                ) : (
                  <MapPin place={it} />
                )}
              </View>
            )
        )}
      </MapView>

      {(isPlacesLoading || isPinsLoading) && (
        <OrangeButton
          style={{
            position: "absolute",
            top: 80,
            left: "50%",
            transform: [{ translateX: "-50%" }],
          }}
          isLoading={true}
          title={isPinsLoading ? "Places loading" : "Updating results"}
        />
      )}
    </View>
  );
};

export const OrangeButton = ({
  title,
  style,
  onPress,
  isLoading = false,
}: {
  style?: StyleProp<ViewStyle>;
  title: string;
  onPress?: () => void;
  isLoading?: boolean;
}) => {
  return (
    <View style={style}>
      <Pressable onPress={onPress}>
        <LinearGradient
          useAngle
          angle={45}
          colors={["#D17C0C", "#E56456"]}
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 20,
            paddingVertical: 8,
            borderRadius: 28,
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              color: colors.white,
              marginRight: 8,
              ...fonts.mediumText_semiBold,
            }}
          >
            {title}
          </Text>
          {isLoading && <ActivityIndicator color={colors.white} />}
        </LinearGradient>
      </Pressable>
    </View>
  );
};
