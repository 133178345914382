import React, { useEffect } from "react";
import "./App.css";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";
import { SubscriptionWrapper } from "./pages/Navigation";
import { Dimensions, View } from "react-native";

function AppNavigation() {
  return <SubscriptionWrapper />;
}

function App() {
  return (
    <BrowserRouter>
      <AppNavigation />
    </BrowserRouter>
  );
}

const NotFoundNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("../not-found", { replace: true });
  }, []);

  return (
    <Routes>
      <Route
        path="*"
        element={
          <View style={{ height: Dimensions.get("window").height }}>
            Not Found
          </View>
        }
      />
    </Routes>
  );
};

export default App;
