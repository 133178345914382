import React, { useEffect, useMemo, useState } from "react";
import {
  View,
  Text,
  Pressable,
  StyleSheet,
  FlatList,
  useWindowDimensions,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import { TabButton } from "../components/TabButton";
import { ReactComponent as ChatBlackIcon } from "@gadder/common/src/assets/svg/chat_black.svg";
import { ReactComponent as ChatLightIcon } from "@gadder/common/src/assets/svg/chat_light.svg";
import { ReactComponent as MapBlackIcon } from "@gadder/common/src/assets/svg/map_tab_black.svg";
import { ReactComponent as MapWhiteIcon } from "@gadder/common/src/assets/svg/map_tab_light.svg";
import { ReactComponent as GadderLogo } from "@gadder/common/src/assets/svg/gadder_logo_big_full.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import LinearGradient from "react-native-web-linear-gradient";
import { CommonInput } from "../components/inputs";
import { InputSelect } from "../components/InputSelect";
import { DatePicker, getBookingMinDate } from "../components/DatePicker";
import { CommonButton } from "@gadder/common/src/components/buttons";
import {
  mapSearchPlacesClearAllBookingDataAction,
  mapSearchPlacesGetAction,
} from "../store/mapSearchPlaces/action";
import _ from "lodash";
import { PlaceCardMedium } from "@gadder/common/src/components/PlaceCardMedium";
import { useIsMobile } from "./ChatPage";
import { MobileHeader } from "../components/MobileHeader";
import { DrawerWrapper } from "../components/drawerWrapper";
import { SideMenu, SideMenuTabs } from "../components/chat/sideMenu";
import { ReactComponent as WhatsAppIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_2.svg";
import { ReactComponent as WhatsAppQrIcon } from "@gadder/common/src/assets/svg/whatsapp_qr_code.svg";
import { WhatsappButton } from "../components/WhatsappButton";
import { BugButton } from "../components/BugButton";

export const MainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((store: RootState) => store.user);

  const [searchValue, setSearchValue] = useState("");

  const [selectedFilters, setSelectedFilters] = useState({
    personsCount: "2",
    date: getBookingMinDate(new Date()),
  });

  const searchPlacesState = useSelector(
    (state: RootState) => state.mapSearchPlaces
  );

  const [loadSearchPlacesToken, loadSearchPlaces] = useUniqueDispatch(
    mapSearchPlacesGetAction.request
  );

  useEffect(() => {
    loadSearchPlaces([]);
  }, []);

  const isPLacesLoading = isLoading(searchPlacesState, loadSearchPlacesToken);

  const { width } = useWindowDimensions();

  const isMobile = useIsMobile();

  const [placesIdsOrder, setPlacesIdsOrder] = useState([]);

  useEffect(() => {
    if (searchPlacesState.data && !placesIdsOrder.length) {
      const numPlaces = isMobile ? 5 : width > 1700 ? 5 : width > 1400 ? 4 : 3;
      setPlacesIdsOrder(
        _.shuffle(
          searchPlacesState.data.data.map((_place) => _place.place_id)
        ).slice(0, numPlaces)
      );
    }
  }, [searchPlacesState.data]);

  const places = useMemo(() => {
    return searchPlacesState.data?.data && placesIdsOrder.length > 0
      ? searchPlacesState.data?.data.filter((_place) =>
          placesIdsOrder.includes(_place.place_id)
        )
      : [];
  }, [placesIdsOrder, searchPlacesState.data]);

  const [clearBookingDataToken, clearBookingData] = useUniqueDispatch(
    mapSearchPlacesClearAllBookingDataAction
  );

  useEffect(() => {
    clearBookingData([]);
  }, [selectedFilters]);

  const findHandler = () => {
    navigate("/map", {
      state: {
        searchParams: {
          searchValue,
          personsCount: selectedFilters.personsCount,
          date: selectedFilters.date,
        },
      },
    });
  };

  const placeRoute = (place_id: number) => {
    window.open("/place/" + place_id, "_blank");
  };

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  return (
    <View style={[styles.pageWrapper, isMobile && { flexDirection: "column" }]}>
      {isMobile && (
        <MobileHeader onBurgerPress={() => setIsMobileDrawerOpen(true)} />
      )}
      <ScrollView showsVerticalScrollIndicator={false} style={styles.content}>
        {!isMobile && (
          <View style={styles.header}>
            <GadderLogo />

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <WhatsAppQrIcon height={100} width={100} />
              <Text
                style={[
                  { color: colors.black, marginLeft: 11, marginRight: 6 },
                  fonts.smallMediumText_semiBold,
                ]}
              >
                Open to speak with our AI concierge
              </Text>
              <WhatsAppIcon height={70} width={70} />
            </View>

            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  flexDirection: "row",
                  gap: 12,
                  alignSelf: "center",
                  marginRight: 24,
                }}
              >
                {/*<TabButton*/}
                {/*  label={"Placelists"}*/}
                {/*  isSelected={selectedTab === SideMenuTabs.PLACELIST}*/}
                {/*  Icon={PlacelistBlackIcon}*/}
                {/*  ActiveIcon={PlacelistWhiteIcon}*/}
                {/*  onPress={() => navigate("/placelists")}*/}
                {/*/>*/}

                <TabButton
                  textStyle={fonts.mediumText_bold}
                  containerStyle={styles.tab}
                  label={"Chats"}
                  isSelected={false}
                  Icon={ChatBlackIcon}
                  ActiveIcon={ChatLightIcon}
                  onPress={() =>
                    user.data
                      ? navigate("/bot")
                      : navigate("/login-modal", {
                          state: { previousLocation: location },
                        })
                  }
                />

                <TabButton
                  containerStyle={styles.tab}
                  textStyle={fonts.mediumText_bold}
                  label={"Map"}
                  isSelected={false}
                  Icon={MapBlackIcon}
                  ActiveIcon={MapWhiteIcon}
                  onPress={() => navigate("/map")}
                />
              </View>

              <Pressable
                onPress={() =>
                  user.data ? navigate("/profile") : navigate("/login")
                }
                style={{ alignItems: "center" }}
              >
                <UserAvatar size={52} user={user.data} />
                <Text style={{ ...fonts.mediumText_bold, color: colors.black }}>
                  {user.data ? "Profile" : "Log in"}
                </Text>
              </Pressable>
            </View>
          </View>
        )}

        <View>
          <View
            style={[
              styles.blackBanner,
              isMobile && {
                marginHorizontal: 16,
                marginTop: 32,
                paddingTop: 12,
                paddingBottom: 14,
                marginBottom: 0,
              },
            ]}
          >
            <LinearGradient
              useAnge
              colors={["#2C2C2C", "#000000"]}
              style={[styles.blackGradient, isMobile && { borderRadius: 10 }]}
            />
            <Text
              style={[
                fonts.huge_extraBold,
                {
                  color: colors.white,
                  marginBottom: 58,
                },
                isMobile && { ...fonts.largeText_extraBold, marginBottom: 19 },
              ]}
            >
              <Text style={{ color: colors.newBlue }}>Book</Text> your perfect
              table
            </Text>

            <View
              style={[
                { alignSelf: "stretch", paddingHorizontal: 80 },
                isMobile && { paddingHorizontal: 14 },
              ]}
            >
              <Text
                style={[
                  {
                    ...fonts.mediumText_medium,
                    color: colors.white,
                    marginBottom: 8,
                  },
                  isMobile && { ...fonts.smallMediumText_medium },
                ]}
              >
                Search by location, cuisine or vibe.
              </Text>
              <View style={{ flexDirection: width > 1200 ? "row" : "column" }}>
                <CommonInput
                  textInputStyle={styles.input}
                  style={styles.inputContainer}
                  placeholder={"eg. Soho, Japanese, Romantic ..."}
                  returnKeyType={"search"}
                  value={searchValue}
                  onChangeText={setSearchValue}
                  onKeyPress={(event) => {
                    if (
                      (event.nativeEvent.key === "Enter" ||
                        event.nativeEvent.key === "NumpadEnter") &&
                      searchValue
                    ) {
                      event.preventDefault();
                      findHandler();
                      // onSearchPress();
                    }
                  }}
                />

                <View
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    marginTop: isMobile ? 8 : width > 1200 ? 0 : 24,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: width > 1200 ? 8 : 0,
                      height: 47,
                      flex: isMobile ? "unset" : 1,
                      marginBottom: isMobile ? 8 : 0,
                    }}
                  >
                    <InputSelect
                      containerStyle={{ fontSize: 16 }}
                      value={selectedFilters.personsCount}
                      iconContainerStyle={{ top: 12 }}
                      style={{ marginRight: 8 }}
                      onChange={(_count) =>
                        setSelectedFilters((prevState) => ({
                          ...prevState,
                          personsCount: _count,
                        }))
                      }
                    />

                    <DatePicker
                      style={{ flex: 1 }}
                      dateButtonStyle={{
                        paddingVertical: "unset",
                        height: "100%",
                        justifyContent: "center",
                      }}
                      timeStyle={{ fontSize: 16 }}
                      dateButtonTextStyle={fonts.mediumText_regular}
                      date={selectedFilters?.date}
                      setDate={(_date) =>
                        setSelectedFilters((prevState) => ({
                          ...prevState,
                          date: _date,
                        }))
                      }
                    />
                  </View>

                  <View style={{ height: 47 }}>
                    <CommonButton
                      onPress={findHandler}
                      textStyle={fonts.mediumText_semiBold}
                      containerStyle={[
                        styles.blueButton,
                        isMobile && { width: "100%" },
                      ]}
                      style={[
                        { marginLeft: 32, flex: 1 },
                        isMobile && { marginLeft: 0 },
                      ]}
                      title={isMobile ? "Search" : "Find your perfect table"}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>

          {!isMobile && (
            <Text
              style={{
                ...fonts.mediumLargeText_medium,
                textAlign: "center",
                marginBottom: 54,
              }}
            >
              Discover and book London's best restaurants with Gadder.{"\n"}
              Real-time availability and personalised recommendations for any
              occasion.
            </Text>
          )}

          {!isMobile && (
            <BugButton
              style={{ position: "absolute", top: 350, right: 78 }}
              withNote
              size={85}
              label={"Report an\nissue"}
            />
          )}

          <View style={{ zIndex: -1 }}>
            <Text
              style={[
                {
                  ...fonts.mediumLargeText_semiBold,
                  marginBottom: 42,
                  marginHorizontal: 58,
                },
                isMobile && {
                  ...fonts.mediumText_semiBold,
                  marginHorizontal: 16,
                  marginBottom: 12,
                  paddingTop: 24,
                },
              ]}
            >
              Some places you might like:
            </Text>

            {isPLacesLoading ? (
              <ActivityIndicator color={colors.black} style={{ flex: 1 }} />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingHorizontal: 58,
                    zIndex: -1,
                    paddingBottom: 64,
                  },
                  isMobile && {
                    flexDirection: "column",
                    paddingHorizontal: 16,
                    gap: 16,
                    paddingBottom: 32,
                  },
                ]}
              >
                {places.map((_place) => (
                  <PlaceCardMedium
                    bookingDataParams={{
                      date: selectedFilters.date,
                      personsCount: selectedFilters.personsCount,
                    }}
                    onPress={() => placeRoute(_place.place_id)}
                    imageContainer={{ height: 210 }}
                    style={{ width: isMobile ? "100%" : 304 }}
                    onArrowPress={() => placeRoute(_place.place_id)}
                    imageCarouselMode={"onHover"}
                    place={_place}
                  />
                ))}
              </View>
            )}
          </View>
        </View>
      </ScrollView>

      {isMobile && (
        <WhatsappButton
          style={{ position: "absolute", right: 26, bottom: 30, zIndex: 1 }}
        />
      )}

      {isMobile && (
        <BugButton
          label={"Bug"}
          style={{ position: "absolute", left: 26, bottom: 30, zIndex: 1 }}
        />
      )}

      {isMobile && (
        <DrawerWrapper
          onClose={() => setIsMobileDrawerOpen(false)}
          isOpen={isMobileDrawerOpen}
        >
          <SideMenu />
        </DrawerWrapper>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  pageWrapper: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
  },
  content: {
    height: "100%",
    width: "100%",
    maxWidth: 1920,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 48,
    paddingHorizontal: 58,
    paddingTop: 24,
  },
  tab: { paddingHorizontal: 18, paddingVertical: 8 },

  inputContainer: {
    backgroundColor: colors.white,
    borderWidth: 0,
    flex: 1,
  },
  input: {
    paddingVertical: 12,
    paddingHorizontal: 15,
    ...fonts.mediumText_regular,
  },
  blackBanner: {
    marginHorizontal: 152,
    paddingTop: 65,
    paddingBottom: 80,
    alignItems: "center",
    alignSelf: "stretch",
    position: "relative",
    borderRadius: 25,
    marginBottom: 40,
  },
  blackGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    borderRadius: 25,
  },
  blueButton: {
    backgroundColor: colors.newBlue,
    color: colors.white,
    borderRadius: 10,
    paddingHorizontal: 23,
    paddingVertical: "unset",
    height: "100%",
    borderWidth: 0,
  },
});
