import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { placelistsGetAction } from "./action";

export const getRequest = function* (
  action: ReturnType<typeof placelistsGetAction.request>
) {
  try {
    let response = yield call(() =>
      api.placelists.methods.get(...action.payload)
    );

    yield put(placelistsGetAction.success(response.data, action.meta));
  } catch (error) {
    yield put(placelistsGetAction.failure(error, action.meta));
  }
};

export const placelistsSaga = function* () {
  yield all([takeEvery(placelistsGetAction.request, getRequest)]);
};
