import {
  FiltersPriceTranslates,
  FiltersTypeTranslates,
} from "../types/translates";

export const filterMeilisearchImplementation = (filters) => {
  return [
    ...(filters?.type.length > 0
      ? [
          "types IN [" +
            filters?.type
              .map((_type) => FiltersTypeTranslates[_type].toLowerCase())
              .join(", ") +
            "]",
        ]
      : []),
    ...(filters?.cuisine.length > 0
      ? [
          "cuisine IN [" +
            filters?.cuisine
              .map((_cuisine) => "'" + _cuisine + "'")
              .join(", ") +
            "]",
        ]
      : []),
    ...(filters?.feature.length > 0
      ? [
          "feature IN [" +
            filters?.feature
              .map((_feature) => "'" + _feature + "'")
              .join(", ") +
            "]",
        ]
      : []),

    ...(filters?.price.length > 0
      ? [
          "price IN [" +
            filters?.price
              .map((_price) => "'" + FiltersPriceTranslates[_price] + "'")
              .join(", ") +
            "]",
        ]
      : []),

    ...(filters?.tag.length > 0
      ? [
          "tag IN [" +
            filters?.tag.map((_tag) => "'" + _tag + "'").join(", ") +
            "]",
        ]
      : []),
  ];
};
