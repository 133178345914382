import api from "../storeApi";
import { all, call, put, takeEvery, take } from "redux-saga/effects";
import { mapSearchPlacesGetAction } from "./action";

export const getRequest = function* (
  action: ReturnType<typeof mapSearchPlacesGetAction.request>
) {
  try {
    const response = yield call(() =>
      api.advancedSearchPlaces.methods.postAdvancesSearchPlaces(
        ...action.payload
      )
    );

    yield put(
      mapSearchPlacesGetAction.success(
        { ...response.data, data: response.data.data },
        action.meta
      )
    );
  } catch (error) {
    yield put(mapSearchPlacesGetAction.failure(error, action.meta));
  }
};

export const mapSearchPlacesSaga = function* () {
  yield all([takeEvery(mapSearchPlacesGetAction.request, getRequest)]);
};
