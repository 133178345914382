import React, { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import {
  StyleProp,
  View,
  ViewStyle,
  StyleSheet,
  Pressable,
} from "react-native";
import { eachHourOfInterval, eachMinuteOfInterval, format } from "date-fns";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import { ReactComponent as UserIcon } from "@gadder/common/src/assets/svg/user_black.svg";
import { ReactComponent as GreyUserIcon } from "@gadder/common/src/assets/svg/user_grey.svg";

export const InputSelect = ({
  value,
  onChange,
  isActive = true,
  disabled = false,
  style,
  containerStyle,
  menuStyle,
  iconContainerStyle,
}: {
  disabled?: boolean;
  isActive?: boolean;
  value: string;
  onChange?: (value: string) => void;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  menuStyle?: StyleProp<ViewStyle>;
}) => {
  const options = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8+", value: "8+" },
  ];

  return (
    <View
      style={{
        alignSelf: "stretch",
        backgroundColor: isActive ? colors.white : colors.gray90,
        borderWidth: 1,
        borderRadius: 10,
        borderStyle: "solid",
        borderColor: colors.gray81,
        cursor: "pointer",
        ...style,
      }}
    >
      <Select
        isSearchable={false}
        onChange={(_value) => {
          onChange(_value.value);
        }}
        isDisabled={disabled || !isActive}
        value={options.find((_option) => _option.value === value)}
        options={options}
        styles={{
          indicatorsContainer: () => ({ display: "none" }),
          valueContainer: () => ({
            padding: 0,
            flexDirection: "row",
            width: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingLeft: 20,
            ...containerStyle,
          }),
          singleValue: () => ({ margin: 0 }),
          input: () => ({ padding: 0, margin: 0, width: 0, opacity: 0 }),
          control: () => ({ minHeight: 0, height: "100%" }),
          container: () => ({
            position: "relative",
            color: isActive ? colors.black : colors.gray50,
            height: "100%",

            ...fonts.smallMediumText_regular,
            lineHeight: "unset",
          }),
          menu: () => ({
            width: 60,
            position: "absolute",
            left: 0,
            top: "calc(100% + 16px)",
            borderRadius: 10,
            backgroundColor: colors.white,
            ...menuStyle,
          }),
        }}
      />

      <View
        style={[
          { position: "absolute", top: 6, left: 8, zIndex: -1, height: 20 },
          iconContainerStyle,
        ]}
      >
        {isActive ? <UserIcon /> : <GreyUserIcon />}
      </View>
    </View>
  );
};
