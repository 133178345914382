import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Pressable,
  Linking,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import useUniqueDispatch, {
  isData,
  isLoading,
  isError,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import {
  chatbotGetChatAction,
  chatbotPostMessageAction,
  chatbotPostMessageEventAction,
  chatbotSubscribeAction,
  chatbotUnsubscribeAction,
} from "../store/chatbot/action";
import { ReactComponent as BurgerIcon } from "@gadder/common/src/assets/svg/burger.svg";
import { DrawerWrapper } from "../components/drawerWrapper";
import { Chat } from "../components/chat";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { ChatContext } from "./Navigation";
import { ChatListPanel } from "../components/chat/chatListPanel";
import { ReactComponent as WhatsAppIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_3.svg";
import { ReactComponent as GadderLogo } from "@gadder/common/src/assets/svg/gadder_with_logo_black.svg";
import { useNavigate } from "react-router-dom";
import { WhatsappButton } from "../components/WhatsappButton";
import { BugButton } from "../components/BugButton";

export const useIsMobile = () => {
  const dimensions = useWindowDimensions();
  return dimensions.width <= 960;
};

export const ChatPage = ({ modalMode = false }: { modalMode?: boolean }) => {
  const { lastOpenedChatId, setLastOpenedChatId } = useContext(ChatContext);

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const [selectedChatId, setSelectedChatId] = useState<number | null>(
    modalMode ? 0 : lastOpenedChatId || null
  );

  const chatRequest = useSelector((store: RootState) => store.chatbot);

  const [getChatToken, getChat] = useUniqueDispatch(
    chatbotGetChatAction.request
  );

  const isChatLoading = isLoading(chatRequest, getChatToken);

  // const [submitMessageToken, submitMessage] = useUniqueDispatch(
  //   chatbotPostMessageAction.request
  // );

  const [chatSubscribeToken, chatSubscribe] = useUniqueDispatch(
    chatbotSubscribeAction.request
  );

  const [chatUnsubscribeToken, chatUnsubscribe] = useUniqueDispatch(
    chatbotUnsubscribeAction.request
  );

  const [postMessageToken, postMessage] = useUniqueDispatch(
    chatbotPostMessageEventAction.request
  );

  useEffect(() => {
    chatSubscribe([]);

    return () => {
      chatUnsubscribe([]);
    };
  }, []);

  const onSubmitMessage = (chatId: number, message: string) => {
    postMessage([
      {
        chat_id: chatId,
        message_text: message,
        optimistic_uuid: new Date().getTime(),
      },
    ]);
  };

  const chat = useMemo(
    () => (modalMode ? { id: 0, messages: [] } : chatRequest.data),
    [chatRequest.data, modalMode]
  );

  useEffect(() => {
    if (selectedChatId) {
      getChat([selectedChatId]);
    }
  }, [selectedChatId]);

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(
    modalMode ? false : !selectedChatId
  );

  return (
    <View style={{ flex: 1, flexDirection: isMobile ? "column" : "row" }}>
      {!isMobile && (
        <ChatListPanel
          selectedChatId={selectedChatId}
          setSelectedChatId={setSelectedChatId}
        />
      )}

      {isChatLoading && chatRequest.data?.id !== selectedChatId ? (
        <ActivityIndicator color={colors.black} style={{ flex: 1 }} />
      ) : (
        <>
          {isMobile && (
            <View
              style={{
                height: 54,
                borderBottomWidth: 1,
                borderBottomColor: colors.gray81,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                paddingHorizontal: 32,
              }}
            >
              <TouchableOpacity onPress={() => setIsMobileDrawerOpen(true)}>
                <BurgerIcon width={28} height={28} />
              </TouchableOpacity>

              <Pressable onPress={() => navigate("/", { replace: true })}>
                <GadderLogo width={90} />
              </Pressable>
            </View>
          )}
          <Chat
            isError={chat?.botError}
            onChatClosing={setLastOpenedChatId}
            isLoading={chat?.isBotResponding}
            onSubmitMessage={onSubmitMessage}
            chat={chat}
          />
        </>
      )}

      <WhatsappButton
        style={{ position: "absolute", right: 26, bottom: 80, zIndex: 1 }}
      />

      {isMobile && (
        <DrawerWrapper
          onClose={() => setIsMobileDrawerOpen(false)}
          isOpen={isMobileDrawerOpen}
        >
          <ChatListPanel
            selectedChatId={selectedChatId}
            setSelectedChatId={(id) => {
              setSelectedChatId(id);
              setIsMobileDrawerOpen(false);
            }}
          />
        </DrawerWrapper>
      )}
    </View>
  );
};
