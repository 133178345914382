import api from "../storeApi";
import { all, call, put, takeEvery, take } from "redux-saga/effects";
import {
  bookingSearchAction,
  bookingSearchEndAction,
  bookingSearchNextPlacesAction,
  bookingSearchStartAction,
  bookingSearchSubscribeAction,
  bookingSearchUnsubscribeAction,
} from "./action";
import { RootState } from "../root-reducer";
import { io, Socket } from "socket.io-client";
import { eventChannel, EventChannel } from "redux-saga";
import { BASE_PATH } from "../../api/constants";
import store from "../index";

export const bookingPlacesSaga = function* () {
  yield all([
    takeEvery(bookingSearchSubscribeAction.request, subscribe),
    takeEvery(bookingSearchUnsubscribeAction.request, unsubscribe),
    takeEvery(bookingSearchAction.request, searchBooking),
  ]);
};

const socketStorage: {
  socket;
} = {
  socket: Socket,
};

const searchBooking = function* (action) {
  yield call(() => {
    socketStorage.socket.emit("get-booking-places", action.payload[0]);
  });
};

const bookingSearchDurationMs = 1000 * 60 * 4;

const subscribe = function* () {
  console.log("BASE_PATH", BASE_PATH);

  const socket = yield call(() =>
    io(BASE_PATH || "https://test.gadderapp.com")
  );

  socketStorage.socket = socket;

  yield call(() =>
    socket.on("booking-start", (arg) => {
      store.dispatch(bookingSearchStartAction.success([arg]));
      setTimeout(
        () => store.dispatch(bookingSearchEndAction.success([arg])),
        bookingSearchDurationMs
      );
    })
  );

  yield call(() =>
    socket.on("booking-next-places", (arg) => {
      store.dispatch(bookingSearchNextPlacesAction.success([arg]));
    })
  );

  yield call(() =>
    socket.on("booking-end", (arg) => {
      store.dispatch(bookingSearchEndAction.success([arg]));
    })
  );
};

const unsubscribe = function* () {
  if (socketStorage.socket) {
    yield call(() => socketStorage.socket.disconnect());
  }
};
