import React, { useEffect, useRef, useState } from "react";
import {
  StyleProp,
  View,
  ViewStyle,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  ImageBackground,
  Pressable,
} from "react-native";
import { ReactComponent as ArrowLight } from "../assets/svg/arrow_big_light.svg";
import { colors } from "../styles/colors";

export const PhotoCarousel = ({
  style,
  showType = null,
  containerStyle,
  overlay = false,
  data,
  imageStyle,
}: {
  showType?: "always" | "onHover" | "none" | null;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  overlay?: boolean;
  data: string[];
  imageStyle?: StyleProp<ViewStyle>;
}) => {
  const [containerHeight, setContainerHeight] = useState(0);

  const ref = useRef<FlatList | null>(null);

  //Todo: remake currentPosition into ref value, add animations

  const [scrollContainerWidth, setScrollContainerWidth] = useState<number>(0);

  const [isHovered, setIsHovered] = useState(false);

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    setScrollProgress(0);
  }, [data]);

  const nextSlideHandle = () => {
    const currentSlideIndex = Math.floor(scrollProgress / scrollContainerWidth);

    const newSlideIndex =
      currentSlideIndex === data.length - 1 ? 0 : currentSlideIndex + 1;

    if (data.length > 0) {
      ref.current?.scrollToOffset({
        offset: scrollContainerWidth * newSlideIndex,
      });
    }
  };

  const prevSlideHandle = () => {
    if (data.length > 0) {
      ref.current?.scrollToOffset({
        offset:
          scrollContainerWidth *
          (Math.floor(scrollProgress / scrollContainerWidth) - 1),
      });
    }
  };

  return (
    <View
      onLayout={(event) => setContainerHeight(event.nativeEvent.layout.height)}
      style={[style, { position: "relative" }]}
    >
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <Pressable
            onHoverIn={() => setIsHovered(true)}
            onHoverOut={() => setIsHovered(false)}
            onPress={nextSlideHandle}
            disabled={showType === "none"}
            key={index}
          >
            <ImageBackground
              style={[
                {
                  width: scrollContainerWidth,
                  height: containerHeight,
                  aspectRatio: 1,
                },
                imageStyle,
              ]}
              source={{ uri: item }}
            >
              {overlay && (
                <View
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: colors.black,
                    zIndex: 1,
                    opacity: 0.2,
                  }}
                />
              )}
            </ImageBackground>
          </Pressable>
        )}
        ref={ref}
        // scrollEnabled={false}
        onScroll={(e) => {
          setScrollProgress(e.nativeEvent.contentOffset.x);
        }}
        snapToAlignment={"center"}
        decelerationRate={"fast"}
        snapToInterval={scrollContainerWidth}
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        horizontal
        scrollEnabled={showType !== "none"}
        onLayout={(e) => setScrollContainerWidth(e.nativeEvent.layout.width)}
        style={[styles.pagerView, containerStyle]}
      />

      {scrollProgress >= scrollContainerWidth * 0.5 && (
        <Pressable
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
          style={[
            styles.arrowContainer,
            { top: containerHeight / 2 - 30, left: 8 },
            showType === "onHover" && { opacity: isHovered ? 1 : 0 },
            showType === "always" && { opacity: 1 },
            showType === "none" && { opacity: 0 },
          ]}
          onPress={prevSlideHandle}
        >
          <ArrowLight
            style={{
              transform: "rotate(180deg)",
              left: 12,
              ...styles.arrow,
            }}
          />
        </Pressable>
      )}

      {scrollProgress < scrollContainerWidth * (data.length - 1.5) && (
        <Pressable
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
          style={[
            styles.arrowContainer,
            { top: containerHeight / 2 - 30, right: 8, alignItems: "flex-end" },
            showType === "onHover" && { opacity: isHovered ? 1 : 0 },
            showType === "always" && { opacity: 1 },
            showType === "none" && { opacity: 0 },
          ]}
          onPress={nextSlideHandle}
        >
          <ArrowLight style={{ right: 12, ...styles.arrow }} />
        </Pressable>
      )}

      {data.length > 1 && (
        <View
          style={[
            styles.pagingContainer,
            showType === "onHover" && { opacity: isHovered ? 1 : 0 },
            showType === "always" && { opacity: 1 },
            showType === "none" && { opacity: 0 },
          ]}
        >
          {data.map((item, index) => (
            <View
              key={index}
              style={[
                {
                  opacity:
                    index ===
                    Math.floor(scrollProgress / scrollContainerWidth + 0.5)
                      ? 1
                      : 0.4,
                },
                styles.pagingIndicator,
              ]}
            />
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  pagerView: {
    height: "100%",
    width: "100%",
    zIndex: 0,
    borderRadius: 12,
    overflow: "hidden",
  },
  pagingIndicator: {
    height: 4,
    flex: 1,
    backgroundColor: colors.LightGrayishYellow,
    borderRadius: 4,
    marginHorizontal: 4,
    elevation: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.6,
    shadowRadius: 4,
  },
  pagingContainer: {
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: 8,
    position: "absolute",
    bottom: 8,
  },
  withOpacity: { backgroundColor: "white", opacity: 0.4, zIndex: 10 },
  arrow: {},
  arrowContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    justifyContent: "center",
  },
});
