import React, { useState } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  StyleProp,
  ViewStyle,
} from "react-native";
import { ReactComponent as ShareIcon } from "../assets/svg/share_black.svg";
import { colors } from "../styles/colors";
import { fonts } from "../styles/fonts";

export const ShareButton = ({
  onPress,
  toolTipStyle,
  iconWidth = 33,
  iconHeight = 33,
  toolTipTextStyle,
}: {
  onPress?: () => void;
  toolTipTextStyle?: StyleProp<ViewStyle>;
  toolTipStyle?: StyleProp<ViewStyle>;
  iconWidth?: number;
  iconHeight?: number;
}) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const pressHandle = () => {
    onPress();
    setIsTooltipShown(true);
    setTimeout(() => setIsTooltipShown(false), 1000);
  };

  return (
    <View style={{ position: "relative", alignItems: "center" }}>
      <TouchableOpacity onPress={pressHandle}>
        <ShareIcon width={iconWidth} height={iconHeight} />
      </TouchableOpacity>
      <Text style={[fonts.little_regular, { color: colors.black }]}>
        Copy link
      </Text>

      {isTooltipShown && (
        <View
          style={[
            {
              position: "absolute",
              top: -30,
              right: -8,
              ...toolTipStyle,
            },
          ]}
        >
          <Text
            style={[
              {
                backgroundColor: colors.lightGray,
                color: colors.black,
                paddingHorizontal: 4,
                paddingVertical: 4,
                borderRadius: 8,
              },
              toolTipTextStyle,
            ]}
          >
            Copied
          </Text>
        </View>
      )}
    </View>
  );
};
