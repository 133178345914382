import React, { ReactNode, useState } from "react";
import GoogleMapReact from "google-map-react";

export interface Region {
  latitude: number;
  longitude: number;
  zoom: number;
}

export const MapView = ({
  initialRegion,
  onRegionChangeComplete,
  onRegionChange,
  children,
  onClick,
  isMapScrollable = true,
  position,
}: {
  position?: Region;
  initialRegion?: Region;
  onRegionChangeComplete?: (region: Region) => void;
  onRegionChange?: (region: Region) => void;
  children?: ReactNode;
  onClick?: () => void;
  isMapScrollable?: boolean;
}) => {
  const [map, setMap] = useState<any>(null);

  const _getCurrentRegion = () => {
    const center = map.getCenter();
    const zoom = map.getZoom();

    return {
      latitude: center.lat(),
      longitude: center.lng(),
      zoom: zoom,
    };
  };

  const onDragStart = () => {
    if (map && onRegionChange) {
      onRegionChange(_getCurrentRegion());
    }
  };

  const onDragEnd = () => {
    if (map && onRegionChangeComplete) {
      onRegionChangeComplete(_getCurrentRegion());
    }
  };

  const onZoomEnd = () => {
    if (map && onRegionChangeComplete) {
      const newRegion = _getCurrentRegion();
      onRegionChangeComplete(newRegion);
    }
  };

  return (
    <GoogleMapReact
      zoom={position.zoom}
      center={{ lat: position.latitude, lng: position.longitude }}
      onGoogleApiLoaded={({ map }) => {
        map.setClickableIcons(false);
        setMap(map);
      }}
      defaultZoom={initialRegion.zoom}
      defaultCenter={{
        lat: initialRegion.latitude,
        lng: initialRegion.longitude,
      }}
      onDrag={onDragStart}
      onDragEnd={onDragEnd}
      onZoomAnimationEnd={onZoomEnd}
      options={{
        streetViewControl: false,
        disableDefaultUI: true,
        scrollwheel: isMapScrollable,
      }}
      onClick={onClick}
      bootstrapURLKeys={{ key: "AIzaSyB3Y6quV8-iwv1EQvL7U929qBJP815qKVE" }}
    >
      {children}
    </GoogleMapReact>
  );
};
export default MapView;
