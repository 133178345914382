import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

export function ArrowLeft(props: any) {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <G id="arrow-left (1) 1">
        <Path
          id="Vector"
          d="M25.3332 16H6.6665"
          stroke="#67636C"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          id="Vector_2"
          d="M15.9998 25.3332L6.6665 15.9998L15.9998 6.6665"
          stroke="#67636C"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </G>
    </Svg>
  );
}
