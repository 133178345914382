import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { tokenSetTokenAction, tokenGetTokenAction } from "./action";
export const setTokenRequest = function* (
  action: ReturnType<typeof tokenSetTokenAction.request>
) {
  try {
    let response = yield call(() =>
      api.token.methods.setToken(...action.payload)
    );

    yield put(tokenSetTokenAction.success(response, action.meta));
  } catch (error) {
    yield put(tokenSetTokenAction.failure(error, action.meta));
  }
};

export const getTokenRequest = function* (
  action: ReturnType<typeof tokenGetTokenAction.request>
) {
  try {
    let response = yield call(() =>
      api.token.methods.getToken(...action.payload)
    );
    yield put(tokenGetTokenAction.success(response, action.meta));
  } catch (error) {
    yield put(tokenGetTokenAction.failure(error, action.meta));
  }
};

export const tokenSaga = function* () {
  yield all([
    takeEvery(tokenSetTokenAction.request, setTokenRequest),
    takeEvery(tokenGetTokenAction.request, getTokenRequest),
  ]);
};
