import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const bookingSearchSubscribeAction = createAsyncActionWithMeta(
  "bookingSearchSubscribe_REQUEST",
  "bookingSearchSubscribe_SUCCESS",
  "bookingSearchSubscribe_FAILURE"
)<{ meetingId: string }, any, Error>();

export const bookingSearchUnsubscribeAction = createAsyncActionWithMeta(
  "bookingSearchUnsubscribe_REQUEST",
  "bookingSearchUnsubscribe_SUCCESS",
  "bookingSearchUnsubscribe_FAILURE"
)<void, any, Error>();

export const bookingSearchAction = createAsyncActionWithMeta(
  "bookingSearch_REQUEST",
  "bookingSearch_SUCCESS",
  "bookingSearch_FAILURE"
)<void, any, Error>();

export const bookingSearchStartAction = createAsyncActionWithMeta(
  "bookingSearchStart_REQUEST",
  "bookingSearchStart_SUCCESS",
  "bookingSearchStart_FAILURE"
)<any, any, Error>();

export const bookingSearchEndAction = createAsyncActionWithMeta(
  "bookingSearchEnd_REQUEST",
  "bookingSearchEnd_SUCCESS",
  "bookingSearchEnd_FAILURE"
)<any, any, Error>();

export const bookingSearchNextPlacesAction = createAsyncActionWithMeta(
  "bookingSearchNextPlaces_REQUEST",
  "bookingSearchNextPlaces_SUCCESS",
  "bookingSearchNextPlaces_FAILURE"
)<any, any, Error>();
