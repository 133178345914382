import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  userCheckEmailAction,
  userDeleteMeAction,
  userForgotPasswordAction,
  userGetMeAction,
  userGetMyPaymentAction,
  userLoginAction,
  userLogoutAction,
  userRegisterAction,
  userResetPasswordAction,
  userSendConfirmEmailAction,
  userUpdateMeAction,
} from "./action";
import { tokenSetTokenAction } from "../token/action";

export const registerRequest = function* (
  action: ReturnType<typeof userRegisterAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.register(...action.payload)
    );
    yield put(tokenSetTokenAction.request([response.data.token], {}));

    if (
      (!response.data.user.email ||
        response.data.user.email.includes("@unconfirmed.disabled")) &&
      action.payload[0].profileToRegister.email
    ) {
      const updateBody = action.payload[0].profileToRegister;

      yield put(userUpdateMeAction.request([{ ...updateBody }], action.meta));
    }

    yield put(userLoginAction.success(response.data.user, action.meta));
  } catch (error) {
    yield put(userLoginAction.failure(error, action.meta));
  }
};

export const loginRequest = function* (
  action: ReturnType<typeof userLoginAction.request>
) {
  try {
    let response = yield call(() => api.user.methods.login(...action.payload));

    yield put(tokenSetTokenAction.request([response.data.jwt]));

    yield put(userLoginAction.success(response.data.user, action.meta));
  } catch (error) {
    yield put(userLoginAction.failure(error, action.meta));
  }
};

export const userSaga = function* () {
  yield all([
    takeEvery(userRegisterAction.request, registerRequest),
    takeEvery(userLoginAction.request, loginRequest),
    takeEvery(userLogoutAction.request, logoutRequest),
    takeEvery(userUpdateMeAction.request, updateMeRequest),
    takeEvery(userGetMeAction.request, getMeRequest),
    takeEvery(userDeleteMeAction.request, deleteMeRequest),
    takeEvery(userResetPasswordAction.request, userResetPasswordRequest),
    takeEvery(userForgotPasswordAction.request, forgotPasswordRequest),
    takeEvery(userSendConfirmEmailAction.request, sendConfirmEmailRequest),
    takeEvery(userGetMyPaymentAction.request, userGetMyPaymentRequest),
  ]);
  yield all([takeEvery(userCheckEmailAction.request, checkEmailRequest)]);
};

export const logoutRequest = function* (
  action: ReturnType<typeof userLogoutAction.request>
) {
  try {
    yield put(tokenSetTokenAction.request([null]));

    yield put(userLogoutAction.success(null, action.meta));
  } catch (error) {
    yield put(userLogoutAction.failure(error, action.meta));
  }
};

export const getMeRequest = function* (
  action: ReturnType<typeof userGetMeAction.request>
) {
  try {
    let response = yield call(() => api.user.methods.getMe(...action.payload));

    yield put(userGetMeAction.success(response.data, action.meta));
  } catch (error) {
    console.log("GET ME ERROR", error);

    yield put(userGetMeAction.failure(error, action.meta));
  }
};

export const updateMeRequest = function* (
  action: ReturnType<typeof userUpdateMeAction.request>
) {
  try {
    const formData = new FormData();
    const updateData = { ...action?.payload?.[0] };

    formData.append("user", JSON.stringify(updateData));

    let response = yield call(() => api.user.methods.updateMe(formData));
    yield put(userUpdateMeAction.success(response.data, action.meta));
  } catch (error) {
    yield put(userUpdateMeAction.failure(error, action.meta));
  }
};

export const deleteMeRequest = function* (
  action: ReturnType<typeof userDeleteMeAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.deleteMe(...action.payload)
    );
    yield put(tokenSetTokenAction.request([null]));
    yield put(userDeleteMeAction.success(response, action.meta));
  } catch (error) {
    yield put(userDeleteMeAction.failure(error, action.meta));
  }
};

export const forgotPasswordRequest = function* (
  action: ReturnType<typeof userForgotPasswordAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.forgotPassword(...action.payload)
    );

    yield put(userForgotPasswordAction.success(response.data, action.meta));
  } catch (error) {
    yield put(userForgotPasswordAction.failure(error, action.meta));
  }
};

export const sendConfirmEmailRequest = function* (
  action: ReturnType<typeof userSendConfirmEmailAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.sendConfirmEmail(...action.payload)
    );
    yield put(userSendConfirmEmailAction.success(response, action.meta));
  } catch (error) {
    yield put(userSendConfirmEmailAction.failure(error, action.meta));
  }
};

export const checkEmailRequest = function* (
  action: ReturnType<typeof userCheckEmailAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.checkEmail(...action.payload)
    );
    yield put(userCheckEmailAction.success(response, action.meta));
  } catch (error) {
    yield put(userCheckEmailAction.failure(error, action.meta));
  }
};

export const userResetPasswordRequest = function* (
  action: ReturnType<typeof userResetPasswordAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.resetPassword(...action.payload)
    );

    yield put(tokenSetTokenAction.request([response.data.jwt]));

    yield put(userResetPasswordAction.success(response.data, action.meta));
  } catch (error) {
    yield put(userResetPasswordAction.failure(error, action.meta));
  }
};

export const userGetMyPaymentRequest = function* (
  action: ReturnType<typeof userGetMyPaymentAction.request>
) {
  try {
    let response = yield call(() =>
      api.user.methods.getMyPayment(...action.payload)
    );

    yield put(userGetMyPaymentAction.success(response.data, action.meta));
  } catch (error) {
    yield put(userGetMyPaymentAction.failure(error, action.meta));
  }
};
