import api from '../storeApi';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {mapPlacesGetAction} from './action';

export const getRequest = function* (
  action: ReturnType<typeof mapPlacesGetAction.request>,
) {
  try {
    let response = yield call(() =>
      api.pinPlaces.methods.get(...action.payload),
    );

    yield put(mapPlacesGetAction.success(response.data, action.meta));
  } catch (error) {
    yield put(mapPlacesGetAction.failure(error, action.meta));
  }
};

export const mapPlacesSaga = function* () {
  yield all([takeEvery(mapPlacesGetAction.request, getRequest)]);
};
