import React from "react";
import { View, Text } from "react-native";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MascotSubscribeStarter } from "@gadder/common/src/assets/svg/gadder_moscot.svg";
import { CommonScreenCard } from "../components/CommonScreenCard";
import { fonts } from "@gadder/common/src/styles/fonts";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { useIsDesktop } from "@gadder/common/src/utils/useIsDesktop";
import { Header } from "../components/Header";

export const LandingSubscriptionPage = () => {
  const navigate = useNavigate();
  return (
    <CommonScreenCard>
      <Header
        onPressLeft={() => {
          navigate(-1);
        }}
      />
      <MascotSubscribeStarter style={{ marginBottom: 32, marginTop: 58 }} />
      <Text style={[fonts.title, { marginBottom: 48 }]}>
        Welcome to gadder!
      </Text>
      <View style={{ flexDirection: "column" }}>
        <CommonButton
          textStyle={fonts.mediumText_bold}
          title="Login"
          type="blackFilled"
          containerStyle={{ width: 170, marginBottom: 12 }}
          onPress={() => navigate("../phone")}
        />
        <CommonButton
          textStyle={fonts.mediumText_bold}
          title="Registration"
          type="black"
          containerStyle={{ width: 170 }}
          onPress={() => navigate("../phone")}
        />
      </View>
    </CommonScreenCard>
  );
};
