import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  FlatList,
  Text,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import { PROVIDER_GOOGLE, Region } from "react-native-maps";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import _ from "lodash";
import { mapPlacesGetAction } from "../store/mapPlaces/action";
import MapView from "../components/Map";
import { colors } from "@gadder/common/src/styles/colors";
import { ReactComponent as GadderLogo } from "@gadder/common/src/assets/svg/gadder_mascot_white_small.svg";
import { fonts } from "@gadder/common/src/styles/fonts";
import { ClusterPin, localeUrlHandler, MapPin } from "../components/MapPin";

import { PlaceCardSmall } from "../components/PlaceCardSmall";
import { useDistrict } from "@gadder/common/src/utils/use-district";
import { PlaceTag } from "@gadder/common/src/components/PlaceCard";
import { ReactComponent as ArrowBlack } from "@gadder/common/src/assets/svg/arrow_short_black.svg";
import { CommonInput } from "../components/inputs";

import { ReactComponent as FilterIcon } from "@gadder/common/src/assets/svg/filter.svg";
import { ReactComponent as FilterActiveIcon } from "@gadder/common/src/assets/svg/filter_active.svg";
import { mapSearchPlacesGetAction } from "../store/mapSearchPlaces/action";
import { useNavigate } from "react-router-dom";
import {
  defaultPlaceFilter,
  PlaceSearchFiltersType,
} from "@gadder/common/src/components/placeSearch/types/filters";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";
import { useDebounce } from "@gadder/common/src/utils/use-debounce";
import {
  FiltersPriceTranslates,
  FiltersTypeTranslates,
} from "@gadder/common/src/components/placeSearch/types/translates";

const defaultRegion: Region = {
  latitude: 51.518787,
  longitude: -0.196143,
  latitudeDelta: 0.015,
  longitudeDelta: 0.0121,
};

const round = (number, digits) => {
  return Math.round(number * Math.pow(10, digits)) / Math.pow(10, digits);
};

const getPointForGrid = (latPairsArr, lngPairsArr) => {
  return latPairsArr.flatMap((_latPoint) =>
    lngPairsArr.map((_lngPoint) => {
      return {
        latitude: _latPoint,
        longitude: _lngPoint,
      };
    })
  );
};

const filterImplementation = (_place, filters?) => {
  return !filters
    ? _place
    : (filters.placeFromSearch?.placesId &&
      filters.placeFromSearch.placesId.length > 0
        ? filters.placeFromSearch?.placesId.includes(_place.place_id)
        : true) &&
        (filters?.type.length > 0
          ? filters?.type.some((_type) =>
              _place?.types?.includes(FiltersTypeTranslates[_type])
            )
          : true) &&
        (filters?.price.length > 0
          ? filters?.price.some(
              (_price) => _place?.price === FiltersPriceTranslates[_price]
            )
          : true) &&
        (filters?.cuisine.length > 0
          ? filters?.cuisine.some((_cuisine) =>
              _place?.cuisine?.toLowerCase().includes(_cuisine.toLowerCase())
            )
          : true) &&
        (filters?.feature.length > 0
          ? filters?.feature.some((_feature) =>
              _place?.feature?.toLowerCase().includes(_feature.toLowerCase())
            )
          : true) &&
        (filters?.tag.length > 0
          ? filters?.tag.some((_tag) =>
              _place?.tag?.toLowerCase().includes(_tag.toLowerCase())
            )
          : true) &&
        (filters?.isFavorites ? _place.user_status === "favorite" : true);
};

const getPins = (places) => {
  const [recommendedPlaces, commonPlaces] = _.partition(
    places,
    (_place) => _place.is_recommended
  );

  return (currentRegion, filters) => {
    if (currentRegion && commonPlaces && commonPlaces.length !== 0) {
      const latitude = round(parseFloat(currentRegion.latitude), 10);
      const longitude = round(parseFloat(currentRegion.longitude), 10);
      const longitudeDeltaRaw = parseFloat(currentRegion.longitudeDelta);
      const latitudeDeltaRaw = parseFloat(currentRegion.latitudeDelta);

      const filteredRecommendedPlaces = recommendedPlaces.filter(
        (_place) =>
          filterImplementation(_place, filters) &&
          _place.latitude >= latitude - latitudeDeltaRaw &&
          _place.latitude < latitude + latitudeDeltaRaw &&
          _place.longitude >= longitude - longitudeDeltaRaw &&
          _place.longitude < longitude + longitudeDeltaRaw
      );

      let resultPins = [];

      const clusteringBorder = 0.01;

      if (
        longitudeDeltaRaw >= clusteringBorder ||
        latitudeDeltaRaw >= clusteringBorder
      ) {
        const longitudeDelta = round(
          longitudeDeltaRaw,
          longitudeDeltaRaw >= 0.01 ? 2 : 3
        );
        const latitudeDelta = round(
          latitudeDeltaRaw,
          latitudeDeltaRaw >= 0.01 ? 2 : 3
        );

        const cellLatCount = 2;
        const cellLngCount = 2;
        const cellOutScreen = 5;

        const cellLatLength = latitudeDelta / cellLatCount;
        const cellLngLength = latitudeDelta / cellLngCount;

        const gridLatStartPointKoef = Math.max(
          Math.floor((latitude - latitudeDelta / 2) / cellLatLength) -
            cellOutScreen +
            1,
          0
        );

        const latitudePointsArray = [
          ...Array(cellLatCount + 2 * cellOutScreen).keys(),
        ].map((it) => (gridLatStartPointKoef + it) * cellLatLength);

        const latitudePointPairs = latitudePointsArray
          .map((_point, index, array) =>
            index !== array.length - 1
              ? { firstPoint: _point, secondPoint: array[index + 1] }
              : undefined
          )
          .slice(0, latitudePointsArray.length - 1);

        const gridLngStartPointKoef =
          Math.floor((longitude - longitudeDelta / 4) / cellLngLength) -
          cellOutScreen;

        const longitudePointsArray = [
          ...Array(cellLngCount + 2 * cellOutScreen).keys(),
        ].map(
          (it) =>
            (gridLngStartPointKoef + it) * cellLngLength +
            (longitudeDelta > 1.5 ? 0.5 : 0)
        );

        const longitudePointPairs = longitudePointsArray
          .map((_point, index, array) =>
            index !== array.length - 1
              ? { firstPoint: _point, secondPoint: array[index + 1] }
              : undefined
          )
          .slice(0, longitudePointsArray.length - 1);

        const grid = [];

        for (const _cell of getPointForGrid(
          latitudePointPairs,
          longitudePointPairs
        )) {
          const placesInSector = commonPlaces.filter(
            (_place) =>
              filterImplementation(_place, filters) &&
              _place.latitude >= _cell.latitude.firstPoint &&
              _place.latitude < _cell.latitude.secondPoint &&
              _place.longitude >= _cell.longitude.firstPoint &&
              _place.longitude < _cell.longitude.secondPoint
          );

          const center =
            placesInSector && placesInSector.length > 1
              ? {
                  latitude: placesInSector[0].latitude,
                  longitude: placesInSector[0].longitude,
                }
              : null;

          const soloPlace =
            placesInSector.length === 1 ? placesInSector[0] : null;

          if (soloPlace || (center && center.latitude && center.longitude)) {
            grid.push({
              ..._cell,
              place: soloPlace,
              numberOfPlaces: placesInSector.length,
              places: placesInSector.slice(0, 5),
              placesIds: placesInSector.map((_place) => _place.place_id),
              center: center,
            });
          }
        }

        resultPins = [
          ...grid
            .filter((_point) => _point.numberOfPlaces !== 0)
            .map(
              (_point) =>
                _point.place || {
                  ..._point.center,
                  numberOfPlaces: _point.numberOfPlaces,
                  places: _point.places,
                  placesIds: _point.placesIds,
                  pinType: "cluster",
                  place_id:
                    _point.center.longitude.toString() +
                    _point.center.latitude.toString() +
                    _point.numberOfPlaces.toString(),
                }
            ),
          ...filteredRecommendedPlaces,
        ];
      } else {
        resultPins = places.filter(
          (_place) =>
            filterImplementation(_place, filters) &&
            _place.latitude >= latitude - latitudeDeltaRaw &&
            _place.latitude < latitude + latitudeDeltaRaw &&
            _place.longitude >= longitude - longitudeDeltaRaw &&
            _place.longitude < longitude + longitudeDeltaRaw
        );
      }

      return resultPins;
    } else return [];
  };
};

export const usePins = (places, currentRegion, filters?) => {
  const pinsCalculation = useMemo(() => getPins(places), [places]);

  return useMemo(
    () => pinsCalculation(currentRegion, filters),
    [currentRegion, filters, places]
  );
};

type ClusterType = {};

export const MapPageOld = () => {
  const [filters, setFilters] =
    useState<PlaceSearchFiltersType>(defaultPlaceFilter);

  const [currentRegion, setCurrentRegion] = useState<Region>(defaultRegion);

  const mapPlacesState = useSelector((state: RootState) => state.mapPlaces);

  const mapPlaces: FullPlaceType[] = useMemo(
    () => _.shuffle(mapPlacesState.data) || [],
    [mapPlacesState.data]
  );

  const [loadPinsToken, loadPins] = useUniqueDispatch(
    mapPlacesGetAction.request
  );

  const pins = usePins(mapPlaces, currentRegion, filters);

  useEffect(() => {
    loadPins([]);
  }, []);

  const [selectedPin, setSelectedPin] = useState<{
    place?: FullPlaceType;
    cluster?: ClusterType;
  } | null>(null);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [isBottomPlacesHidden, setIsBottomPlacesHidden] = useState(true);

  useEffect(() => {
    setIsBottomPlacesHidden(
      (!selectedPin?.cluster && currentRegion.longitudeDelta > 0.3) ||
        !!(selectedPin && selectedPin.place) ||
        isSearchOpen
    );
  }, [currentRegion.longitudeDelta, selectedPin, isSearchOpen]);

  const [currentDistrict] = useDistrict(
    currentRegion.latitude,
    currentRegion.longitude
  );

  const bottomPlaces = useMemo(
    () =>
      selectedPin?.cluster
        ? mapPlaces.filter((_place) =>
            selectedPin?.cluster.placesIds.find(
              (_id) => _id === _place.place_id
            )
          )
        : mapPlaces.filter(
            (_place) =>
              _place.district === currentDistrict &&
              filterImplementation(_place, filters)
          ),

    [mapPlaces, currentDistrict, filters, selectedPin]
  );

  const [position, setPosition] = useState({
    lat: defaultRegion.latitude,
    lng: defaultRegion.longitude,
  });

  const fitToCoordinates = (coordinated: any) => {
    setPosition({ lat: coordinated.latitude, lng: coordinated.longitude });
  };

  const [searchValue, setSearchValue] = useState("");
  const [fullResult, setFullResult] = useState(false);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const searchPlaceFilters = useMemo(
    () => ({
      searchValue,
      filter: filterMeilisearchImplementation(filters),
      fullResult,
    }),
    [searchValue, filters, fullResult]
  );

  useEffect(() => {
    setIsSearchOpen(searchValue !== "");
    setSelectedPin(null);
  }, [searchValue]);

  useEffect(() => {
    if (isSearchOpen) {
      setFullResult(false);
    }
  }, [isSearchOpen]);

  useEffect(() => {
    if (!searchValue) {
      setFilters((prevState) => ({ ...prevState, placeFromSearch: null }));
    }
  }, [searchValue]);

  const navigate = useNavigate();

  const placeRoute = (place_id: string) => {
    navigate("/place/" + place_id);
  };

  return (
    <View>
      <div
        style={{
          height: "calc(100vh)",
          width: "100%",
        }}
      >
        <View style={[styles.header]}>
          <View
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {(selectedPin || searchValue || filters.placeFromSearch) && (
              <TouchableOpacity
                onPress={() => {
                  if (selectedPin) {
                    setSelectedPin(null);
                  } else {
                    setSearchValue("");
                    setFilters((prevState) => ({
                      ...prevState,
                      placeFromSearch: null,
                    }));
                  }
                }}
                style={{ paddingRight: 16 }}
              >
                <ArrowBlack />
              </TouchableOpacity>
            )}

            <CommonInput
              style={{ flex: 1 }}
              onFocus={() => {
                if (selectedPin?.cluster) {
                  setSelectedPin(null);
                  // setSearchValue('');
                }
              }}
              textAlign={selectedPin ? "center" : "left"}
              value={
                selectedPin
                  ? selectedPin.place
                    ? selectedPin.place.name
                    : selectedPin.cluster?.numberOfPlaces + " places"
                  : searchValue
              }
              placeholder={"Search by name, cuisine, area or vibe..."}
              returnKeyType={"search"}
              onSubmitEditing={() => {
                setFullResult(true);
              }}
              onChangeText={setSearchValue}
              RightItem={
                <TouchableOpacity
                  onPress={() => {
                    setIsFilterOpen(true);
                  }}
                  style={{ marginRight: 12 }}
                >
                  {filters === null ||
                  (Object.keys(filters).filter(
                    (_filter) =>
                      !["placeFromSearch"].includes(_filter) &&
                      filters[_filter] &&
                      filters[_filter].length !== 0
                  ).length === 0 &&
                    !filters.isFavorites) ? (
                    <FilterIcon />
                  ) : (
                    <FilterActiveIcon />
                  )}
                </TouchableOpacity>
              }
            />
          </View>

          {
            <View
              style={[
                styles.bottomSearchLine,
                !isSearchOpen && {
                  borderBottomLeftRadius: 16,
                  borderBottomRightRadius: 16,
                },
              ]}
            />
          }
        </View>

        {isSearchOpen && (
          <SearchList
            setSelectedPlaceId={(places_id: string[]) =>
              setFilters((prevState) => ({
                ...prevState,
                placeFromSearch: { placesId: places_id },
              }))
            }
            onClose={() => setIsSearchOpen(false)}
            searchFilter={searchPlaceFilters}
            style={[
              {
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 53,
                zIndex: 1,
              },
            ]}
            onPlaceSelect={(place) => {
              fitToCoordinates({
                latitude: Number(place.latitude),
                longitude: Number(place.longitude),
              });

              setIsSearchOpen(false);
              setSelectedPin({ place });
            }}
          />
        )}

        <MapView
          onClick={() => setSelectedPin(null)}
          onRegionChangeComplete={(region) => {
            region && setCurrentRegion(region);
          }}
          position={position}
          initialRegion={defaultRegion}
        >
          {selectedPin?.place && (
            <div
              style={{ zIndex: 5 }}
              key={selectedPin?.place?.place_id}
              lat={selectedPin?.place?.latitude}
              lng={selectedPin?.place?.longitude}
            >
              {selectedPin?.place ? (
                <MapPin place={selectedPin?.place} active />
              ) : (
                <></>
              )}
            </div>
          )}

          {pins?.map(
            (it) =>
              it.place_id !== selectedPin?.place?.place_id && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    const currentPin = pins.find(
                      (_place) => _place.place_id === it.place_id
                    );

                    if (currentPin?.pinType === "cluster") {
                      if (
                        selectedPin?.cluster &&
                        selectedPin.cluster.place_id === it.place_id
                      ) {
                        setSelectedPin(null);
                      } else {
                        setSelectedPin({ cluster: currentPin });
                      }
                    } else {
                      if (
                        selectedPin?.place &&
                        selectedPin?.place.place_id === e.nativeEvent.id
                      ) {
                        setSelectedPin(null);
                      } else {
                        setSelectedPin({ place: currentPin });
                      }
                    }
                  }}
                  style={{
                    zIndex: 10,
                    cursor: "pointer",
                    backgroundColor: "red",
                  }}
                  lat={it.latitude}
                  key={it.place_id}
                  lng={it.longitude}
                >
                  {it.pinType === "cluster" ? (
                    <ClusterPin
                      active={
                        selectedPin?.cluster &&
                        selectedPin.cluster.place_id === it.place_id
                      }
                      places={it.places.filter(
                        (_place) =>
                          _place.place_id !== selectedPin?.place?.place_id
                      )}
                      count={
                        it.places.find(
                          (_place) =>
                            _place.place_id === selectedPin?.place?.place_id
                        )
                          ? it.numberOfPlaces - 1
                          : it.numberOfPlaces
                      }
                    />
                  ) : (
                    <MapPin place={it} />
                  )}
                </div>
              )
          )}
        </MapView>

        {!isSearchOpen && selectedPin?.place && (
          <View
            style={[{ position: "absolute", bottom: 0, left: 0, right: 0 }]}
          >
            <View style={[styles.placeContainer]}>
              <PlaceCardSmall
                onPress={() => placeRoute(selectedPin?.place?.place_id)}
                additionalText={
                  // location
                  //   ? calcCrowInMiles(selectedPin?.place, location.coords) +
                  //     " mile from you"
                  //   :
                  ""
                }
                mapMod
                // onGetDirectionPress={() =>
                //   getDirectionHandler(selectedPin.place)
                // }
                place={selectedPin.place}
              />
            </View>
          </View>
        )}

        {!isBottomPlacesHidden && (
          <PlaceMiniatureList
            places={bottomPlaces}
            onPress={(_place) => {
              fitToCoordinates({
                latitude: Number(_place.latitude),
                longitude: Number(_place.longitude),
                longitudeDelta: currentRegion.longitudeDelta,
                latitudeDelta: currentRegion.latitudeDelta,
              });
              setSelectedPin({ place: _place });
            }}
            style={{
              position: "absolute",
              width: "100%",
              bottom: 14,
            }}
          />
        )}
      </div>
    </View>
  );
};

export const SearchList = ({
  onPlaceSelect,
  style,
  searchFilter,
  onClose,
  setSelectedPlaceId,
  containerStyle,
}: {
  onPlaceSelect: (place: FullPlaceType) => void;
  style?: StyleProp<ViewStyle>;
  searchFilter?: { searchValue: string; filter: string[]; fullResult: boolean };
  onClose: () => void;
  setSelectedPlaceId: (string: []) => void;
  containerStyle?: StyleProp<ViewStyle>;
}) => {
  const searchPlacesState = useSelector(
    (state: RootState) => state.mapSearchPlaces
  );

  const [loadSearchPlacesToken, loadSearchPlaces] = useUniqueDispatch(
    mapSearchPlacesGetAction.request
  );

  const isPlacesLoading = isLoading(searchPlacesState, loadSearchPlacesToken);
  const isPlacesLoaded = isData(searchPlacesState, loadSearchPlacesToken);

  useEffect(() => {
    if (isPlacesLoaded && searchFilter?.fullResult) {
      setSelectedPlaceId(
        (isPlacesLoaded?.data || []).map((_place) => _place.place_id)
      );
      onClose();
    }
  }, [isPlacesLoaded]);

  const [loadNextSearchPlacesToken, loadNextSearchPlaces] = useUniqueDispatch(
    mapSearchPlacesGetAction.request
  );

  const isNextPlacesLoading = isLoading(
    searchPlacesState,
    loadNextSearchPlacesToken
  );

  useEffect(() => {
    searchThrottleHandle([searchFilter]);
  }, [searchFilter]);

  const searchThrottleHandle = useDebounce(
    (params: any) => loadSearchPlaces(params),
    800
  );

  const [isReachedEnd, setIsReachedEnd] = useState(false);

  useEffect(() => {
    setIsReachedEnd(false);
  }, [searchFilter?.searchValue]);

  const loadNextPlaces = useCallback(() => {
    if (
      searchPlacesState?.data?.data.length !==
      searchPlacesState?.data?.meta.estimatedTotalHits
    ) {
      loadNextSearchPlaces([
        {
          ...searchFilter,
          offset: searchPlacesState?.data?.meta.offset + 20,
        },
      ]);
    } else {
      if (!isReachedEnd) {
        setIsReachedEnd(true);
      }
    }
  }, [searchPlacesState, searchFilter, isReachedEnd]);

  return (
    <View style={[{ flex: 1, backgroundColor: colors.white }, style]}>
      {isPlacesLoading ? (
        <ActivityIndicator style={{ marginTop: 60 }} color={colors.black} />
      ) : searchPlacesState?.data?.data.length > 0 ? (
        <FlatList
          style={{ flex: 1, zIndex: 10 }}
          contentContainerStyle={containerStyle}
          renderItem={({ item }) =>
            item.name && (
              <PlaceCardSmall
                onPress={() => onPlaceSelect(item)}
                style={styles.placeCard}
                place={item}
              />
            )
          }
          ListFooterComponent={
            isNextPlacesLoading ? (
              <ActivityIndicator
                color={colors.black}
                style={{ marginVertical: 24 }}
              />
            ) : null
          }
          onEndReached={loadNextPlaces}
          data={searchPlacesState?.data.data}
        />
      ) : (
        <Text
          style={[
            fonts.mediumText_bold,
            {
              color: colors.black,
              textAlign: "center",
              paddingTop: 60,
            },
          ]}
        >
          No match
        </Text>
      )}
    </View>
  );
};

const PlaceMiniatureList = ({
  style,
  onPress,
  places,
}: {
  style?: StyleProp<ViewStyle>;
  onPress: (place: FullPlaceType) => void;
  places: FullPlaceType[];
}) => {
  return (
    <View style={style}>
      <FlatList
        ItemSeparatorComponent={() => <View style={{ width: 12 }} />}
        horizontal
        data={places || []}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 14 }}
        renderItem={({ item }) => (
          <PlaceMiniature
            key={item.place_id}
            onPress={() => onPress(item)}
            place={item}
          />
        )}
      />
    </View>
  );
};

const PlaceMiniature = ({
  place,
  style,
  onPress,
}: {
  place: FullPlaceType;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          width: 88,
          height: 128,
          borderRadius: 10,
          overflow: "hidden",
          backgroundColor: colors.black,
        },
        style,
      ]}
    >
      <ImageBackground
        style={{ flex: 1 }}
        source={{ uri: localeUrlHandler(place.picture_1) }}
      >
        <PlaceTag
          little
          style={{ paddingTop: 8, paddingLeft: 3 }}
          tag={place.tag}
        />

        <View style={{ flex: 1 }} />

        <Text
          numberOfLines={1}
          style={[
            {
              color: colors.LightGrayishYellow,
              backgroundColor: "rgba(37, 31, 44, 0.44)",
              padding: 4,
            },
            fonts.little_bold,
          ]}
        >
          {place.name}
        </Text>
      </ImageBackground>

      <GadderLogo
        style={{
          position: "absolute",
          left: 12,
          top: 12,
          zIndex: -1,
          transform: [{ scale: 0.6 }],
        }}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  header: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 10,
    paddingTop: 6,
    paddingHorizontal: 16,
    backgroundColor: colors.white,
  },
  textButton: {
    ...fonts.smallMediumText_regular,
  },
  button: {
    marginRight: 8,
  },
  placeCard: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: "#BEBCC0",
    borderBottomWidth: 1,
  },
  placeContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,

    elevation: 1,
    shadowOffset: {
      width: 0,
      height: -0.5,
    },
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 0,
  },
  filter: {
    flexDirection: "row",
    paddingVertical: 12,
    paddingLeft: 18,
    paddingBottom: 12,
    position: "absolute",
    bottom: -54,
    backgroundColor: colors.white,

    left: 0,
    right: 0,
    zIndex: 2,
  },
  recenterButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.LightGrayishYellow,
    position: "absolute",
    right: 16,
    bottom: 32,
    justifyContent: "center",
    alignItems: "center",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    shadowColor: "#000",
    elevation: 1,
  },
  bottomSearchLine: {
    height: 12,
    position: "absolute",
    bottom: -12,
    backgroundColor: colors.white,
    left: 0,
    right: 0,
    zIndex: 2,
  },
});
