import {
  View,
  Text,
  FlatList,
  Pressable,
  TextInput,
  ScrollView,
} from "react-native";
import { ModalWrapper } from "./LoginModal";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import _ from "lodash";
import { ReactComponent as CrossIcon } from "@gadder/common/src/assets/svg/cross_black.svg";
import { ReactComponent as GadderIcon } from "@gadder/common/src/assets/svg/gadder_mascot_black_big.svg";
import { useEffect, useState } from "react";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsMobile } from "./ChatPage";
import useUniqueDispatch, {
  useSelector,
  isData,
  isLoading,
} from "@gadder/common/src/utils/redux-utils";
import { bugReportCreateAction } from "../store/bugReport/action";
import { RootState } from "../store/root-reducer";

const options = [
  {
    id: 1,
    text: "The time slot I needed was not available",
  },
  {
    id: 2,
    text: "I was redirected to the wrong page",
  },
  {
    id: 3,
    text: "Time slots shown didn't match time slots in the service provider's website",
  },
  {
    id: 4,
    text: "Other (Please describe in comment)",
  },
];

export const BugReportModal = () => {
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<number[]>([]);

  const [comment, setComment] = useState("");

  useEffect(() => {
    const commentOptionId = 4;
    if (comment && !selectedOptionsIds.includes(commentOptionId)) {
      setSelectedOptionsIds((prev) => [...prev, commentOptionId]);
    }
  }, [comment]);

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [submitToken, submit] = useUniqueDispatch(
    bugReportCreateAction.request
  );

  const bugReportRequest = useSelector((store: RootState) => store.bugReport);
  const userData = useSelector((store: RootState) => store.user.data);

  const isSubmitting = isLoading(bugReportRequest, submitToken);
  const isSubmitted = isData(bugReportRequest, submitToken);

  const submitHandler = () => {
    submit([
      {
        data: {
          comment,
          user_id: userData?.id,
          issues: JSON.stringify(
            selectedOptionsIds.map(
              (_optionId) =>
                options.find((_option) => _option.id === _optionId)?.text
            )
          ),
        },
      },
    ]);
  };

  const closeHandler = () => navigate(location.state.previousLocation || "/");

  return (
    <ModalWrapper>
      <View
        style={{
          width: isMobile ? "100%" : 600,
          maxHeight: isMobile ? 800 : "unset",
          backgroundColor: colors.white,
          borderRadius: 16,
          alignSelf: isMobile ? "unset" : "center",
          alignItems: "center",

          shadowOpacity: 0.3,
          shadowRadius: 16,
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowColor: "#000000",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            paddingTop: 50,
            paddingBottom: 64,
            paddingHorizontal: 32,
          }}
        >
          <Pressable onPress={closeHandler} style={{ alignSelf: "flex-end" }}>
            <CrossIcon />
          </Pressable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 64,
            }}
          >
            <GadderIcon width={45} height={65} />
            <Text
              numberOfLines={1}
              style={{
                color: colors.black,
                ...fonts.giant_bold,
                marginLeft: 23,
              }}
            >
              Report an issue
            </Text>
          </View>

          {isSubmitted ? (
            <Text
              style={{
                textAlign: "center",
                color: colors.black,
                ...fonts.mediumLargeText_500,
                marginBottom: 64,
              }}
            >
              Thank You!{"\n\n"}Your feedback matters!
            </Text>
          ) : (
            <>
              <FlatList
                contentContainerStyle={{ gap: 12 }}
                data={options}
                renderItem={({ item, index }) => (
                  <Option
                    onSelect={() =>
                      setSelectedOptionsIds((prev) => _.xor(prev, [item.id]))
                    }
                    index={index}
                    isSelected={selectedOptionsIds.includes(item.id)}
                    label={item.text}
                    key={item.id}
                  />
                )}
              />
              <TextInput
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  color: colors.black,
                  ...fonts.mediumText_medium,
                  padding: 17,
                  borderRadius: 10,
                  borderColor: "#67636C",
                  borderWidth: 1,
                  height: 90,
                  outline: "none",
                  width: "100%",
                }}
                placeholderTextColor={colors.darkGray}
                multiline
                placeholder={"Comment:"}
                onChangeText={setComment}
                value={comment}
              />
            </>
          )}

          <CommonButton
            disabled={selectedOptionsIds.length === 0}
            containerStyle={{
              paddingHorizontal: 66,
              paddingVertical: 10,
              backgroundColor: colors.newBlue,
              borderWidth: 0,
              borderRadius: 28,
            }}
            onPress={isSubmitted ? closeHandler : submitHandler}
            isLoading={isSubmitting}
            textStyle={{ color: colors.white, ...fonts.mediumLargeText_bold }}
            title={isSubmitted ? "Close" : "Submit"}
          />
        </ScrollView>
      </View>
    </ModalWrapper>
  );
};

const Option = ({
  label,
  index,
  onSelect,
  isSelected,
}: {
  onSelect: () => void;
  label: string;
  index: number;
  isSelected: boolean;
}) => {
  return (
    <Pressable
      onPress={onSelect}
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 12,
        paddingVertical: 10,
        borderRadius: 10,
        borderWidth: 1,
        backgroundColor: isSelected ? "rgba(63, 154, 233, 0.2)" : "unset",
        borderColor: isSelected ? "#3F9AE9" : "#67636C",
      }}
    >
      <View
        style={{
          width: 30,
          height: 30,
          borderRadius: 30,
          borderWidth: isSelected ? 0 : 1,
          backgroundColor: isSelected ? "#3F9AE9" : "unset",
          borderColor: colors.black,
          justifyContent: "center",
          alignItems: "center",
          marginRight: 12,
        }}
      >
        <Text
          style={{
            color: isSelected ? colors.white : colors.black,
            ...fonts.mediumText_medium,
          }}
        >
          {index + 1}
        </Text>
      </View>
      <Text style={{ color: colors.black, ...fonts.mediumText_medium }}>
        {label}
      </Text>
    </Pressable>
  );
};
