import {
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
} from "react-native";

import { ReactComponent as IconLeftShortArrow } from "@gadder/common/src/assets/svg/arrow_short_black.svg";
import { ReactNode } from "react";

export function Header({
  containerStyle,
  rightButton,
  leftButton,
  onPressLeft,
  onPressRight,
}: {
  containerStyle?: StyleProp<ViewStyle>;
  rightButton?: ReactNode;
  leftButton?: ReactNode;
  onPressLeft?: () => void;
  onPressRight?: () => void;
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity onPress={onPressLeft}>
        {!!leftButton ? leftButton : <IconLeftShortArrow />}
      </TouchableOpacity>
      {!!rightButton && (
        <TouchableOpacity onPress={onPressRight}>
          {rightButton}
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
