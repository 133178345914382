import { mapPlacesGetAction } from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import { placeBookingDataGetBookingDataAction } from "../placeBookingData/action";
import { mapSearchPlacesClearAllBookingDataAction } from "../mapSearchPlaces/action";

export const mapPlacesReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction(
      [mapPlacesGetAction.success],
      (state, action) => action.payload
    )
    .handleAction(
      [placeBookingDataGetBookingDataAction.success],
      (state, action) =>
        state && state.length > 0
          ? state?.map((_place) =>
              _place.place_id === action.payload.place_id
                ? {
                    ..._place,
                    booking_dates: action.payload.booking_dates,
                    book_provider: action.payload.book_provider,
                  }
                : _place
            )
          : state
    )
    .handleAction([mapSearchPlacesClearAllBookingDataAction], (state) =>
      state && state?.length > 0
        ? state.map((_place) => ({
            ..._place,
            booking_dates: undefined,
          }))
        : state
    ),
  isLoading: createLoadingReducer(mapPlacesGetAction),
  lastOpId: createOperationReducer(mapPlacesGetAction),
  error: createErrorReducer(mapPlacesGetAction),
});
