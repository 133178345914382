import { tokenSetTokenAction, tokenGetTokenAction } from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";

export const tokenReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction(
      [tokenSetTokenAction.success],
      (state, action) => action.payload
    )
    .handleAction(
      [tokenGetTokenAction.success],
      (state, action) => action.payload
    ),
  isLoading: createLoadingReducer(tokenSetTokenAction, tokenGetTokenAction),
  lastOpId: createOperationReducer(tokenSetTokenAction, tokenGetTokenAction),
  error: createErrorReducer(tokenSetTokenAction, tokenGetTokenAction),
});
