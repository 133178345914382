import React from "react";
import { ReactReduxContextValue } from "react-redux/src/components/Context";
import { createDispatchHook, createSelectorHook } from "react-redux";
import createUseUniqueDispatch, {
  isData as remoteIsData,
  isError as remoteIsError,
  isLoading as remoteIsLoading,
} from "../../../../libraries/packages/redux-utils/src/request/useUniqueDispatch";

export const MobileContext = React.createContext<ReactReduxContextValue>(
  null as any
);

export const useDispatch = createDispatchHook(MobileContext);
const useUniqueDispatch = createUseUniqueDispatch(MobileContext);
export default useUniqueDispatch;
export const useSelector = createSelectorHook(MobileContext);
export const isData = remoteIsData;
export const isLoading = remoteIsLoading;
export const isError = remoteIsError;
