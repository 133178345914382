import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import { bugReportCreateAction } from "./action";

export const bugReportReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null).handleAction(
    [bugReportCreateAction.success],
    (state, action) => action.payload
  ),
  isLoading: createLoadingReducer(bugReportCreateAction),
  lastOpId: createOperationReducer(bugReportCreateAction),
  error: createErrorReducer(bugReportCreateAction),
});
