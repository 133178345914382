import { StyleSheet } from "react-native";

export const fonts = StyleSheet.create({
  tiny_bold: {
    fontSize: 8,
    lineHeight: 10,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  little_bold: {
    fontSize: 10,
    lineHeight: 14,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },

  little_regular: {
    fontSize: 10,
    lineHeight: 14,
    letterSpacing: -0.41,
    fontWeight: "400",
    fontFamily: "ManropeRegular",
  },
  little_medium: {
    fontSize: 10,
    lineHeight: 14,
    letterSpacing: -0.41,
    fontWeight: "500",
    fontFamily: "ManropeMedium",
  },
  smallText_regular: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "400",
    fontFamily: "ManropeRegular",
    letterSpacing: -0.41,
  },
  smallText_medium: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "500",
    fontFamily: "ManropeMedium",
    letterSpacing: -0.41,
  },
  smallText_semiBld: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "700",
    fontFamily: "ManropeSemiBold",
    letterSpacing: -0.41,
  },
  smallText_bold: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "700",
    fontFamily: "ManropeBold",
    letterSpacing: -0.41,
  },
  smallMediumText_regular: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.41,
    fontWeight: "400",
    fontFamily: "ManropeRegular",
  },
  smallMediumText_medium: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "500",
    letterSpacing: -0.41,
    fontFamily: "ManropeMedium",
  },
  smallMediumText_semiBold: {
    fontSize: 14,
    letterSpacing: -0.41,
    lineHeight: 20,
    fontWeight: "600",
    fontFamily: "ManropeSemiBold",
  },
  smallMediumText_bold: {
    fontSize: 14,
    letterSpacing: -0.41,
    lineHeight: 20,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  mediumText_regular: {
    fontSize: 16,
    letterSpacing: -0.41,
    lineHeight: 22,
    fontWeight: "400",
    fontFamily: "ManropeRegular",
  },
  mediumText_medium: {
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: -0.41,
    fontWeight: "500",
    fontFamily: "ManropeMedium",
  },
  mediumText_semiBold: {
    fontSize: 16,
    fontFamily: "ManropeSemiBold",
    letterSpacing: -0.41,
    fontWeight: "600",
  },
  mediumText_bold: {
    fontSize: 16,
    fontFamily: "ManropeBold",
    lineHeight: 22,
    letterSpacing: -0.41,
    fontWeight: "700",
  },
  mediumLargeText_regular: {
    fontSize: 20,
    lineHeight: 27,
    letterSpacing: -0.41,
    fontWeight: "400",
    fontFamily: "ManropeRegular",
  },
  mediumLargeText_500: {
    fontSize: 20,
    lineHeight: 27,
    letterSpacing: -0.41,
    fontWeight: "500",
    fontFamily: "ManropeMedium",
  },
  mediumLargeText_medium: {
    fontSize: 20,
    lineHeight: 27,
    letterSpacing: -0.41,
    fontWeight: "600",
    fontFamily: "ManropeRegular",
  },
  mediumLargeText_bold: {
    fontSize: 20,
    lineHeight: 27,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  mediumLargeText_semiBold: {
    fontSize: 20,
    lineHeight: 27,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeSemiBold",
  },
  averageLargeText_bold: {
    fontSize: 22,
    lineHeight: 30,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  largeText_bold: {
    fontSize: 24,
    lineHeight: 33,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  largeText_semiBold: {
    fontSize: 24,
    lineHeight: 33,
    letterSpacing: -0.41,
    fontWeight: "600",
    fontFamily: "ManropeSemiBold",
  },
  largeText_extraBold: {
    fontSize: 24,
    lineHeight: 33,
    letterSpacing: -0.41,
    fontWeight: "600",
    fontFamily: "ManropeExtraBold",
  },
  title: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  input: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },
  giant_bold: {
    fontSize: 32,
    lineHeight: 44,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeBold",
  },

  huge_normal: {
    fontSize: 48,
    lineHeight: 44,
    letterSpacing: -0.41,
    fontWeight: "600",
    fontFamily: "ManropeMedium",
  },

  huge_extraBold: {
    fontSize: 48,
    lineHeight: 44,
    letterSpacing: -0.41,
    fontWeight: "600",
    fontFamily: "ManropeExtraBold",
  },
  enormous_extraBold: {
    fontSize: 64,
    lineHeight: 64,
    letterSpacing: -0.41,
    fontWeight: "700",
    fontFamily: "ManropeExtraBold",
  },
});
