import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../../store/root-reducer";
import {
  chatbotListDeleteChatAction,
  chatbotListGetChatListAction,
  chatbotListPostChatAction,
  chatbotListUpdateChatAction,
} from "../../store/chatbotList/action";
import React, { useEffect } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import { ChatListItem } from "./chatListItem";
import { SideMenu, SideMenuTabs } from "./sideMenu";
import { useIsMobile } from "../../pages/ChatPage";

export const ChatListPanel = ({
  selectedChatId,
  setSelectedChatId,
}: {
  setSelectedChatId: (id: number) => void;
  selectedChatId: number | null;
}) => {
  const chatbotListRequest = useSelector(
    (store: RootState) => store.chatbotList
  );

  const [getChatListToken, getChatList] = useUniqueDispatch(
    chatbotListGetChatListAction.request
  );

  const isChatListLoading = isLoading(chatbotListRequest, getChatListToken);

  const [createChatToken, createChat] = useUniqueDispatch(
    chatbotListPostChatAction.request
  );

  const [deleteChatToken, deleteChat] = useUniqueDispatch(
    chatbotListDeleteChatAction.request
  );

  const [renameChatToken, renameChat] = useUniqueDispatch(
    chatbotListUpdateChatAction.request
  );

  useEffect(() => {
    if (
      chatbotListRequest.data &&
      chatbotListRequest.lastOpId === createChatToken
    ) {
      setSelectedChatId(
        chatbotListRequest.data[chatbotListRequest.data.length - 1].id
      );
    }
  }, [chatbotListRequest.lastOpId]);

  useEffect(() => {
    getChatList([]);
  }, []);

  useEffect(() => {
    if (chatbotListRequest.data) {
      if (chatbotListRequest.data.length > 0 && selectedChatId === null) {
        setSelectedChatId(
          chatbotListRequest.data?.sort(
            (a, b) =>
              new Date(b.lastMessagesDate) - new Date(a.lastMessagesDate)
          )[0].id
        );
      }
    }
  }, [chatbotListRequest.data]);

  const isChatsLoaded = isData(chatbotListRequest, getChatListToken);

  useEffect(() => {
    if (isChatsLoaded && isChatsLoaded.length === 0) {
      createChat([{}]);
    }
  }, [isChatsLoaded]);

  const createChatHandler = () => {
    createChat([{}]);
  };

  const deleteHandler = (id: number) => {
    deleteChat([id]);
  };

  const renameChatHandler = (id: number, name: string) => {
    renameChat([id, { name }]);
  };

  const isMobile = useIsMobile();

  return (
    <SideMenu selectedTab={SideMenuTabs.CHATS}>
      {isChatListLoading && !chatbotListRequest.data ? (
        <ActivityIndicator
          color={colors.black}
          style={{ marginTop: 24, flex: 1 }}
        />
      ) : (
        <ScrollView
          contentContainerStyle={{ paddingTop: 12 }}
          style={styles.container}
        >
          <TouchableOpacity onPress={createChatHandler}>
            <View style={styles.newChatButtonContainer}>
              <Text
                style={[
                  isMobile
                    ? fonts.smallMediumText_semiBold
                    : fonts.mediumText_semiBold,
                  { color: colors.black },
                ]}
              >
                New Chat
              </Text>
            </View>
          </TouchableOpacity>

          {chatbotListRequest.data
            ?.sort(
              (a, b) =>
                new Date(b.lastMessagesDate) - new Date(a.lastMessagesDate)
            )
            .map((item) => (
              <ChatListItem
                isSelected={item.id === selectedChatId}
                onSelect={setSelectedChatId}
                onSave={renameChatHandler}
                onDelete={deleteHandler}
                chat={item}
                isOne={chatbotListRequest.data.length === 1}
              />
            ))}
        </ScrollView>
      )}
    </SideMenu>
  );
};

const styles = StyleSheet.create({
  container: { paddingHorizontal: 16, flex: 1, overflow: "visible" },
  newChatButtonContainer: {
    width: "100%",
    backgroundColor: "#F7F6F6",
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 6,
    marginBottom: 12,
  },
});
