import React, { useMemo } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
  ScrollView,
  Pressable,
} from "react-native";
import { useIsMobile } from "../../pages/ChatPage";
import { colors } from "@gadder/common/src/styles/colors";
import { ReactComponent as CrossIcon } from "@gadder/common/src/assets/svg/cross_big_black.svg";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { fonts } from "@gadder/common/src/styles/fonts";
import {
  FilterCuisine,
  FilterFeature,
  PlaceSearchFiltersType,
} from "@gadder/common/src/components/placeSearch/types/filters";
import { CommonInput } from "../inputs";
import { DatePicker } from "../DatePicker";
import { InputSelect } from "../InputSelect";
import { OrangeButton } from "./MapWithPlaces";

export const isFiltersSelectedHandler = (filters: PlaceSearchFiltersType) =>
  filters.feature.length > 0 ||
  filters.cuisine.length > 0 ||
  (filters.bookingMode && !!filters.bookingFilters?.date);

export const FiltersMenu = ({
  onBack,
  onSearch,
  searchValue,
  onSearchValueChange,
  selectedFilters,
  onFilterChange,
}: {
  searchValue?: string;
  onSearchValueChange?: (text: string) => void;
  onBack?: () => void;
  onSearch?: () => void;
  selectedFilters: PlaceSearchFiltersType;
  onFilterChange: React.Dispatch<React.SetStateAction<PlaceSearchFiltersType>>;
}) => {
  const isMobile = useIsMobile();

  const isFiltersSelected = useMemo(
    () => isFiltersSelectedHandler(selectedFilters),
    [selectedFilters]
  );

  return (
    <View style={isMobile ? styles.mobileContainer : styles.container}>
      <View style={styles.wrapper}>
        <View style={[styles.header, isMobile && styles.header_mobile]}>
          <TouchableOpacity onPress={onBack}>
            <CrossIcon />
          </TouchableOpacity>
          <CommonButton
            containerStyle={styles.clearAll}
            disabled={!isFiltersSelected}
            textStyle={fonts.smallMediumText_bold}
            type={"whiteFilled"}
            onPress={() =>
              onFilterChange((prevState) => ({
                ...prevState,
                feature: [],
                cuisine: [],
              }))
            }
            title={"Clear all"}
          />
        </View>
        <ScrollView
          contentContainerStyle={{
            gap: 18,
            paddingBottom: isMobile ? 122 : 160,
          }}
          style={[
            styles.content,
            isMobile && { paddingHorizontal: 16, gap: 24, paddingVertical: 12 },
          ]}
        >
          <CommonInput
            value={searchValue}
            onChangeText={onSearchValueChange}
            textInputStyle={{ padding: 12 }}
            style={{ backgroundColor: colors.white, flex: 1, zIndex: -1 }}
            placeholder={"Search by name, cuisine, area or vibe..."}
            returnKeyType={"search"}
          />

          <View>
            <Text style={[styles.sectionTitle, isMobile && { marginRight: 0 }]}>
              Refine your search
            </Text>

            <View style={{ marginBottom: 8 }}>
              <View style={{ flexDirection: isMobile ? "column" : "row" }}>
                <View
                  style={[
                    styles.toggleContainer,
                    isMobile && {
                      marginBottom: 8,
                      alignSelf: "stretch",
                      marginRight: 0,
                    },
                  ]}
                >
                  <Pressable
                    onPress={() =>
                      onFilterChange((prevState) => ({
                        ...prevState,
                        bookingMode: !prevState.bookingMode,
                      }))
                    }
                    style={[
                      styles.bookableToggle,
                      selectedFilters.bookingMode &&
                        styles.bookableToggleActive,
                      isMobile && { width: "unset", flex: 1 },
                    ]}
                  >
                    Show availabilities
                  </Pressable>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <InputSelect
                    value={selectedFilters.bookingFilters?.personsCount}
                    onChange={(_count) =>
                      onFilterChange((prevState) => ({
                        ...prevState,
                        bookingFilters: {
                          ...prevState.bookingFilters,
                          personsCount: _count,
                        },
                      }))
                    }
                    style={{ marginRight: 8 }}
                  />

                  <DatePicker
                    style={{ flex: 1 }}
                    date={selectedFilters.bookingFilters?.date}
                    setDate={(_date) =>
                      onFilterChange((prevState) => ({
                        ...prevState,
                        bookingFilters: {
                          ...prevState.bookingFilters,
                          date: _date,
                        },
                      }))
                    }
                  />
                </View>
              </View>
            </View>
          </View>

          <View style={{ zIndex: -1 }}>
            <Text style={[styles.sectionTitle, isMobile && { marginRight: 0 }]}>
              Cuisine
            </Text>

            <View style={[styles.optionsContainer]}>
              {Object.keys(FilterCuisine).map((filter) => {
                const cuisine = FilterCuisine[filter];
                const active = selectedFilters.cuisine.find(
                  (_cuisine) => _cuisine === cuisine
                );

                return (
                  <CommonButton
                    key={cuisine}
                    onPress={() =>
                      onFilterChange((prevState) => ({
                        ...prevState,
                        cuisine: active
                          ? prevState.cuisine.filter(
                              (_cuisine) => _cuisine !== cuisine
                            )
                          : [...prevState.cuisine, cuisine],
                      }))
                    }
                    textStyle={[
                      styles.buttonLabel,
                      active && styles.buttonActiveLabel,
                    ]}
                    containerStyle={[
                      styles.button,
                      active && styles.buttonActive,
                    ]}
                    size={"medium"}
                    title={cuisine}
                  />
                );
              })}
            </View>
          </View>

          <View style={{ zIndex: -1 }}>
            <Text style={[styles.sectionTitle, isMobile && { marginRight: 0 }]}>
              Feature
            </Text>

            <View style={[styles.optionsContainer]}>
              {Object.keys(FilterFeature).map((filter) => {
                const feature = FilterFeature[filter];
                const active = selectedFilters.feature.find(
                  (_feature) => _feature === feature
                );

                return (
                  <CommonButton
                    key={feature}
                    onPress={() =>
                      onFilterChange((prevState) => ({
                        ...prevState,
                        feature: active
                          ? prevState.feature.filter(
                              (_feature) => _feature !== feature
                            )
                          : [...prevState.feature, feature],
                      }))
                    }
                    textStyle={[
                      styles.buttonLabel,
                      active && styles.buttonActiveLabel,
                    ]}
                    containerStyle={[
                      styles.button,
                      active && styles.buttonActive,
                    ]}
                    size={"medium"}
                    title={feature}
                  />
                );
              })}
            </View>
          </View>
        </ScrollView>
        <OrangeButton
          style={[styles.showAllButton, { bottom: isMobile ? 60 : 80 }]}
          title={"Show places"}
          onPress={() => {
            onBack && onBack();
            onSearch && onSearch();
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
  },
  mobileContainer: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: colors.gray81,
    position: "relative",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray81,
    paddingHorizontal: 32,
    paddingTop: 24,
    paddingBottom: 22,
  },
  header_mobile: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 12,
  },
  content: {
    flex: 1,
    backgroundColor: colors.gray_f9,
    paddingHorizontal: 32,
    paddingVertical: 24,
    position: "relative",
  },
  sectionTitle: {
    marginLeft: 8,
    marginBottom: 12,
    ...fonts.averageLargeText_bold,
    color: colors.black,
  },
  optionsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
  },

  button: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    borderColor: colors.black,
    borderWidth: 1,
  },
  buttonActive: {
    backgroundColor: colors.black,
  },
  buttonLabel: {
    ...fonts.smallMediumText_regular,
    color: colors.black,
  },
  buttonActiveLabel: {
    color: colors.white,
  },
  clearAll: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.black,
  },
  showAllButton: {
    position: "absolute",
    left: "50%",
    transform: [{ translateX: "-50%" }],
  },
  toggleContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: colors.gray81,
    borderRadius: 10,
    overflow: "hidden",
    marginRight: 8,
    alignSelf: "flex-start",
  },
  bookableToggle: {
    width: 145,
    paddingVertical: 6,
    backgroundColor: colors.white,
    color: colors.black,
    textAlign: "center",
    ...fonts.smallMediumText_regular,
  },
  bookableToggleActive: {
    backgroundColor: colors.black,
    color: colors.white,
    ...fonts.smallMediumText_bold,
  },
});
