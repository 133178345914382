import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { phoneAuthPostSmsAction } from "./action";
export const postSmsRequest = function* (
  action: ReturnType<typeof phoneAuthPostSmsAction.request>
) {
  try {
    let response = yield call(() =>
      api.phoneAuth.methods.postSms(...action.payload)
    );
    yield put(phoneAuthPostSmsAction.success(response.data, action.meta));
  } catch (error) {
    console.log(error);
    yield put(phoneAuthPostSmsAction.failure(error, action.meta));
  }
};

export const phoneAuthSaga = function* () {
  yield all([takeEvery(phoneAuthPostSmsAction.request, postSmsRequest)]);
};
