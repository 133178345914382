import React, { useEffect, useState } from "react";
import { Keyboard, Text } from "react-native";
import { useNavigate } from "react-router-dom";
import { InputField } from "../components/InputField";
import { Header } from "../components/Header";
import { CommonScreenCard } from "../components/CommonScreenCard";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import { userLogoutAction, userUpdateMeAction } from "../store/user/action";
import { fonts } from "@gadder/common/src/styles/fonts";
import { CommonButton } from "@gadder/common/src/components/buttons";
export const NamePage = () => {
  const navigate = useNavigate();

  const userData = useSelector((store: RootState) => store.user);

  const [name, setName] = useState(userData?.data.first_name || "");
  const [surname, setSurname] = useState(userData?.data.second_name || "");

  const [updateMeToken, updateMe] = useUniqueDispatch(
    userUpdateMeAction.request
  );

  const [logoutToken, logout] = useUniqueDispatch(userLogoutAction.request);

  const isUserLoading = isLoading(userData, updateMeToken);

  function onPressSave() {
    updateMe([{ first_name: name, second_name: surname }]);
  }
  const disableButton = !name || !surname;

  useEffect(() => {
    if (isData(userData, updateMeToken)) {
      Keyboard.dismiss();
      navigate("../");
    }
  }, [userData]);

  return (
    <CommonScreenCard>
      <Header onPressLeft={() => logout([{}])} />
      <Text
        style={[
          fonts.title,
          { marginBottom: 8, textAlign: "center", maxWidth: 340 },
        ]}
      >
        Welcome!
      </Text>
      <Text style={[fonts.mediumText_regular, { marginBottom: 51 }]}>
        Please enter your full name.
      </Text>
      <InputField
        placeholder="Name"
        value={name}
        onChangeText={(text) =>
          text.length < 30 && setName(text.replace(/\s/g, ""))
        }
        style={{ marginBottom: 8 }}
      />
      <InputField
        placeholder="Surname"
        value={surname}
        onChangeText={(text) =>
          text.length < 30 && setSurname(text.replace(/\s/g, ""))
        }
        style={{ marginBottom: 116 }}
      />
      <CommonButton
        textStyle={fonts.mediumText_bold}
        title="Save"
        type={disableButton ? "black" : "blackFilled"}
        onPress={() => onPressSave()}
        disabled={disableButton}
        isLoading={isUserLoading}
      />
    </CommonScreenCard>
  );
};
