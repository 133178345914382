import { placelistsGetAction } from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { paginationReducer } from "../../../../../libraries/packages/redux-utils/src/request/pagination/usePagination";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";

export const placelistsReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null).handleAction(
    [placelistsGetAction.success],
    paginationReducer((it) => it.id)
  ),
  isLoading: createLoadingReducer(placelistsGetAction),
  lastOpId: createOperationReducer(placelistsGetAction),
  error: createErrorReducer(placelistsGetAction),
});
