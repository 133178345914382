import {
  chatbotGetChatAction,
  chatbotHideErrorAction,
  chatbotPostMessageEventAction,
} from "./action";

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import api from "../storeApi";
import { chatbotListDeleteChatAction } from "../chatbotList/action";

export const chatbotReducer = combineReducers<{
  data: typeof api.chatbot.type;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction(
      [chatbotGetChatAction.success],
      (state, action) => action.payload
    )
    .handleAction([chatbotPostMessageEventAction.request], (state, action) =>
      state && state.id === action.payload[0].chat_id
        ? {
            ...state,
            isBotResponding: true,
            botError: null,
            messages: [
              ...(state.messages || []),
              {
                createdAt: new Date(),
                author: "user",
                text: action.payload[0].message_text,
                optimistic_uuid: action.payload[0].optimistic_uuid,
                id: action.payload[0].optimistic_uuid,
              },
            ],
          }
        : state
    )
    .handleAction([chatbotPostMessageEventAction.success], (state, action) => {
      console.log("action", action.payload[0]);

      return state && state.id === action.payload[0].chat_id
        ? {
            ...state,
            isBotResponding: !action.payload[0].is_finished,
            botError: action.payload[0].bot_error,
            messages: [
              ...(state.messages || []).filter(
                (_message) =>
                  (action.payload[0].optimistic_uuid
                    ? _message.optimistic_uuid !==
                      action.payload[0].optimistic_uuid
                    : true) &&
                  (action.payload[0].new_message
                    ? _message.id !== action.payload[0].new_message?.id
                    : true)
              ),
              ...(action.payload[0].new_message
                ? [action.payload[0].new_message]
                : []),
            ],
          }
        : state;
    })
    .handleAction([chatbotHideErrorAction], (state, action) => {
      return {
        ...state,
        botError: null,
      };
    })
    .handleAction([chatbotPostMessageEventAction.failure], (state, action) => {
      console.log("action.payload[0]", action.payload[0]);

      return state
        ? {
            ...state,
            isBotResponding: false,
            botError: action.payload[0],
          }
        : state;
    })
    .handleAction([chatbotListDeleteChatAction.request], (state, action) =>
      !state ? null : state.id === action.payload[0] ? null : state
    ),
  isLoading: createLoadingReducer(chatbotGetChatAction),
  lastOpId: createOperationReducer(chatbotGetChatAction),
  error: createErrorReducer(chatbotGetChatAction),
});
