import React from "react";
import { colors } from "@gadder/common/src/styles/colors";
import { Linking, Pressable, TouchableOpacity, View } from "react-native";
import { ReactComponent as BurgerIcon } from "@gadder/common/src/assets/svg/burger.svg";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { fonts } from "@gadder/common/src/styles/fonts";
import { ReactComponent as DownloadIcon } from "@gadder/common/src/assets/svg/download.svg";
import { ReactComponent as GadderLogo } from "@gadder/common/src/assets/svg/gadder_with_logo_black.svg";
import { ReactComponent as WhatsAppIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_3.svg";
import { useNavigate } from "react-router-dom";

export const MobileHeader = ({
  onBurgerPress,
}: {
  onBurgerPress?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <View
      style={{
        height: 54,
        borderBottomWidth: 1,
        borderBottomColor: colors.gray81,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: 32,
      }}
    >
      <View style={{ flex: 1 }}>
        <TouchableOpacity onPress={onBurgerPress}>
          <BurgerIcon width={28} height={28} />
        </TouchableOpacity>
      </View>

      <Pressable onPress={() => navigate("/", { replace: true })}>
        <GadderLogo width={90} />
      </Pressable>
    </View>
  );
};
