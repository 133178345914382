import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  Text,
  TextStyle,
} from "react-native";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { colors } from "@gadder/common/src/styles/colors";

import { format, max as maxDate } from "date-fns";
import { fonts } from "@gadder/common/src/styles/fonts";
import { TimeSelect } from "./TimeSelect";
import { useIsMobile } from "../pages/ChatPage";

export const getBookingMinDate = (_date: Date) => {
  const currentDate = new Date(_date);
  const newDate = new Date(_date);

  const hours = currentDate.getHours();

  if (hours >= 22) {
    newDate.setDate(currentDate.getDate() + 1);
    newDate.setHours(19, 0, 0, 0);
  } else if (hours >= 19) {
    newDate.setHours(currentDate.getHours() + 1, 0, 0, 0);
  } else {
    newDate.setHours(19, 0, 0, 0);
  }
  return newDate;
};

export const DatePicker = ({
  date,
  setDate,
  style,
  disabled = false,
  isActive = true,
  calendarStyle,
  timeStyle,
  dateButtonStyle,
  dateButtonTextStyle,
  timeButtonTextStyle,
  menuStyle,
}: {
  style?: StyleProp<ViewStyle>;
  date: Date;
  setDate?: (date: Date) => void;
  disabled?: boolean;
  isActive?: boolean;
  calendarStyle?: StyleProp<ViewStyle>;
  timeStyle?: StyleProp<ViewStyle>;
  dateButtonStyle?: StyleProp<ViewStyle>;
  menuStyle?: StyleProp<ViewStyle>;
  dateButtonTextStyle?: StyleProp<TextStyle>;
  timeButtonTextStyle?: StyleProp<TextStyle>;
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const calendarRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        isCalendarOpen &&
        calendarRef.current &&
        !calendarRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
      }
    },
    [isCalendarOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const isMobile = useIsMobile();

  return (
    <View style={[{ position: "relative", flexDirection: "row" }, style]}>
      <View style={{ flex: 1 }}>
        {isCalendarOpen && (
          <View
            ref={calendarRef}
            style={[
              {
                position: "absolute",
                top: "calc(100% + 8px)",
                right: isMobile ? "-60px" : "0",
                zIndex: 10,
              },
              calendarStyle,
            ]}
          >
            <Calendar
              minDate={new Date()}
              onChange={(_date) => {
                const newDate = new Date(_date);

                const isToday =
                  new Date().setHours(0, 0, 0, 0) ===
                  new Date(_date).setHours(0, 0, 0, 0);

                newDate.setHours(
                  isToday
                    ? Math.max(new Date().getHours(), date.getHours())
                    : date.getHours(),
                  date.getMinutes(),
                  date.getSeconds(),
                  date.getMilliseconds()
                );
                setDate(newDate);
                setIsCalendarOpen(false);
              }}
              value={date}
            />
          </View>
        )}

        <Pressable
          disabled={disabled || !isActive}
          style={[
            styles.commonContainer,
            { marginRight: 8 },
            !isActive && styles.disableStyle,
            dateButtonStyle,
          ]}
          onPress={() => setIsCalendarOpen(true)}
        >
          <Text
            numberOfLines={1}
            style={[
              {
                color: isActive ? colors.black : colors.gray50,
                ...fonts.smallMediumText_regular,
              },
              dateButtonTextStyle,
            ]}
          >
            {format(date, "d'.'LL E")}
          </Text>
        </Pressable>
      </View>

      <TimeSelect
        isActive={isActive}
        disabled={disabled}
        menuStyle={menuStyle}
        style={[styles.commonContainer, !isActive && styles.disableStyle]}
        date={date}
        setDate={setDate}
        containerStyle={timeStyle}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  commonContainer: {
    backgroundColor: colors.white,
    paddingVertical: 7,
    paddingHorizontal: 12,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.gray81,
    textAlign: "center",
  },
  input: {
    width: 52,
    color: colors.black,
    textAlign: "center",
    ...fonts.smallMediumText_regular,
    outlineStyle: "none",
  },
  disableStyle: {
    backgroundColor: colors.gray90,
  },
});
