import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { LandingSubscriptionPage } from "./LandingSubscriptionPage";
import { PhonePage } from "./PhonePage";
import { PlacePage } from "./PlacePage";
import { CodePage } from "./CodePage";
import { NamePage } from "./NamePage";
import { ProfilePage } from "./ProfilePage";
import { Provider as ReduxProvider } from "react-redux";
import store from "../store";

import useUniqueDispatch, {
  isLoading,
  MobileContext,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";

import { RootState } from "../store/root-reducer";
import { createContext, useContext, useEffect, useState } from "react";
import { userGetMeAction } from "../store/user/action";
import { ActivityIndicator, Dimensions, View } from "react-native";
import { SendEmailPage } from "./SendEmailPage";
import { ChatPage } from "./ChatPage";
import { MapPageOld } from "./MapPageOld";
import { colors } from "@gadder/common/src/styles/colors";
import { LoginModal } from "./LoginModal";
import { MapPage } from "./MapPage";
import { MainPage } from "./MainPage";
import { BugReportModal } from "./BugReportModal";

export const CommonContext = createContext<{
  redirectAfterLoginTo: string | null;
  setRedirectAfterLoginTo: (v: string | null) => void;
}>({
  redirectAfterLoginTo: null,
  setRedirectAfterLoginTo: () => {},
});

const Navigation = () => {
  const token = useSelector((store: RootState) => store.token.data);

  const userRequest = useSelector((store: RootState) => store.user);

  const [getMeToken, getMe] = useUniqueDispatch(userGetMeAction.request);

  const isUserLoading = isLoading(userRequest, getMeToken);

  useEffect(() => {
    if (token && !userRequest.data) {
      getMe([]);
    }
  }, [token]);

  const [redirectAfterLoginTo, setRedirectAfterLoginTo] = useState<
    string | null
  >(null);

  return (
    <CommonContext.Provider
      value={{ redirectAfterLoginTo, setRedirectAfterLoginTo }}
    >
      <View style={{ height: Dimensions.get("window").height }}>
        {isUserLoading ? (
          <ActivityIndicator color={colors.black} style={{ flex: 1 }} />
        ) : userRequest.data?.id ? (
          <MainNavigation />
        ) : (
          <AuthNavigation />
        )}
      </View>
    </CommonContext.Provider>
  );
};

export const AuthContext = createContext({
  password: "",
  email: "",
  phone: "",
  setPhone: (v: string) => {},
  setEmail: (v: string) => {},
  setPassword: (v: string) => {},
});

const AuthNavigation = () => {
  console.log("AuthNavigation");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  return (
    <AuthContext.Provider
      value={{
        phone,
        setPhone,
        email,
        password,
        setEmail,
        setPassword,
      }}
    >
      <Routes location={previousLocation || location}>
        <Route path={"/"} element={<MainPage />} />

        <Route path={"/map"} element={<MapPage />} />

        <Route path={"/login"} element={<LandingSubscriptionPage />} />
        <Route path={"/phone"} element={<PhonePage />} />

        <Route path={"/code"} element={<CodePage />} />

        <Route path={"/place/:placeId"} element={<PlacePage fromChat />} />

        {/*<Route path={"/placelists/:id?"} element={<PlacelistaPage />} />*/}

        <Route path={"/bot"} element={<LoginModal />} />
        <Route path={"/profile"} element={<LandingSubscriptionPage />} />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>

      <Routes>
        {previousLocation && (
          <>
            <Route path="/bug-report" element={<BugReportModal />} />
            <Route path={"/login-modal"} element={<LoginModal />} />
          </>
        )}
      </Routes>
    </AuthContext.Provider>
  );
};

export const ChatContext = createContext<{
  lastOpenedChatId: number | null;
  setLastOpenedChatId: (val: number | null) => void;
}>({
  lastOpenedChatId: null,
  setLastOpenedChatId: () => {},
});

const MainNavigation = () => {
  const user = useSelector((store: RootState) => store.user.data);

  const navigate = useNavigate();

  useEffect(() => {
    // if (!user.confirmed) {
    //   navigate("/confirm");
    //   return;
    // }
    if (!user.first_name || !user.second_name) {
      navigate("/name");
      return;
    }
  }, [user]);

  const { redirectAfterLoginTo, setRedirectAfterLoginTo } =
    useContext(CommonContext);

  useEffect(() => {
    if (redirectAfterLoginTo) {
      navigate(redirectAfterLoginTo);
      setRedirectAfterLoginTo(null);
    }
  }, [redirectAfterLoginTo]);

  const [lastOpenedChatId, setLastOpenedChatId] = useState<number | null>(null);

  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <ChatContext.Provider value={{ lastOpenedChatId, setLastOpenedChatId }}>
      <Routes location={previousLocation || location}>
        <Route path={"/"} element={<MainPage />} />
        <Route path={"/map"} element={<MapPage />} />

        <Route path={"/profile"} element={<ProfilePage />} />
        <Route path={"/name"} element={<NamePage />} />
        {/*<Route path={"/placelists/:id?"} element={<PlacelistaPage />} />*/}
        <Route path={"/bot"} element={<ChatPage />} />
        <Route path={"/place/:placeId"} element={<PlacePage />} />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Routes>
        {previousLocation && (
          <Route path="/bug-report" element={<BugReportModal />} />
        )}
      </Routes>
    </ChatContext.Provider>
  );
};

export const SubscriptionWrapper = () => {
  return (
    <ReduxProvider context={MobileContext} store={store}>
      <Navigation />
    </ReduxProvider>
  );
};
