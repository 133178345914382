import React, { ReactNode, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  Image,
  FlatList,
  ActivityIndicator,
  Pressable,
} from "react-native";

import { ReactComponent as MapIcon } from "@gadder/common/src/assets/svg/pin.svg";
import { ReactComponent as MapIconLight } from "@gadder/common/src/assets/svg/map_light.svg";
import { ReactComponent as ArrowBlack } from "@gadder/common/src/assets/svg/arrow_short_black.svg";
import { ReactComponent as DefaultPhoto } from "@gadder/common/src/assets/svg/gadder_icon.svg";

import { localeUrlHandler } from "./MapPin";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { CommonButton } from "@gadder/common/src/components/buttons";
import {
  getPlaceTags,
  LikedFollowers,
  TagsWithDivider,
} from "@gadder/common/src/components/PlaceCard";
import LinearGradient from "react-native-web-linear-gradient";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { placeBookingDataGetBookingDataAction } from "../store/placeBookingData/action";
import { RootState } from "../store/root-reducer";
import { format } from "date-fns";

export const PlaceCardSmall = ({
  light = false,
  place,
  style,
  onPress,
  RightIcon,
  mapMod = false,
  additionalText = "",
  onGetDirectionPress,
  bookingDataParams,
}: {
  light?: boolean;
  place?: FullPlaceType;
  style?: StyleProp<ViewStyle>;
  RightIcon?: ReactNode | React.Component;
  onPress?: () => void;
  mapMod?: boolean;
  additionalText?: string;
  onGetDirectionPress?: () => void;
  bookingDataParams?: { date: Date; personsCount: string };
}) => {
  const [getBookingDataToken, getBookingData, reset] = useUniqueDispatch(
    placeBookingDataGetBookingDataAction.request
  );

  const bookingDataRequest = useSelector(
    (store: RootState) => store.bookingPlaceData
  );

  const iBookingDatasLoading = isLoading(
    bookingDataRequest,
    getBookingDataToken
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [place.place_id]);

  const getBookingDataHandler = () => {
    setBookingSearchPressed(true);
    getBookingData([
      {
        params: {
          id: place.place_id,
          date: format(bookingDataParams.date, "Y-MM-dd"),
          time: format(bookingDataParams.date, "HH:mm"),
          personsCount: bookingDataParams.personsCount,
        },
      },
    ]);
  };

  const [bookingSearchPressed, setBookingSearchPressed] = useState(false);

  useEffect(() => {
    setBookingSearchPressed(false);
  }, [place.place_id]);

  return (
    <Pressable disabled={!onPress} style={style} onPress={onPress}>
      <View style={[styles.container, mapMod && { marginBottom: 12 }]}>
        <>
          <View
            style={[{ flexDirection: "row", alignItems: "center", flex: 1 }]}
          >
            {place ? (
              place.picture_1 && (
                <Image
                  style={styles.image}
                  source={{ uri: localeUrlHandler(place.picture_1) }}
                />
              )
            ) : (
              <DefaultPhoto width={82} height={82} />
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                flex: 1,
              }}
            >
              <View
                style={[
                  styles.placeInfo,
                  { justifyContent: place ? "space-between" : "space-evenly" },
                ]}
              >
                <Text
                  numberOfLines={1}
                  style={[
                    fonts.mediumLargeText_regular,
                    { fontWeight: "700", color: colors.black, marginBottom: 8 },
                    light && { color: colors.white },
                  ]}
                >
                  {place ? place.name : "Gaddering..."}
                </Text>
                {place && (
                  <CommonButton
                    LeftIcon={
                      light ? (
                        <MapIconLight
                          height={13}
                          width={11}
                          style={{ marginRight: 5 }}
                        />
                      ) : (
                        <MapIcon
                          height={13}
                          width={11}
                          style={{ marginRight: 5 }}
                        />
                      )
                    }
                    textStyle={{ flex: 1 }}
                    style={{
                      marginBottom: 12,
                      alignSelf: "flex-start",
                    }}
                    containerStyle={{ maxWidth: 200 }}
                    title={place.address}
                    type={light ? "white" : "black"}
                    size={"small"}
                  />
                )}

                {mapMod || additionalText ? (
                  <Text
                    numberOfLines={1}
                    style={[
                      fonts.smallText_regular,
                      { color: colors.black },
                      light && { color: colors.white },
                    ]}
                  >
                    {additionalText}
                  </Text>
                ) : (
                  place && (
                    <TagsWithDivider
                      tags={getPlaceTags(place)}
                      textStyle={[
                        { color: colors.black, fontWeight: "400" },
                        light && { color: colors.white },
                      ]}
                      dividerStyle={[
                        { backgroundColor: colors.black },
                        light && { backgroundColor: colors.white },
                      ]}
                    />
                  )
                )}

                <View style={{ height: 30 }}>
                  {bookingDataParams && !place.booking_dates && (
                    <View
                      style={{
                        alignItems: "flex-start",
                        marginTop: 2,
                      }}
                    >
                      {iBookingDatasLoading && bookingSearchPressed ? (
                        <ActivityIndicator style={{}} color={colors.black} />
                      ) : (
                        <CommonButton
                          onPress={getBookingDataHandler}
                          type={"black"}
                          size={"small"}
                          title={"Check booking time"}
                        />
                      )}
                    </View>
                  )}

                  {place.book_provider && place.booking_dates && (
                    <View>
                      {place.book_provider !== "none" &&
                      place.booking_dates.length > 0 ? (
                        <View
                          style={{
                            position: "relative",
                            flex: 1,
                            marginRight: 12,
                          }}
                        >
                          <LinearGradient
                            useAngle
                            angle={90}
                            locations={[0.1, 1]}
                            colors={[
                              "rgb(255,255,255,1)",
                              "rgba(255,255,255,0)",
                            ]}
                            style={{
                              height: "100%",
                              width: 16,
                              position: "absolute",
                              zIndex: 1,
                            }}
                          />
                          <FlatList
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            data={place.booking_dates}
                            contentContainerStyle={{
                              gap: 4,
                              paddingHorizontal: 12,
                            }}
                            renderItem={({ item }) => (
                              <TouchableOpacity
                                onPress={() => {
                                  window.open(item.book_url, "_blank");
                                }}
                                style={styles.bookingTime}
                              >
                                <Text style={styles.bookingTimeLabel}>
                                  {item}
                                </Text>
                              </TouchableOpacity>
                            )}
                          />
                          <LinearGradient
                            useAngle
                            angle={90}
                            locations={[0, 0.9]}
                            colors={[
                              "rgb(255,255,255,0)",
                              "rgb(255,255,255,1)",
                            ]}
                            style={{
                              height: "100%",
                              width: 16,
                              position: "absolute",
                              right: 0,
                              zIndex: 1,
                            }}
                          />
                        </View>
                      ) : (
                        <Text
                          style={{
                            paddingHorizontal: 12,
                            color: colors.black,
                            ...fonts.mediumText_semiBold,
                          }}
                        >
                          No booking available
                        </Text>
                      )}
                    </View>
                  )}
                </View>
              </View>

              {mapMod && (
                <ArrowBlack style={{ transform: "rotateZ(180deg)" }} />
              )}
            </View>
          </View>

          {RightIcon}
        </>
      </View>

      {mapMod && place && (
        <>
          <LikedFollowers
            hideArrow={mapMod}
            textStyle={{ color: colors.black }}
            style={{ marginBottom: 12 }}
            userList={place.also_liked}
          />

          <View style={styles.container}>
            <TagsWithDivider
              textStyle={{ color: colors.black }}
              dividerStyle={{ backgroundColor: colors.black }}
              style={{ flex: 1 }}
              tags={getPlaceTags(place)}
            />

            {onGetDirectionPress && (
              <CommonButton
                onPress={onGetDirectionPress}
                title={"Get Directions"}
                type={"black"}
                size={"small"}
              />
            )}
          </View>
        </>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    justifyContent: "center",
    alignItems: "center",
    width: 82,
    height: 82,
    borderRadius: 41,
  },
  placeInfo: {
    paddingLeft: 8,
    flex: 1,
  },
  bookingTime: {
    padding: 8,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: colors.gray81,
  },
  bookingTimeLabel: {
    color: colors.black,
    ...fonts.smallText_semiBld,
  },
});
