import {
  mapSearchPlacesClearAllBookingDataAction,
  mapSearchPlacesGetAction,
} from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import { paginationReducer } from "../../../../../libraries/packages/redux-utils/src/request/pagination/usePagination";
import { placeBookingDataGetBookingDataAction } from "../placeBookingData/action";
export const mapSearchPlacesReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction(
      [mapSearchPlacesGetAction.success],
      paginationReducer((it) => it.place_id, {
        listSelector: (data) => data.data,
        metaSelector: (data) => data.meta,
        offsetField: "offset",
      })
    )
    .handleAction(
      [placeBookingDataGetBookingDataAction.success],
      (state, action) => {
        const newPlaces = state.data.map((_place) =>
          _place.place_id === action.payload.place_id
            ? {
                ..._place,
                booking_dates: action.payload.booking_dates,
                book_provider: action.payload.book_provider,
              }
            : _place
        );
        return state?.data && state.data?.length > 0
          ? {
              ...state,
              data: newPlaces,
            }
          : state;
      }
    )
    .handleAction([mapSearchPlacesClearAllBookingDataAction], (state) => {
      return state && state.data?.length > 0
        ? {
            ...state,
            data: state.data.map((_place) => ({
              ..._place,
              booking_dates: undefined,
            })),
          }
        : state;
    }),
  isLoading: createLoadingReducer(mapSearchPlacesGetAction),
  lastOpId: createOperationReducer(mapSearchPlacesGetAction),
  error: createErrorReducer(mapSearchPlacesGetAction),
});
