import React from "react";
import {
  StyleProp,
  ViewStyle,
  View,
  StyleSheet,
  ViewProps,
} from "react-native";
import {
  useIsDesktop,
  WINDOW_HEIGHT,
} from "@gadder/common/src/utils/useIsDesktop";
import { useIsMobile } from "../pages/ChatPage";

export function CommonScreenCard({
  screenStyle,
  style,
  ...props
}: {
  screenStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
} & ViewProps) {
  const isMobile: boolean = useIsMobile();
  return (
    <View
      style={[styles.screen, !isMobile && styles.screen_desktop, screenStyle]}
    >
      <View
        style={[
          styles.card,
          !isMobile && {
            ...styles.card_desktop,
            boxShadow: "0px 4px 12px 0px rgba(92, 107, 140, 0.30)",
          },
          style,
        ]}
        {...props}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: WINDOW_HEIGHT,
  },
  screen_desktop: {
    justifyContent: "center",
  },
  card: {
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxHeight: 610,
    borderRadius: 16,
  },
  card_desktop: {
    maxWidth: 460,
    height: "100%",
  },
});
