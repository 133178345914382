import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { bugReportCreateAction } from "./action";

export const createRequest = function* (
  action: ReturnType<typeof bugReportCreateAction.request>
) {
  try {
    let response = yield call(() =>
      api.bugReport.methods.create(...action.payload)
    );
    yield put(bugReportCreateAction.success(response.data, action.meta));
  } catch (error) {
    yield put(bugReportCreateAction.failure(error, action.meta));
  }
};

export const bugReportSaga = function* () {
  yield all([takeEvery(bugReportCreateAction.request, createRequest)]);
};
