import createSagaMiddleware from "redux-saga";
import { compose, applyMiddleware, createStore } from "redux";

import rootReducer from "./root-reducer";
import rootSaga from "./saga";
import { tokenGetTokenAction, tokenSetTokenAction } from "./token/action";
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const enchancer = compose(applyMiddleware(...middlewares));

const initialState = {};
const store = createStore(rootReducer, initialState, enchancer);
sagaMiddleware.run(rootSaga);

store.dispatch(tokenGetTokenAction.request([], "init"));

export default store;
