import React, { ReactNode } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { fonts } from "@gadder/common/src/styles/fonts";
import { ReactComponent as PlacelistWhiteIcon } from "@gadder/common/src/assets/svg/placelist_big_light.svg";
import { ReactComponent as PlacelistBlackIcon } from "@gadder/common/src/assets/svg/placelist_big_black.svg";
import { colors } from "@gadder/common/src/styles/colors";
import { useIsMobile } from "../pages/ChatPage";

export const TabButton = ({
  onPress,
  isSelected,
  Icon,
  ActiveIcon,
  label,
  textStyle,
  containerStyle,
}: {
  onPress?: () => void;
  isSelected?: boolean;
  Icon?: ReactNode;
  ActiveIcon?: ReactNode;
  label?: string;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}) => {
  const isMobile = useIsMobile();

  const iconWidth = isMobile ? 16 : 24;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.tabButton,
        {
          paddingHorizontal: isMobile ? 6 : 8,
          paddingVertical: isMobile ? 4 : 6,
        },
        isSelected && styles.tabButtonActive,
        containerStyle,
      ]}
    >
      <View style={{ marginRight: 4 }}>
        {isSelected ? (
          <ActiveIcon width={iconWidth} />
        ) : (
          <Icon width={iconWidth} />
        )}
      </View>
      <Text
        style={[
          isMobile
            ? fonts.smallMediumText_medium
            : fonts.smallMediumText_semiBold,
          isSelected && styles.tabButtonActiveText,
          textStyle,
        ]}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  tabButton: {
    color: colors.black,

    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#BEBCC0",
    flexDirection: "row",
    alignItems: "center",
  },
  tabButtonActive: {
    borderWidth: 0,
    backgroundColor: colors.black,
  },
  tabButtonActiveText: {
    color: colors.white,
  },
});
