import { StyleSheet, View, Text, TextInputProps } from "react-native";

import { InputField } from "./InputField";
import { useEffect, useMemo, useState } from "react";
import { useIsDesktop } from "@gadder/common/src/utils/useIsDesktop";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import { userUpdateMeAction } from "../store/user/action";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import { CommonButton } from "@gadder/common/src/components/buttons";

function EditFieldContainer({
  title,
  length,
  ...inputPrors
}: {
  title: string;
  length?: number;
} & TextInputProps) {
  const isDesktop: boolean = useIsDesktop();
  return (
    <View
      style={{
        flexDirection: "column",
        marginBottom: isDesktop ? 16 : 8,
        maxWidth: 343,
        width: "100%",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: !!length ? "space-between" : "flex-start",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <Text style={fonts.mediumText_bold}>{title}</Text>
        {!!length && (
          <Text style={[fonts.mediumText_regular, { color: colors.gray20 }]}>
            {length}/30
          </Text>
        )}
      </View>
      <InputField containerStyle={{ maxWidth: 343 }} {...inputPrors} />
    </View>
  );
}

export function EditProfile({
  onCancel,
  onSave,
  onDelete,
}: {
  onCancel: () => void;
  onSave: () => void;
  onDelete: () => void;
}) {
  const isDesktop: boolean = useIsDesktop();

  const user = useSelector((store: RootState) => store.user);

  const [name, setName] = useState(user?.data?.first_name || "");
  const [surname, setSurname] = useState(user?.data?.second_name || "");
  const [phone, setPhone] = useState(
    user.data?.auth_providers?.[0]?.username || ""
  );
  const [email, setEmail] = useState(user?.data?.email);

  const [updateMeToken, updateMe] = useUniqueDispatch(
    userUpdateMeAction.request
  );

  function onPressSave() {
    updateMe([{ first_name: name, second_name: surname, email: email }]);
  }

  const isUserLoading = isLoading(user, updateMeToken);

  useEffect(() => {
    if (isData(user, updateMeToken)) {
      onSave();
    }
  }, [user]);

  const disableButton = useMemo(
    () => !(name && surname && phone && email),
    [name, surname, phone, email]
  );

  return (
    <View style={[styles.mainContainer, isDesktop && { paddingHorizontal: 0 }]}>
      <View
        style={[
          styles.adaptiveContainer,
          isDesktop && styles.adaptiveContainer_desktop,
        ]}
      >
        <UserAvatar
          user={user.data}
          size={isDesktop ? 82 : 124}
          style={isDesktop ? { marginRight: 12 } : { marginBottom: 16 }}
        />
        <View style={styles.inputPairsContainer}>
          <View
            style={[styles.inputsPair, isDesktop && { flexDirection: "row" }]}
          >
            <EditFieldContainer
              title="Name"
              value={name}
              onChangeText={(text) =>
                text.length <= 30 && setName(text.replace(/\s/g, ""))
              }
              length={name.length}
              placeholder="Name"
            />
            <EditFieldContainer
              title="Surname"
              value={surname}
              onChangeText={(text) =>
                text.length <= 30 && setSurname(text.replace(/\s/g, ""))
              }
              length={surname.length}
              placeholder="Surname"
            />
          </View>
          <View
            style={[
              styles.inputsPair,
              isDesktop && { flexDirection: "row" },
              { marginBottom: isDesktop ? 24 : 26 },
            ]}
          >
            <EditFieldContainer
              title="Number"
              value={phone}
              editable={false}
              onChangeText={setPhone}
              placeholder="Phone"
            />
            {/*<EditFieldContainer*/}
            {/*  title="Email"*/}
            {/*  editable={false}*/}
            {/*  value={email}*/}
            {/*  onChangeText={setEmail}*/}
            {/*  placeholder="Email"*/}
            {/*/>*/}
          </View>
          <View
            style={[styles.buttonsContainer, isDesktop && { marginBottom: 32 }]}
          >
            <CommonButton
              type="black"
              size="medium"
              title="Cancel"
              onPress={onCancel}
            />
            <CommonButton
              type="blackFilled"
              size="medium"
              title="Save changes"
              onPress={onPressSave}
              disabled={disableButton}
              isLoading={isUserLoading}
            />
          </View>
          <View style={styles.deleteContainer}>
            <Text
              style={[
                fonts.smallMediumText_regular,
                { color: colors.WarningRed },
              ]}
              onPress={onDelete}
            >
              Delete account
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    maxWidth: 790,
    paddingHorizontal: 16,
    alignItems: "center",
  },
  adaptiveContainer: {
    width: "100%",
    alignItems: "center",
    marginBottom: 32,
    flexDirection: "column",
  },
  adaptiveContainer_desktop: {
    alignItems: "flex-start",
    marginBottom: 40,
    flexDirection: "row",
  },
  inputPairsContainer: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
  },
  inputsPair: {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 343,
    marginBottom: 56,
  },
  deleteContainer: {
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: colors.gray81,
    paddingTop: 16,
  },
});
