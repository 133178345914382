import axios from "axios";
import store from "../store";
import { BASE_PATH, TOKEN_PREFIX } from "./constants";

import {
  catchResponseError,
  placeTokenIntoRequests,
} from "../../../../libraries/packages/api-interceptors/src";

import { Alert } from "react-native";
import { userLogoutAction } from "../store/user/action";
import { Api } from "@gadder/common/src/api/api";
export const axiosInstance = axios.create({ baseURL: BASE_PATH });

placeTokenIntoRequests(
  axiosInstance,
  () => {
    return store.getState().token.data;
  },
  "Authorization",
  TOKEN_PREFIX
);

catchResponseError(
  axiosInstance,
  (e) => {
    store.dispatch(userLogoutAction.request([], "401"));
    return e;
  },
  401
);

catchResponseError(axiosInstance, (e) => {
  Alert.alert("Server error", e);
});

export const api = new Api(axiosInstance);
