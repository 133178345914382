import { UsersPermissions_User } from "../api/models/UsersPermissions_User";
import { Image, View, ViewProps } from "react-native";

import React from "react";
import { ReactComponent as MascotSmall1 } from "../assets/svg/mascot_small_1.svg";
import { ReactComponent as MascotSmall3 } from "../assets/svg/mascot_small_2.svg";
import { ReactComponent as MascotSmall2 } from "../assets/svg/mascot_small_3.svg";

export enum UserColors {
  RED = "RED",
  BLUE = "BLUE",
  ORANGE = "ORANGE",
  PURPLE = "PURPLE",
  YELLOW = "YELLOW",
  PINK = "PINK",
  LIGHT_PINK = "LIGHT_PINK",
  LIGHT_GREEN = "LIGHT_GREEN",
  GREEN = "GREEN",
  BLACK = "BLACK",
  AQUA = "AQUA",
}

export const handleGradientColor = (color: UserColors = UserColors.BLACK) => {
  switch (color) {
    case UserColors.AQUA:
      return ["#C5FCFF", "#57A6AB"];
    case UserColors.BLACK:
      return ["#DBD6E0", "#251F2C"];
    case UserColors.BLUE:
      return ["#C5DEF5", "#316BA0"];
    case UserColors.GREEN:
      return ["#D5FBED", "#2C9D73"];
    case UserColors.LIGHT_GREEN:
      return ["#FFFCE2", "#C7B728"];
    case UserColors.LIGHT_PINK:
      return ["#FEEEF1", "#E48A99"];
    case UserColors.ORANGE:
      return ["#FFECD7", "#E8A353"];
    case UserColors.PINK:
      return ["#FBD7EB", "#BB6594"];
    case UserColors.PURPLE:
      return ["#D6D2FF", "#766FC9"];
    case UserColors.RED:
      return ["#F9DADF", "#AA2F44"];
    case UserColors.YELLOW:
      return ["#FFFCE2", "#F6C970"];
    default:
      return ["#DBD6E0", "#251F2C"];
  }
};

const localeUrlHandler = (url?: string) => {
  return !url
    ? null
    : url.startsWith("/uploads")
    ? process.env.REACT_APP_BASE_PATH + url
    : url;
};

export const UserAvatar = ({
  user,
  size = 40,
  ...props
}: { user?: UsersPermissions_User; size?: number } & ViewProps) => {
  const rawUrl =
    (size > 100
      ? user?.photo?.formats?.medium?.url
      : user?.photo?.formats?.thumbnail?.url) || user?.photo?.url;

  const url = localeUrlHandler(rawUrl);

  const color = handleGradientColor(user?.color as UserColors)[1];

  return (
    <View style={[{ width: size, height: size }, props.style]}>
      <MascotIcon
        color={color}
        size={size}
        style={{
          borderRadius: size / 2,
          width: size,
          height: size,
          overflow: "hidden",
        }}
        name={user?.avatar_type || null}
      />
      {url && (
        <Image
          source={{ uri: url }}
          style={[
            {
              width: size,
              height: size,
              borderRadius: size / 2,
              position: "absolute",
            },
          ]}
        />
      )}
    </View>
  );
};

export const MascotIcon = ({
  name = "small",
  color = "E48A99",
  size = 40,
  style,
}: {
  name?: string | null;
  size?: number;
  color?: string;
  style?: any;
}) => {
  switch (name) {
    case "large":
      return (
        <MascotSmall1
          key={color}
          width={size}
          height={size}
          fill={color}
          style={style}
        />
      );
    case "medium":
      return (
        <MascotSmall2
          key={color}
          width={size}
          height={size}
          fill={color}
          style={style}
        />
      );
    case "small":
      return (
        <MascotSmall3
          key={color}
          width={size}
          height={size}
          fill={color}
          style={style}
        />
      );
    default:
      return (
        <MascotSmall2
          key={color}
          width={size}
          height={size}
          fill={color}
          style={style}
        />
      );
  }
};
