import {
  StyleSheet,
  TextInput,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TextInputProps,
} from "react-native";
import { ReactComponent as AlertCircleIcon } from "@gadder/common/src/assets/svg/alert_circle.svg";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";

export function InputField({
  error,
  errorText,
  style,
  editable = true,
  containerStyle,
  ...props
}: {
  error?: boolean;
  errorText?: string;
  style?: any;
  containerStyle?: StyleProp<ViewStyle>;
} & TextInputProps) {
  return (
    <View style={[styles.container, containerStyle]}>
      <TextInput
        editable={editable}
        placeholderTextColor={colors.gray20}
        style={[
          fonts.mediumText_regular,
          styles.input,
          { outlineStyle: "none" },
          style,
          !editable && { backgroundColor: colors.gray90 },
          error && { borderColor: colors.WarningRed, borderWidth: 1 },
        ]}
        {...props}
      />
      {error && errorText && (
        <View style={styles.errorContainer}>
          <Text
            style={[
              fonts.smallText_regular,
              { color: colors.WarningRed, marginRight: 4 },
            ]}
          >
            {errorText}
          </Text>
          <AlertCircleIcon />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 300,
    width: "100%",
  },
  input: {
    height: 46,
    backgroundColor: colors.LightGrayishYellow,
    borderRadius: 10,
    borderWidth: 0.5,
    borderColor: colors.gray81,
    textAlign: "center",
  },
  errorContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
