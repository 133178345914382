import React from "react";
import {
  Image,
  View,
  Text,
  StyleProp,
  ViewStyle,
  ImageBackground,
} from "react-native";

import { ReactComponent as GadderIcon } from "@gadder/common/src/assets/svg/gadder_mascot_white_tiny.svg";

import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";

import { ReactComponent as HeartBlack } from "@gadder/common/src/assets/svg/pin_heart_black.svg";
import { ReactComponent as HeartBlue } from "@gadder/common/src/assets/svg/pin_heart_blue.svg";
import { ReactComponent as HeartRed } from "@gadder/common/src/assets/svg/pin_heart_red.svg";
import { ReactComponent as HeartGreen } from "@gadder/common/src/assets/svg/pin_heart_green.svg";
import { ReactComponent as HeartWhite } from "@gadder/common/src/assets/svg/pin_heart_white.svg";
import { ReactComponent as HeartYellow } from "@gadder/common/src/assets/svg/pin_heart_yellow.svg";
import {
  FullPlaceType,
  PlaceTagType,
} from "@gadder/common/src/api/custom-models/place";

export const localeUrlHandler = (url?: string) => {
  return !url
    ? null
    : url.startsWith("/uploads")
    ? process.env.GADDER_API_URL + url
    : url;
};

export const ClusterPin = ({
  places,
  count,
  active = false,
}: {
  places: FullPlaceType[];
  count: number;
  active?: boolean;
}) => {
  return (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
      <View
        style={{
          flexDirection: "row",
          position: "relative",
          height: 42,
          width: 42 + (places.length - 1) * 14,
        }}
      >
        {places.map((_place, idx) => (
          <MapPin
            style={{ position: "absolute", left: 14 * idx, zIndex: 5 - idx }}
            place={_place}
          />
        ))}
      </View>

      <View
        style={{
          backgroundColor: active ? colors.black : "white",
          borderRadius: 10,
          borderWidth: 1,
          borderColor: active ? "white" : colors.black,
          paddingBottom: 4,
          paddingHorizontal: 10,
          alignItems: "center",
          paddingTop: 6,
          transform: [{ translateY: -11 }],
          zIndex: -2,
        }}
      >
        <Text
          style={{
            ...fonts.smallText_bold,
            color: active ? "white" : colors.black,
            textAlign: "center",
          }}
        >
          {count} places
        </Text>
      </View>
    </View>
  );
};

export const MapPin = ({
  style,
  place,
  active = false,
}: {
  active?: boolean;
  place: FullPlaceType;
  style?: StyleProp<ViewStyle>;
}) => {
  const borderColorHandler = (tag: PlaceTagType | null) => {
    switch (tag) {
      case "recommended":
        return "#739DCF";
      case "promo":
        return "#FB89A4";
      case "new":
        return "#52AD3B";
      case "trending":
        return "#F6D048";
      default:
        return "white";
    }
  };

  const favIconHandler = (tag: PlaceTagType | null) => {
    switch (tag) {
      case "recommended":
        return <HeartBlue />;
      case "promo":
        return <HeartRed />;
      case "new":
        return <HeartGreen />;
      case "trending":
        return <HeartYellow />;
      default:
        return <HeartWhite />;
    }
  };

  const markerSize = place.is_recommended ? 62 : 42;

  return (
    <View
      style={[
        {
          width: markerSize,
          height: markerSize + (place.is_recommended ? 30 : 0),
          justifyContent: "center",
          alignItems: "center",
        },
        style,
      ]}
    >
      <View
        style={[
          {
            width: markerSize - 4,
            height: markerSize - 4,
            borderRadius: (markerSize - 4) / 2,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: active
              ? colors.black
              : borderColorHandler(place.tag),

            elevation: 2,
            shadowOpacity: 0.2,
            shadowRadius: 2,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowColor: "#000000",
          },
        ]}
      >
        {place.thumbnail_url ? (
          <Image
            resizeMode={"cover"}
            source={{
              uri: localeUrlHandler(place.thumbnail_url),
            }}
            style={{
              width: markerSize - 8,
              height: markerSize - 8,
              borderRadius: (markerSize - 8) / 2,
              zIndex: 2,
            }}
          />
        ) : (
          <View
            style={{
              width: markerSize - 8,
              height: markerSize - 8,
              borderRadius: (markerSize - 8) / 2,
              backgroundColor: colors.black,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GadderIcon />
          </View>
        )}

        {place.user_status === "favorite" && (
          <View style={{ position: "absolute", zIndex: 3 }}>
            {active ? <HeartBlack /> : favIconHandler(place.tag)}
          </View>
        )}
      </View>

      {place.is_recommended && (
        <View
          style={{
            position: "absolute",
            backgroundColor: active ? colors.black : colors.blueLight,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: active ? "white" : colors.black,
            alignItems: "center",
            zIndex: -2,
            paddingHorizontal: 10,
            paddingBottom: 6,
            paddingTop: 18,

            bottom: 0,
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              ...fonts.tiny_bold,
              color: active ? "white" : colors.black,
              textAlign: "center",
            }}
          >
            For you
          </Text>
        </View>
      )}
    </View>
  );
};
