import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const placeGetAction = createAsyncActionWithMeta(
  "place_placeGetAction_REQUEST",
  "place_placeGetAction_SUCCESS",
  "place_placeGetAction_FAILURE"
)<Parameters<typeof api.place.methods.get>, any, Error>();

export const placeGetBookingDataAction = createAsyncActionWithMeta(
  "place_placeGetBookingDataAction_REQUEST",
  "place_placeGetBookingDataAction_SUCCESS",
  "place_placeGetBookingDataAction_FAILURE"
)<Parameters<typeof api.place.methods.getBookingData>, any, Error>();
