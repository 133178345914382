import { placeBookingDataGetBookingDataAction } from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";

export const bookingPlaceDataReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null).handleAction(
    [placeBookingDataGetBookingDataAction.success],
    (state, action) => {
      return state;
    }
  ),
  isLoading: createLoadingReducer(placeBookingDataGetBookingDataAction),
  lastOpId: createOperationReducer(placeBookingDataGetBookingDataAction),
  error: createErrorReducer(placeBookingDataGetBookingDataAction),
});
