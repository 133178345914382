import {
  userCheckEmailAction,
  userDeleteMeAction,
  userForgotPasswordAction,
  userGetMeAction,
  userGetMyPayment,
  userGetMyPaymentAction,
  userLoginAction,
  userLogoutAction,
  userRegisterAction,
  userResetPasswordAction,
  userSendConfirmEmailAction,
  userUpdateMeAction,
} from "./action";

import { combineReducers } from "redux";
import { action, createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";

export const userReducer = combineReducers<{
  data: any;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction(
      [userRegisterAction.success],
      (state, action) => action.payload
    )
    .handleAction([userLoginAction.success], (state, action) => action.payload)
    .handleAction(
      [userCheckEmailAction.success],
      (state, action) => action.payload
    )
    .handleAction([userLogoutAction.success], (state, action) => null)
    .handleAction([userGetMeAction.success], (state, action) => action.payload)
    .handleAction(
      [userUpdateMeAction.success],
      (state, action) => action.payload
    )
    .handleAction(
      [userResetPasswordAction.success],
      (state, action) => action.payload.user
    )
    .handleAction([userDeleteMeAction.success], (state, action) => null)
    .handleAction([userGetMyPaymentAction.success], (state, action) =>
      !state ? state : { ...state, payment_provider: action.payload }
    ),
  isLoading: createLoadingReducer(
    userLoginAction,
    userGetMeAction,
    userUpdateMeAction,
    userDeleteMeAction,
    userRegisterAction,
    userForgotPasswordAction,
    userSendConfirmEmailAction,
    userCheckEmailAction,
    userResetPasswordAction,
    userGetMyPaymentAction
  ),
  lastOpId: createOperationReducer(
    userLoginAction,
    userGetMeAction,
    userUpdateMeAction,
    userDeleteMeAction,
    userRegisterAction,
    userForgotPasswordAction,
    userSendConfirmEmailAction,
    userCheckEmailAction,
    userResetPasswordAction,
    userGetMyPaymentAction
  ),
  error: createErrorReducer(
    userLoginAction,
    userGetMeAction,
    userUpdateMeAction,
    userDeleteMeAction,
    userRegisterAction,
    userForgotPasswordAction,
    userSendConfirmEmailAction,
    userCheckEmailAction,
    userResetPasswordAction,
    userGetMyPaymentAction
  ),
});
