import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import api from "../storeApi";
import {
  chatbotListDeleteChatAction,
  chatbotListGetChatListAction,
  chatbotListPostChatAction,
  chatbotListUpdateChatAction,
} from "./action";
import { chatbotPostMessageAction } from "../chatbot/action";

export const chatbotListReducer = combineReducers<{
  data: typeof api.chatbotList.type;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction(
      [chatbotListGetChatListAction.success],
      (state, action) => action.payload
    )
    .handleAction([chatbotListPostChatAction.success], (state, action) => [
      ...(state || []),
      action.payload,
    ])
    .handleAction([chatbotListUpdateChatAction.request], (state, action) =>
      state
        ? state.map((_chat) =>
            _chat.id === action.payload[0]
              ? { ..._chat, name: action.payload[1].name }
              : _chat
          )
        : state
    )
    .handleAction([chatbotListDeleteChatAction.request], (state, action) =>
      state ? state.filter((_chat) => _chat.id !== action.payload[0]) : state
    )
    .handleAction([chatbotPostMessageAction.request], (state, action) =>
      state
        ? state.map((_chat) =>
            _chat.id === action.payload[0].chat_id
              ? { ..._chat, name: _chat.name || action.payload[0].message_text }
              : _chat
          )
        : state
    ),
  isLoading: createLoadingReducer(
    chatbotListGetChatListAction,
    chatbotListPostChatAction,
    chatbotListUpdateChatAction,
    chatbotListDeleteChatAction
  ),
  lastOpId: createOperationReducer(
    chatbotListGetChatListAction,
    chatbotListPostChatAction,
    chatbotListUpdateChatAction,
    chatbotListDeleteChatAction
  ),
  error: createErrorReducer(
    chatbotListGetChatListAction,
    chatbotListPostChatAction,
    chatbotListUpdateChatAction,
    chatbotListDeleteChatAction
  ),
});
