import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { placelistGetAction } from "./action";

export const getRequest = function* (
  action: ReturnType<typeof placelistGetAction.request>
) {
  try {
    let response = yield call(() =>
      api.placelist.methods.get(...action.payload)
    );
    yield put(placelistGetAction.success(response.data, action.meta));
  } catch (error) {
    yield put(placelistGetAction.failure(error, action.meta));
  }
};

export const placelistSaga = function* () {
  yield all([takeEvery(placelistGetAction.request, getRequest)]);
};
