import api from "../storeApi";
import { all, call, put, takeEvery, take } from "redux-saga/effects";
import { placeBookingDataGetBookingDataAction } from "./action";

export const bookingPlaceDataSaga = function* () {
  yield all([
    takeEvery(
      placeBookingDataGetBookingDataAction.request,
      getBookingDataRequest
    ),
  ]);
};

export const getBookingDataRequest = function* (
  action: ReturnType<typeof placeBookingDataGetBookingDataAction.request>
) {
  try {
    const response = yield call(() =>
      api.mapSearchPlaces.methods.getBookingData(...action.payload)
    );

    yield put(
      placeBookingDataGetBookingDataAction.success(
        { ...response.data },
        action.meta
      )
    );
  } catch (error) {
    yield put(placeBookingDataGetBookingDataAction.failure(error, action.meta));
  }
};
