import React, { useMemo, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TextStyle,
  Linking,
  TouchableOpacity,
} from "react-native";
import { PlaceTagType } from "../api/models/place";

import { CommonButton } from "./buttons";
import _ from "lodash";
import { UsersPermissions_User } from "../api/models/UsersPermissions_User";
import { UserAvatar } from "./user-avatar";
import LinearGradient from "react-native-web-linear-gradient";
import { PhotoCarousel } from "./PhotoCarousel";
import { ReactComponent as MapLight } from "../assets/svg/map_light.svg";
import { ReactComponent as ShareLight } from "../assets/svg/share_light.svg";
import { ReactComponent as IconRightShortArrow } from "../assets/svg/arrow_short_light.svg";
import { fonts } from "../styles/fonts";
import { colors } from "../styles/colors";
import { FullPlaceType } from "../api/custom-models/place";

export const GRADIENT_ANGLE = 28.67;

interface placeCardType {
  extended?: boolean;
  place: FullPlaceType;
  onExtendPress?: () => void;
  onAddressPress?: () => void;
  onMenuPressed?: () => void;
  showFriends?: boolean;
  disableScroll?: boolean;
}

export const TagsWithDivider = ({
  tags,
  textStyle,
  dividerStyle,
  style,
}: {
  style?: StyleProp<ViewStyle>;
  tags?: string[];
  textStyle?: StyleProp<TextStyle>;
  dividerStyle?: ViewStyle;
}) => {
  return (
    <View style={[style]}>
      {tags && tags.length > 0 && (
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
            },
          ]}
        >
          {tags.map((tag, index) => (
            <>
              <Text
                style={[
                  fonts.mediumText_medium,
                  { color: colors.LightGrayishYellow },
                  textStyle,
                ]}
                key={tag}
              >
                {tag.toLowerCase()}
              </Text>
              {index !== tags.length - 1 && (
                <View
                  key={tag + "circle"}
                  style={[styles.circleDivider, dividerStyle]}
                />
              )}
            </>
          ))}
        </View>
      )}
    </View>
  );
};

export const priceHandler = (price: string) => {
  switch (price) {
    case "$$":
      return "£15 - £25";
    case "$$$":
      return "£35 - £50";
    case "$$$$":
      return "£65+";
    default:
      return price;
  }
};

export const PlaceCard = ({
  place,
  style,
  ...props
}: placeCardType & {
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <View style={[styles.container, style]}>
      <PlaceInfo style={{ flex: 1 }} place={place} {...props} />
    </View>
  );
};

export const getPlaceTags = (place?: FullPlaceType) =>
  !place
    ? []
    : _.uniq(
        [
          // place.vibe,
          place.feature?.split(",")[0],
          ...(place.cuisine
            ? place.cuisine?.split(",")
            : (place.feature?.split(",") || []).slice(1)),
        ].filter((tag) => Boolean(tag) && tag !== "NA" && tag !== "nan")
      )
        .slice(0, 2)
        .map((tag) => tag.trim());

export const usePlaceTags = (place: FullPlaceType | null) =>
  useMemo(() => (place ? getPlaceTags(place) : []), [place]);

export const PlaceInfo = ({
  place,
  extended = false,
  onExtendPress,
  onMenuPressed,
  style,
  onAddressPress,
}: placeCardType & {
  style?: StyleProp<ViewStyle>;
}) => {
  const [descriptionExtended, setDescriptionExtended] = useState(extended);

  const filteredPictures: string[] = useMemo(
    () =>
      [place.picture_1, place.picture_2, place.picture_3].filter(
        (img) => !!img
      ),
    [place]
  );

  const tags = usePlaceTags(place);

  return (
    <View style={style}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <Text
          numberOfLines={1}
          style={[
            fonts.averageLargeText_bold,
            { color: colors.LightGrayishYellow, flex: 1, marginRight: 6 },
          ]}
        >
          {place.name}
        </Text>

        <Text
          numberOfLines={1}
          style={[fonts.mediumText_bold, { color: colors.LightGrayishYellow }]}
        >
          {priceHandler(place.price)}
        </Text>
      </View>

      <CommonButton
        onPress={onAddressPress}
        style={{
          marginBottom: 12,
          zIndex: 2,
          alignSelf: "flex-start",
          width: "100%",
        }}
        LeftIcon={<MapLight style={{ marginRight: 4 }} />}
        textStyle={[fonts.smallMediumText_bold, {}]}
        containerStyle={{
          paddingHorizontal: 12,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        title={place.address || " "}
        size={"small"}
        type={"lightYellow"}
      />

      <View>
        {filteredPictures.length !== 0 && (
          <PhotoCarousel
            containerStyle={{ aspectRatio: 1 }}
            style={{ marginBottom: 12 }}
            data={filteredPictures}
          />
        )}
        {/*<PlaceTag*/}
        {/*  style={{ position: "absolute", top: 12, left: 12 }}*/}
        {/*  tag={place.tag}*/}
        {/*/>*/}
        {/*<InstagramPlace*/}
        {/*  style={{ position: "absolute", bottom: 42, left: 12 }}*/}
        {/*  place={place}*/}
        {/*/>*/}
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <TagsWithDivider style={{ flex: 1 }} tags={tags} />

        {place.menu && (
          <CommonButton
            onPress={() => {
              Linking.openURL(place.menu);
              onMenuPressed?.();
            }}
            RightIcon={<ShareLight style={{ marginLeft: 4 }} />}
            containerStyle={{ paddingHorizontal: 8 }}
            textStyle={fonts.mediumText_bold}
            style={{ zIndex: 2 }}
            type={"lightYellow"}
            size={"small"}
            title={"menu"}
          />
        )}
      </View>

      <Text
        numberOfLines={!descriptionExtended ? 3 : undefined}
        style={[
          fonts.smallMediumText_regular,
          { color: colors.LightGrayishYellow },
        ]}
      >
        {place.description || place.ABOUT}
      </Text>

      {!descriptionExtended && (
        <Text
          onPress={() =>
            onExtendPress ? onExtendPress() : setDescriptionExtended(true)
          }
          style={[
            fonts.smallMediumText_bold,
            { color: colors.LightGrayishYellow },
          ]}
        >
          learn more
        </Text>
      )}
    </View>
  );
};

export const LikedFollowers = ({
  userList,
  style,
  textStyle,
  onPress,
  hideArrow = false,
}: {
  userList?: UsersPermissions_User[];
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
  hideArrow?: boolean;
}) => (
  <>
    {userList && userList.length > 0 && (
      <TouchableOpacity
        disabled={!onPress}
        onPress={onPress}
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
          },
          style,
        ]}
      >
        <View
          style={[
            styles.userAvatarContainer,
            {
              width:
                32 +
                (userList.length === 1 ? 0 : userList.length === 2 ? 20 : 40),
            },
          ]}
        >
          {userList.map(
            (user, index) =>
              index < 3 && (
                <UserAvatar
                  size={32}
                  key={user.id}
                  style={[
                    styles.userAvatar,
                    {
                      zIndex: 3 - index,
                      left: index * 20,
                    },
                  ]}
                  user={user}
                />
              )
          )}
        </View>
        <Text
          style={[
            fonts.smallMediumText_medium,
            { color: colors.LightGrayishYellow, flex: 1 },
            textStyle,
          ]}
        >
          {userList[0].nametag?.toLowerCase() + " "}
          {userList.length === 1 ? "likes " : "and others like "}
          this place
        </Text>
        {!hideArrow && <IconRightShortArrow />}
      </TouchableOpacity>
    )}
  </>
);

export const PlaceTag = ({
  little = false,
  tag,
  style,
  onPress,
  disabled,
  containerStyle,
  textStyle,
}: {
  little?: boolean;
  tag: PlaceTagType | null;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
  disabled?: boolean;
}) => {
  const tagLabels = {
    recommended: "recommended",
    new: "new",
    trending: "trending 🔥",
    promo: little ? "promo" : "redeem promo",
  };

  const tagColors = {
    recommended: ["#739DCF", "#13363F"],
    new: ["#52AD3B", "#64D647"],
    trending: ["#F6D048", "#D1741F"],
    promo: ["#FB89A4", "#DA5547"],
  };

  return (
    <TouchableOpacity style={style} onPress={onPress} disabled={disabled}>
      {tag && (
        <LinearGradient
          angle={tag === "new" ? 257.61 : 28.67}
          colors={tagColors[tag]}
          style={[
            {
              alignSelf: "flex-start",
              paddingHorizontal: little ? 8 : 16,
              paddingTop: 3,
              paddingBottom: little ? 3 : 5,
              borderRadius: 28,
            },
            containerStyle,
          ]}
        >
          <Text
            style={[
              little ? fonts.tiny_bold : fonts.mediumText_bold,
              { color: colors.LightGrayishYellow },
              textStyle,
            ]}
          >
            {tagLabels[tag]}
          </Text>
        </LinearGradient>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderRadius: 24,
  },
  circleDivider: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 8,
    backgroundColor: colors.LightGrayishYellow,
  },
  overlay: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: colors.white,
    opacity: 0.4,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  userAvatarContainer: {
    position: "relative",
    marginRight: 8,
    flexDirection: "row",
    height: 32,
  },
  userAvatar: {
    overflow: "hidden",
    position: "absolute",
    borderRadius: 16,
    elevation: 2,
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      width: 1,
      height: 0,
    },
    shadowColor: "#000000",
    backgroundColor: "transparent",
  },
});
