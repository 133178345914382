import { useCallback } from 'react'
const uniqWith = require('lodash/uniqWith')
export const enhanceOptionsWithParams = (options, params) => {
  return {
    ...(options || {}),
    params: { ...params, ...(options?.params || {}) },
  }
}
export type PaginationConfigType = {
  pageField: string
  resetField: string
  limitField: string
  offsetField: string
  insertPageQuery: (options: any, page: number) => any
  metaSelector: (data: any) => any
  listSelector: (data: any) => any[]
}
export const PaginationConfig = {
  config: {
    pageField: 'page',
    resetField: 'reset',
    limitField: 'limit',
    offsetField: 'start',
    insertPageQuery: (options, page) =>
      enhanceOptionsWithParams(options, { 'pagination[page]': page }),
    metaSelector: (data) => data.meta,
    listSelector: (data) => data.data,
  },
}
export const useLimitOffsetPagination = (
  state,
  dispatch,
  config?: Partial<PaginationConfigType>,
) => {
  //TODO maybe separate loadFirst and loadNext? but as loadFirst can be used raw dispatch
  return useCallback(
    (options = {}, limit = 25) => {
      const limitField = PaginationConfig.config.limitField
      const offsetField = PaginationConfig.config.offsetField
      const { total } =
        (config?.metaSelector || PaginationConfig.config.metaSelector)(
          state.data,
        ) || {}
      if (state.data.meta?.[offsetField] < state.data.length) {
        dispatch([
          {
            ...options,
            params: {
              ...options.params,
              [offsetField]: state.data.length,
              [limitField]: limit,
            },
          },
        ])
      }
    },
    [state, dispatch],
  )
}
export const usePagination = (
  state,
  dispatch,
  config?: Partial<PaginationConfigType>,
) => {
  //TODO maybe separate loadFirst and loadNext? but as loadFirst can be used raw dispatch
  return useCallback(
    (options = {}) => {
      const { page, pageCount } =
        (config?.metaSelector || PaginationConfig.config.metaSelector)(
          state.data,
        ) || {}
      if (page && pageCount > page) {
        dispatch([
          (config?.insertPageQuery || PaginationConfig.config.insertPageQuery)(
            options,
            page + 1,
          ),
        ])
      }
    },
    [state, dispatch],
  )
}
export const paginationReducer =
  (keyExtractor?, config?: Partial<PaginationConfigType>) =>
  (state, action) => {
    const newState = action.payload
    const listSelector =
      config?.listSelector || PaginationConfig.config.listSelector
    const metaSelector =
      config?.metaSelector || PaginationConfig.config.metaSelector
    const pageField = config?.pageField || PaginationConfig.config.pageField
    const resetField = config?.resetField || PaginationConfig.config.resetField
    const limitField = config?.limitField || PaginationConfig.config.limitField
    const offsetField =
      config?.offsetField || PaginationConfig.config.offsetField
    if (
      metaSelector(action.payload)?.[offsetField] ||
      (metaSelector(action.payload)?.[pageField] > 1 &&
        !metaSelector(action.payload)?.[resetField])
    ) {
      newState.data = keyExtractor
        ? uniqWith(
            [...(listSelector(state) || []), ...listSelector(action.payload)],
            (val1, val2) => {
              return keyExtractor(val1) === keyExtractor(val2)
            },
          )
        : [...(listSelector(state) || []), ...listSelector(action.payload)]
    } else {
      newState.data = action.payload.data
    }

    return newState
  }
