import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";
import { createAction } from "typesafe-actions";

export const mapSearchPlacesGetAction = createAsyncActionWithMeta(
  "mapSearchPlaces_mapSearchPlacesGetAction_REQUEST",
  "mapSearchPlaces_mapSearchPlacesGetAction_SUCCESS",
  "mapSearchPlaces_mapSearchPlacesGetAction_FAILURE"
)<Parameters<typeof api.mapSearchPlaces.methods.get>, any, Error>();

export const mapSearchPlacesClearAllBookingDataAction = createAction(
  "mapSearchPlaces_mapSearchPlacesClearAllBookingDataAction"
)<any>();
