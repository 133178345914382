import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";

import {
  bookingSearchEndAction,
  bookingSearchNextPlacesAction,
  bookingSearchStartAction,
} from "./action";
import { mapSearchPlacesClearAllBookingDataAction } from "../mapSearchPlaces/action";

export const bookingPlacesReducer = combineReducers<{
  data: { isPlacesLoading: boolean; places: any[]; timeStarted: string | null };
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer({ isPlacesLoading: false, places: [], timeStarted: null })
    .handleAction([bookingSearchStartAction.success], (state, action) => {
      return {
        isPlacesLoading: true,
        places: [],
        timeStarted: action.payload?.[0]?.timeStarted,
      };
    })
    .handleAction([bookingSearchEndAction.success], (state, action) => {
      if (state.timeStarted !== action.payload?.[0]?.timeStarted) {
        return state;
      }

      const newPlaces = action.payload?.[0]?.newPlaces || [];

      return {
        timeStarted: null,
        isPlacesLoading: false,
        places: [...state.places, ...newPlaces],
      };
    })
    .handleAction([bookingSearchNextPlacesAction.success], (state, action) => {
      if (state.timeStarted !== action.payload?.[0]?.timeStarted) {
        return state;
      }

      const newPlaces = action.payload?.[0]?.newPlaces || [];

      return {
        ...state,
        isPlacesLoading: true,
        places: [...state.places, ...newPlaces],
      };
    })
    .handleAction([mapSearchPlacesClearAllBookingDataAction], (state) => {
      return {
        isPlacesLoading: false,
        places: [],
      };
    }),
  isLoading: createLoadingReducer(),
  lastOpId: createOperationReducer(),
  error: createErrorReducer(),
});
