import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const userRegisterAction = createAsyncActionWithMeta(
  "user_userRegisterAction_REQUEST",
  "user_userRegisterAction_SUCCESS",
  "user_userRegisterAction_FAILURE"
)<Parameters<typeof api.user.methods.register>, any, Error>();

export const userLoginAction = createAsyncActionWithMeta(
  "user_userLoginAction_REQUEST",
  "user_userLoginAction_SUCCESS",
  "user_userLoginAction_FAILURE"
)<Parameters<typeof api.user.methods.login>, any, Error>();

export const userLogoutAction = createAsyncActionWithMeta(
  "user_userLogoutAction_REQUEST",
  "user_userLogoutAction_SUCCESS",
  "user_userLogoutAction_FAILURE"
)<Parameters<typeof api.user.methods.login>, any, Error>();

export const userGetMeAction = createAsyncActionWithMeta(
  "user_userGetMeAction_REQUEST",
  "user_userGetMeAction_SUCCESS",
  "user_userGetMeAction_FAILURE"
)<Parameters<typeof api.user.methods.getMe>, any, Error>();

export const userUpdateMeAction = createAsyncActionWithMeta(
  "user_userUpdateMeAction_REQUEST",
  "user_userUpdateMeAction_SUCCESS",
  "user_userUpdateMeAction_FAILURE"
)<Parameters<typeof api.user.methods.updateMe>, any, Error>();

export const userDeleteMeAction = createAsyncActionWithMeta(
  "user_userDeleteMeAction_REQUEST",
  "user_userDeleteMeAction_SUCCESS",
  "user_userDeleteMeAction_FAILURE"
)<Parameters<typeof api.user.methods.deleteMe>, any, Error>();

export const userForgotPasswordAction = createAsyncActionWithMeta(
  "user_userForgotPasswordAction_REQUEST",
  "user_userForgotPasswordAction_SUCCESS",
  "user_userForgotPasswordAction_FAILURE"
)<Parameters<typeof api.user.methods.forgotPassword>, any, Error>();

export const userSendConfirmEmailAction = createAsyncActionWithMeta(
  "user_userSendConfirmEmailAction_REQUEST",
  "user_userSendConfirmEmailAction_SUCCESS",
  "user_userSendConfirmEmailAction_FAILURE"
)<Parameters<typeof api.user.methods.sendConfirmEmail>, any, Error>();

export const userCheckEmailAction = createAsyncActionWithMeta(
  "user_userCheckEmailAction_REQUEST",
  "user_userCheckEmailAction_SUCCESS",
  "user_userCheckEmailAction_FAILURE"
)<Parameters<typeof api.user.methods.checkEmail>, any, Error>();

export const userResetPasswordAction = createAsyncActionWithMeta(
  "user_userResetPassword_REQUEST",
  "user_userResetPassword_SUCCESS",
  "user_userResetPassword_FAILURE"
)<Parameters<typeof api.user.methods.resetPassword>, any, Error>();

export const userGetMyPaymentAction = createAsyncActionWithMeta(
  "user_userGetMyPayment_REQUEST",
  "user_userGetMyPayment_SUCCESS",
  "user_userGetMyPayment_FAILURE"
)<Parameters<typeof api.user.methods.getMyPayment>, any, Error>();
