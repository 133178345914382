import { userSaga } from "./user/saga";
import { all } from "redux-saga/effects";
import { tokenSaga } from "./token/saga";
import { phoneAuthSaga } from "./phoneAuth/saga";
import { chatbotSaga } from "./chatbot/saga";
import { chatbotListSaga } from "./chatbotList/saga";
import { mapPlacesSaga } from "./mapPlaces/saga";
import { mapSearchPlacesSaga } from "./mapSearchPlaces/saga";
import { placelistsSaga } from "./placelists/saga";
import { placelistSaga } from "./placelist/saga";
import { bookingPlacesSaga } from "./bookingPlaces/saga";
import { bookingPlaceDataSaga } from "./placeBookingData/saga";
import { bugReportSaga } from "./bugReport/saga";
import { placeSaga } from "./place/saga";

const rootSaga = function* () {
  yield all([
    userSaga(),
    tokenSaga(),
    phoneAuthSaga(),
    chatbotSaga(),
    chatbotListSaga(),
    mapPlacesSaga(),
    mapSearchPlacesSaga(),
    placelistsSaga(),
    placelistSaga(),
    bookingPlacesSaga(),
    bookingPlaceDataSaga(),
    bugReportSaga(),
    placeSaga(),
  ]);
};
export default rootSaga;
