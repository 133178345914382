import { placeGetAction, placeGetBookingDataAction } from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";

export const placeReducer = combineReducers<{
  data: FullPlaceType;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null)
    .handleAction([placeGetAction.success], (state, action) => action.payload)
    .handleAction([placeGetBookingDataAction.success], (state, action) =>
      !state
        ? state
        : {
            ...state,
            booking_dates: action.payload.booking_dates,
            book_provider: action.payload.book_provider,
          }
    ),
  isLoading: createLoadingReducer(placeGetAction, placeGetBookingDataAction),
  lastOpId: createOperationReducer(placeGetAction, placeGetBookingDataAction),
  error: createErrorReducer(placeGetAction, placeGetBookingDataAction),
});
