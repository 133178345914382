import React, { useEffect, useState } from "react";
import {
  Linking,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
  Text,
} from "react-native";
import { ReactComponent as WarnIcon } from "@gadder/common/src/assets/svg/warninig_white.svg";
import { ReactComponent as HelpUsNote } from "@gadder/common/src/assets/svg/help_us_improve_note.svg";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import { useLocation, useNavigate } from "react-router-dom";

export const BugButton = ({
  style,
  size = 65,
  label,
  withNote = false,
}: {
  style?: StyleProp<ViewStyle>;
  label?: string;
  withNote?: boolean;
  size?: number;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <View
      onPress={() => Linking.openURL("https://wa.me/message/WI7DQ3ELWM5DK1")}
      style={style}
    >
      <Pressable
        onPress={() =>
          navigate("/bug-report", { state: { previousLocation: location } })
        }
        style={{
          width: size,
          height: size,
          borderRadius: size,
          backgroundColor: colors.newBlue,
          borderWidth: 1,
          borderColor: colors.white,
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <WarnIcon width={30} height={27} />
        <Text
          style={{
            color: colors.white,
            ...fonts.smallText_medium,
            textAlign: "center",
          }}
        >
          {label}
        </Text>
      </Pressable>

      {withNote && (
        <View style={{ position: "absolute", left: -200, bottom: -50 }}>
          <HelpUsNote />
        </View>
      )}
    </View>
  );
};
