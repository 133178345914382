import React, { PropsWithRef, ReactNode, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TextStyle,
  ViewStyle,
  StyleProp,
  TouchableOpacityProps,
  ActivityIndicator,
} from "react-native";
import { colors } from "../styles/colors";
import { fonts } from "../styles/fonts";
import LinearGradient from "react-native-web-linear-gradient";

export type ButtonProps = {
  type?:
    | "blackFilled"
    | "black"
    | "red"
    | "lightYellow"
    | "gray"
    | "blackNonBordered"
    | "whiteFilled"
    | "white"
    | "instaGradient";
  size?: "large" | "medium" | "small";
  title: string | null;
  isLoading?: boolean;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
} & PropsWithRef<TouchableOpacityProps> & {
    style?: StyleProp<ViewStyle>;
  };

export const CommonButton = ({
  type = "blackFilled",
  size = "large",
  title,
  LeftIcon,
  RightIcon,
  disabled = false,
  onPress,
  textStyle,
  containerStyle,
  isLoading = false,
  ...props
}: ButtonProps) => {
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  return (
    <TouchableOpacity
      {...props}
      disabled={disabled || !onPress || isLoading}
      onPress={onPress}
    >
      <View
        style={[
          styles.container,
          sizeStyles[size].view,
          typeStyles[type].view,
          disabled && styles.disabledView,
          containerStyle,
        ]}
      >
        {isLoading ? (
          <ActivityIndicator
            style={[
              width ? { width: width } : null,
              height ? { height: height } : null,
            ]}
            color={
              type === "blackFilled"
                ? colors.LightGrayishYellow
                : typeStyles[type].view.borderColor
            }
          />
        ) : (
          <>
            {LeftIcon}
            <Text
              onLayout={(e) => {
                setHeight(e.nativeEvent.layout.height);
                setWidth(e.nativeEvent.layout.width);
              }}
              numberOfLines={1}
              style={[sizeStyles[size].text, typeStyles[type].text, textStyle]}
            >
              {title}
            </Text>
            {RightIcon}
          </>
        )}

        <View style={styles.backgroundContainer}>
          {type === "instaGradient" && (
            <LinearGradient
              useAngle
              angle={90}
              style={{ height: "100%", width: "100%" }}
              colors={["#F9CE34", "#EE2A7B", "#6228D7"]}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: 28,
    overflow: "hidden",
  },

  disabledView: { opacity: 0.5 },

  backgroundContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
});

const typeStyles: {
  [key: string]: {
    view: ViewStyle;
    text: TextStyle;
  };
} = {
  blackFilled: StyleSheet.create({
    view: {
      backgroundColor: colors.black,
      borderColor: colors.black,
      borderWidth: 1,
    },
    text: { color: colors.LightGrayishYellow },
  }),

  black: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
      borderColor: colors.black,
      borderWidth: 1,
    },
    text: { color: colors.black },
  }),

  blackNonBordered: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
    },
    text: { color: colors.black },
  }),

  red: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
      borderColor: colors.ImportantRed,
      borderWidth: 1,
    },
    text: { color: colors.ImportantRed },
  }),

  gray: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
      borderColor: colors.gray50,
      borderWidth: 1,
    },
    text: { color: colors.gray50 },
  }),

  whiteFilled: StyleSheet.create({
    view: {
      backgroundColor: colors.white,
      borderColor: colors.white,
      borderWidth: 1,
    },
    text: { color: colors.black },
  }),

  lightYellow: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
      borderColor: colors.LightGrayishYellow,
      borderWidth: 1,
    },
    text: { color: colors.LightGrayishYellow },
  }),

  white: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
      borderColor: colors.white,
      borderWidth: 1,
    },
    text: { color: colors.white },
  }),

  instaGradient: StyleSheet.create({
    view: {
      backgroundColor: "transparent",
    },
    text: { color: colors.white },
  }),
};

const sizeStyles: {
  [key: string]: {
    view: ViewStyle;
    text: TextStyle;
  };
} = {
  small: StyleSheet.create({
    view: {
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
    text: fonts.smallText_regular,
  }),
  medium: StyleSheet.create({
    view: {
      paddingVertical: 12,
      paddingHorizontal: 24,
    },
    text: fonts.mediumText_medium,
  }),
  large: StyleSheet.create({
    view: {
      paddingVertical: 12,
      paddingHorizontal: 24,
    },
    text: fonts.largeText_bold,
  }),
};
