export const FiltersTypeTranslates = {
  cafe: "Coffee",
  bar: "Drinks",
  restaurant: "Dinner",
};

export const FiltersPriceTranslates = {
  "£": "$$",
  "££": "$$$",
  "£££": "$$$$",
};
