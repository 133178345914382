import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { colors } from "../styles/colors";
import {
  GRADIENT_ANGLE,
  PlaceTag,
  TagsWithDivider,
  usePlaceTags,
} from "./PlaceCard";
import { fonts } from "../styles/fonts";
import { FullPlaceType } from "../api/custom-models/place";
import { ReactComponent as MapLight } from "../assets/svg/map_black.svg";
import { ReactComponent as NextButton } from "../assets/svg/round_button_next.svg";
import { ReactComponent as ShareIcon } from "../assets/svg/share_black.svg";
import { ShareButton } from "./ShareButton";
import copy from "copy-to-clipboard";
import { ORIGIN_BASE_URL } from "@gadder/chatbot/src/common/constants";
import LinearGradient from "react-native-web-linear-gradient";
import { ReactComponent as OpenTableIcon } from "../assets/svg/bookingLogo/opentable.svg";
import { ReactComponent as SevenRoomsIcon } from "../assets/svg/bookingLogo/sevenrooms.svg";
import { ReactComponent as ResdiaryIcon } from "../assets/svg/bookingLogo/resdiary.svg";
import { PhotoCarousel } from "./PhotoCarousel";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "../utils/redux-utils";
import { mapSearchPlacesGetBookingDataAction } from "@gadder/chatbot/src/store/mapSearchPlaces/action";
import { placeBookingDataGetBookingDataAction } from "@gadder/chatbot/src/store/placeBookingData/action";
import { RootState } from "@gadder/chatbot/src/store/root-reducer";
import { CommonButton } from "./buttons";
import { useIsMobile } from "@gadder/chatbot/src/pages/ChatPage";
import { DotsLoading } from "@gadder/chatbot/src/components/DotsLoading";
import { ReactComponent as ArrowBlack } from "../assets/svg/arrow_short_black.svg";
import { format } from "date-fns";

const bookingProviderIconHandler = (booking_provider: string) => {
  switch (booking_provider) {
    case "sevenrooms.com": {
      return <SevenRoomsIcon />;
    }
    case "opentable.co.uk": {
      return <OpenTableIcon />;
    }
    case "booking.resdiary.com": {
      return <ResdiaryIcon />;
    }
  }
};

export const PlaceCardMedium = ({
  place,
  onPress,
  onArrowPress,
  style,
  wide = false,
  onHover,
  onHoverOut,
  bookingDataParams,
  onAddressPress,
  imageContainer,
  imageCarouselMode,
}: {
  onPress?: () => void;
  onArrowPress?: () => void;
  place: FullPlaceType;
  style?: StyleProp<ViewStyle>;
  wide?: boolean;
  onHover?: () => void;
  onHoverOut?: () => void;
  bookingDataParams?: { date: Date; personsCount: string };
  onAddressPress?: (place: FullPlaceType | null) => void;
  imageContainer?: StyleProp<ViewStyle>;
  imageCarouselMode?: "none" | "always" | "onHover";
}) => {
  const tags = usePlaceTags(place);

  const shareLinkHandle = (place_id: string) => {
    copy(ORIGIN_BASE_URL + "/place/" + place_id);
  };

  const filteredPictures: string[] = useMemo(
    () => [place.picture_1, place.picture_2, place.picture_3].filter(Boolean),
    [place]
  );

  const [getBookingDataToken, getBookingData, resetBookingRequest] =
    useUniqueDispatch(placeBookingDataGetBookingDataAction.request);

  const bookingDataRequest = useSelector(
    (store: RootState) => store.bookingPlaceData
  );

  const iBookingDatasLoading = isLoading(
    bookingDataRequest,
    getBookingDataToken
  );

  const getBookingDataHandler = () => {
    getBookingData([
      {
        params: {
          id: place.place_id,
          date: format(bookingDataParams.date, "Y-MM-dd"),
          time: format(bookingDataParams.date, "HH:mm"),
          personsCount: bookingDataParams.personsCount,
        },
      },
    ]);
  };

  useEffect(() => {
    resetBookingRequest();
  }, [bookingDataParams]);

  const isMobile = useIsMobile();

  const bookingTimesRef = useRef<FlatList>();

  const [scrollOffset, setScrollOffset] = useState(0);

  return (
    <View style={[style]}>
      <View
        style={[
          {
            borderRadius: 12,
            overflow: "hidden",
            width: "100%",
            flexDirection: wide ? "row" : "column",
            backgroundColor: colors.white,
          },
          {
            shadowOpacity: 0.3,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 6,
            },
            shadowRadius: 16,
          },
        ]}
      >
        <View
          style={[
            wide
              ? { width: 220, alignSelf: "stretch" }
              : { height: 260, width: "100%" },
            imageContainer,
          ]}
        >
          <PhotoCarousel
            imageStyle={{
              aspectRatio: "unset",
            }}
            showType={imageCarouselMode || (isMobile ? "always" : "onHover")}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: colors.black,
            }}
            containerStyle={{ borderRadius: 0 }}
            data={filteredPictures}
          />
        </View>

        <Pressable
          onPress={onPress}
          style={{
            paddingVertical: wide ? 8 : 8,
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <View style={{}}>
            <Text
              numberOfLines={1}
              style={[
                {
                  color: colors.black,
                  marginBottom: 2,
                  paddingHorizontal: 12,
                },
                fonts.mediumText_bold,
              ]}
            >
              {place.name}
            </Text>

            {place.address && (
              <Pressable
                onPress={() => onAddressPress && onAddressPress(place)}
                style={{
                  flexDirection: "row",
                  marginBottom: 2,
                  alignItems: "center",
                  paddingHorizontal: 12,
                }}
              >
                <MapLight style={{ marginRight: 4 }} />
                <Text
                  style={[
                    fonts.smallMediumText_medium,
                    { color: colors.black },
                  ]}
                  numberOfLines={1}
                >
                  {place.address}
                </Text>
              </Pressable>
            )}

            <TagsWithDivider
              textStyle={{
                color: colors.black,
                ...fonts.smallMediumText_regular,
              }}
              dividerStyle={{ backgroundColor: colors.black }}
              style={{ marginBottom: 8, paddingHorizontal: 12 }}
              tags={tags}
            />

            <View style={{ height: 40 }}>
              {bookingDataParams && !place.booking_dates && (
                <View
                  style={{
                    alignItems: "flex-start",
                    paddingLeft: 12,
                    marginTop: 4,
                  }}
                >
                  {iBookingDatasLoading ? (
                    <Text
                      style={{
                        color: colors.black,
                        ...fonts.smallMediumText_semiBold,
                      }}
                    >
                      Finding a table for you
                      <DotsLoading />
                    </Text>
                  ) : (
                    <CommonButton
                      style={{
                        marginBottom: 5,
                      }}
                      onPress={getBookingDataHandler}
                      type={"black"}
                      size={"small"}
                      title={"Check availability"}
                    />
                  )}
                </View>
              )}

              {place.book_provider && place.booking_dates && (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {!isMobile &&
                    place.book_provider !== "none" &&
                    place.booking_dates.length > 0 && (
                      <Pressable
                        onPress={() =>
                          bookingTimesRef.current?.scrollToOffset({
                            offset: scrollOffset - 100,
                            animated: true,
                          })
                        }
                        style={{ marginTop: 6, marginLeft: 12, marginRight: 4 }}
                      >
                        <ArrowBlack />
                      </Pressable>
                    )}

                  {place.book_provider !== "none" &&
                  place.booking_dates.length > 0 ? (
                    <Pressable
                      onHoverIn={onHover}
                      onHoverOut={onHoverOut}
                      style={{ position: "relative", flex: 1 }}
                    >
                      <LinearGradient
                        useAngle
                        angle={90}
                        locations={[0.1, 1]}
                        colors={["rgb(255,255,255,1)", "rgba(255,255,255,0)"]}
                        style={{
                          height: "100%",
                          width: 16,
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />

                      <FlatList
                        horizontal
                        ref={bookingTimesRef}
                        onScroll={(event) => {
                          setScrollOffset(event.nativeEvent.contentOffset.x);
                        }}
                        showsHorizontalScrollIndicator={false}
                        style={{ marginTop: 6 }}
                        data={place.booking_dates}
                        contentContainerStyle={{
                          gap: 4,
                          paddingHorizontal: 8,
                        }}
                        renderItem={({ item }) => (
                          <TouchableOpacity
                            onPress={() => {
                              window.open(place.book_url, "_blank");
                            }}
                            style={styles.bookingTime}
                          >
                            <Text style={styles.bookingTimeLabel}>{item}</Text>
                          </TouchableOpacity>
                        )}
                      />
                      <LinearGradient
                        useAngle
                        angle={90}
                        locations={[0, 0.9]}
                        colors={["rgb(255,255,255,0)", "rgb(255,255,255,1)"]}
                        style={{
                          height: "100%",
                          width: 16,
                          position: "absolute",
                          right: 0,
                          zIndex: 1,
                        }}
                      />
                    </Pressable>
                  ) : (
                    <Text
                      style={{
                        paddingHorizontal: 12,
                        color: colors.black,
                        marginTop: 6,
                        ...fonts.smallMediumText_bold,
                      }}
                    >
                      No booking available
                    </Text>
                  )}

                  {!isMobile &&
                    place.book_provider !== "none" &&
                    place.booking_dates.length > 0 && (
                      <Pressable
                        onPress={() =>
                          bookingTimesRef.current?.scrollToOffset({
                            offset: scrollOffset + 100,
                            animated: true,
                          })
                        }
                        style={{
                          marginTop: 6,
                          marginLeft: 4,
                          marginRight: 12,
                          transform: [{ rotateY: "180deg" }],
                        }}
                      >
                        <ArrowBlack />
                      </Pressable>
                    )}
                </View>
              )}
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignSelf: "stretch",
              paddingHorizontal: 12,
              alignItems: "center",
              marginTop: wide ? (place.booking_dates ? 0 : 0) : 0,
            }}
          >
            <ShareButton
              toolTipStyle={{ right: -12 }}
              onPress={() => shareLinkHandle(place.place_id)}
            />

            {place.book_provider && place.booking_dates?.length > 0 && (
              <View>{bookingProviderIconHandler(place.book_provider)}</View>
            )}

            <TouchableOpacity
              style={{ alignItems: "center" }}
              onPress={onArrowPress}
            >
              <NextButton />
              <Text style={[fonts.little_regular, { color: colors.black }]}>
                See more
              </Text>
            </TouchableOpacity>
          </View>

          {/*<Text*/}
          {/*  numberOfLines={1}*/}
          {/*  style={{*/}
          {/*    ...fonts.smallText_regular,*/}
          {/*    color: colors.black,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {description}*/}
          {/*</Text>*/}
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bookingTime: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: colors.gray81,
  },
  bookingTimeLabel: {
    color: colors.black,
    ...fonts.smallText_semiBld,
  },
});
