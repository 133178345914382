import React, { useEffect, useState } from "react";
import { StyleProp, Text, TextStyle } from "react-native";

export const DotsLoading = ({ style }: { style?: StyleProp<TextStyle> }) => {
  const contentArray = [".", "..", "..."];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevState) =>
        prevState + 1 > contentArray.length - 1 ? 0 : prevState + 1
      );
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Text style={style}>{contentArray[currentIndex]}</Text>;
};
