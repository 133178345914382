import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const tokenSetTokenAction = createAsyncActionWithMeta(
  "token_tokenSetTokenAction_REQUEST",
  "token_tokenSetTokenAction_SUCCESS",
  "token_tokenSetTokenAction_FAILURE"
)<Parameters<typeof api.token.methods.setToken>, any, Error>();

export const tokenGetTokenAction = createAsyncActionWithMeta(
  "token_tokenGetTokenAction_REQUEST",
  "token_tokenGetTokenAction_SUCCESS",
  "token_tokenGetTokenAction_FAILURE"
)<Parameters<typeof api.token.methods.getToken>, any, Error>();
