import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { ReactComponent as ChatItemMenu } from "@gadder/common/src/assets/svg/chat_menu_background.svg";
import { useIsMobile } from "../../pages/ChatPage";

export const ChatListItem = ({
  isSelected,
  chat,
  onSave,
  onDelete,
  onSelect,
  isOne = false,
}: {
  isSelected?: boolean;
  chat: { name: string; id: number };
  onDelete: (id: number) => void;
  onSave: (id: number, name: string) => void;
  onSelect: (id: number) => void;
  isOne?: boolean;
}) => {
  const [chatName, setChatName] = useState(chat.name);
  const [renameMode, setRenameMod] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const menuRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        !renameMode &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    },
    [renameMode]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const [isHover, setIsHover] = useState(false);

  const isMobile = useIsMobile();

  return (
    <View
      style={{
        width: "100%",
        paddingHorizontal: 8,
        paddingVertical: 6,
        borderRadius: 6,
        flexDirection: "row",
        backgroundColor: isSelected ? "#F1F1F1" : isHover ? "#F7F6F6" : "white",
        zIndex: 1000,
        position: "relative",
      }}
    >
      <View style={{ flex: 1 }}>
        {renameMode ? (
          <TextInput
            style={[
              isMobile
                ? fonts.smallMediumText_regular
                : fonts.mediumText_regular,
              { color: colors.black },
            ]}
            placeholder={"Input chat name"}
            editable={renameMode}
            onChangeText={setChatName}
            value={chatName}
          />
        ) : (
          <Pressable
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
            onPress={() => onSelect(chat.id)}
          >
            <Text
              numberOfLines={1}
              style={[
                isSelected
                  ? isMobile
                    ? fonts.smallMediumText_medium
                    : fonts.mediumText_medium
                  : isMobile
                  ? fonts.smallMediumText_regular
                  : fonts.mediumText_regular,
                { color: colors.black },
              ]}
            >
              {chat.name || "New Chat"}
            </Text>
          </Pressable>
        )}
      </View>

      <TouchableOpacity
        onPress={() => setMenuOpen(true)}
        style={[styles.dotContainer, { gap: 2 }]}
      >
        <View style={styles.dot} />
        <View style={styles.dot} />
        <View style={styles.dot} />
      </TouchableOpacity>

      {menuOpen && (
        <View
          ref={menuRef}
          style={{
            position: "absolute",
            right: -17,
            top: -50,
            zIndex: 20,
            transform: [{ scale: 0.9 }],
          }}
        >
          <ChatItemMenu style={{ position: "absolute" }} />
          <View style={{ alignItems: "center", width: 92, height: 64 }}>
            <Text
              onPress={() => {
                if (renameMode) {
                  onSave(chat.id, chatName);
                  setMenuOpen(false);
                }
                setRenameMod(!renameMode);
              }}
              style={[{ paddingTop: isOne ? 18 : 6, paddingBottom: 6 }]}
            >
              {renameMode ? "Save" : "Rename"}
            </Text>
            {!isOne && (
              <>
                <View
                  style={{ width: 49, height: 1, backgroundColor: "#979797" }}
                />
                <Text
                  onPress={() => {
                    if (!renameMode) {
                      onDelete(chat.id);
                    } else {
                      setRenameMod(false);
                    }

                    setMenuOpen(false);
                  }}
                  style={{ paddingTop: 3 }}
                >
                  {renameMode ? "Cancel" : "Delete"}
                </Text>
              </>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dotContainer: {
    marginLeft: 16,
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 4,
    backgroundColor: colors.noteGray,
  },
  roundButton: {
    ...fonts.smallMediumText_medium,
    color: colors.black,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: colors.black,
  },
});
