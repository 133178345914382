import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const chatbotListGetChatListAction = createAsyncActionWithMeta(
  "chatbotList_chatbotListGetChatListAction_REQUEST",
  "chatbotList_chatbotListGetChatListAction_SUCCESS",
  "chatbotList_chatbotListGetChatListAction_FAILURE"
)<
  Parameters<typeof api.chatbotList.methods.getChatList>,
  typeof api.chatbotList.type,
  Error
>();

export const chatbotListPostChatAction = createAsyncActionWithMeta(
  "chatbotList_chatbotListPostChatAction_REQUEST",
  "chatbotList_chatbotListPostChatAction_SUCCESS",
  "chatbotList_chatbotListPostChatAction_FAILURE"
)<
  Parameters<typeof api.chatbotList.methods.postChat>,
  typeof api.chatbotList.type,
  Error
>();

export const chatbotListUpdateChatAction = createAsyncActionWithMeta(
  "chatbotList_chatbotListUpdateChatAction_REQUEST",
  "chatbotList_chatbotListUpdateChatAction_SUCCESS",
  "chatbotList_chatbotListUpdateChatAction_FAILURE"
)<
  Parameters<typeof api.chatbotList.methods.updateChat>,
  typeof api.chatbotList.type,
  Error
>();

export const chatbotListDeleteChatAction = createAsyncActionWithMeta(
  "chatbotList_chatbotListDeleteChatAction_REQUEST",
  "chatbotList_chatbotListDeleteChatAction_SUCCESS",
  "chatbotList_chatbotListDeleteChatAction_FAILURE"
)<
  Parameters<typeof api.chatbotList.methods.deleteChat>,
  typeof api.chatbotList.type,
  Error
>();
