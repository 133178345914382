import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  chatbotListGetChatListAction,
  chatbotListPostChatAction,
  chatbotListUpdateChatAction,
  chatbotListDeleteChatAction,
} from "./action";

export const getChatListRequest = function* (
  action: ReturnType<typeof chatbotListGetChatListAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbotList.methods.getChatList(...action.payload)
    );

    yield put(chatbotListGetChatListAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotListGetChatListAction.failure(error, action.meta));
  }
};

export const postChatRequest = function* (
  action: ReturnType<typeof chatbotListPostChatAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbotList.methods.postChat(...action.payload)
    );
    yield put(chatbotListPostChatAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotListPostChatAction.failure(error, action.meta));
  }
};

export const updateChatRequest = function* (
  action: ReturnType<typeof chatbotListUpdateChatAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbotList.methods.updateChat(...action.payload)
    );
    yield put(chatbotListUpdateChatAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotListUpdateChatAction.failure(error, action.meta));
  }
};

export const deleteChatRequest = function* (
  action: ReturnType<typeof chatbotListDeleteChatAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbotList.methods.deleteChat(...action.payload)
    );
    yield put(chatbotListDeleteChatAction.success(response.data, action.meta));
  } catch (error) {
    yield put(chatbotListDeleteChatAction.failure(error, action.meta));
  }
};

export const chatbotListSaga = function* () {
  yield all([
    takeEvery(chatbotListGetChatListAction.request, getChatListRequest),
    takeEvery(chatbotListPostChatAction.request, postChatRequest),
    takeEvery(chatbotListUpdateChatAction.request, updateChatRequest),
    takeEvery(chatbotListDeleteChatAction.request, deleteChatRequest),
  ]);
};
