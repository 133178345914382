import { getBookingMinDate } from "@gadder/chatbot/src/components/DatePicker";

export enum FilterType {
  CAFE = "cafe",
  RESTAURANT = "restaurant",
  BAR = "bar",
}

export enum FilterPrice {
  "PRICE_ONE" = "£",
  "PRICE_TWO" = "££",
  "PRICE_THREE" = "£££",
}

export enum FilterCuisine {
  "ASIAN" = "asian",
  "BAKERY" = "bakery",
  "BRITISH" = "british",
  "BRUNCH" = "brunch",
  "BURGER" = "burger",
  "CHINESE" = "chinese",
  "FRENCH" = "french",
  "INDIAN" = "indian",
  "ITALIAN" = "italian",
  "JAPANESE" = "japanese",
  "LATIN_AMERICAN" = "latin american",
  "MEDITERRANEAN" = "mediterranean",
  "MODERN_EUROPE" = "modern european",
  "MIDDLE_EASTERN" = "middle eastern",
  "PIZZA" = "pizza",
  "SEAFOOD" = "seafood",
  "SPANISH" = "spanish",
  "STEAK" = "steak",
  "ICE_CREAM_AND_DESSERT" = "ice cream & dessert",
}

export enum FilterTag {
  "RECOMMENDED" = "recommended",
  "NEW" = "new",
  "TRENDING" = "trending",
  "PROMO" = "promo",
}

export enum FilterFeature {
  "AFTERNOON_TEA" = "afternoon tea",
  "BOOKABLE_BAR" = "bookable bar",
  "BUSINESS" = "business",
  "BUZZY" = "buzzy",
  "CASUAL" = "casual",
  "COCKTAIL_BAR" = "cocktail bar",
  "GREAT_TO_WORK_FROM" = "great to work from",
  "HEALTHY" = "healthy",
  "HIDDEN_GEM" = "hidden gem",
  "HOTEL" = "hotel",
  "INSTAGRAMMABLE" = "instagrammable",
  "LIVE_MUSIC" = "live music",
  "LIVE_SPORTS" = "live sports",
  "OUTDOOR_SEATING" = "outdoor seating",
  "ROMANTIC" = "romantic",
  "ROOFTOP" = "rooftop",
  "SUNDAY_ROAST" = "sunday roast",
  "VEGETARIAN_FRIENDLY" = "vegetarian friendly",
  "VIEWS" = "views",
  "PUB" = "pub",
  "HIGH_END" = "high end",
  "COSY" = "cosy",
}

export type PlaceSearchFiltersType = {
  type: FilterType[];
  price: FilterPrice[];
  cuisine: FilterCuisine[];
  feature: FilterFeature[];
  tag: FilterTag[];
  isFavorites?: boolean;
  placeFromSearch?: { placesId: string[] } | null;
  bookingFilters?: { date: Date; personsCount: string };
  bookingMode: boolean;
};

export const defaultPlaceFilter: PlaceSearchFiltersType = {
  type: [],
  price: [],
  tag: [],
  cuisine: [],
  feature: [],
  isFavorites: false,
  placeFromSearch: null,
  bookingFilters: { date: getBookingMinDate(new Date()), personsCount: "2" },
  bookingMode: false,
};
